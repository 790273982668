export const formatDate = (date) => {
  try {
    if (date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    return "";
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
};

export const formatTime = (time) => {
  try {
    if (time) {
      const d = new Date(time);
      const hours = (d.getHours() < 10 ? "0" : "") + d.getHours();
      const minutes = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();

      return [hours, minutes].join(":");
    }
    return "";
  } catch (error) {
    console.error("Error formatting time:", error);
    return "";
  }
};

export const formatTimeAMPM = (time) => {
  try {
    if (time) {
      var d = new Date(time),
        hours = d.getHours(),
        minutes = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes(),
        ampm = hours >= 12 ? "PM" : "AM";

      hours = hours % 12;
      hours = hours ? (hours < 10 ? "0" : "") + hours : 12; // Handle midnight (12 AM)

      return [hours, minutes].join(":") + " " + ampm;
    }
    return "";
  } catch (error) {
    console.error("Error formatting time:", error);
    return "";
  }
};
