import React from 'react';
import LandingPageMenu from 'components/LandingPageMenu';
import MenuItems from '../../config/MenuItems';
import { useRolePermissions } from './../../hooks/useRolePermissions';

const { redirectToForbiddenIfUnauthorized } = useRolePermissions();

class Nav extends React.Component
{
    render() {
        redirectToForbiddenIfUnauthorized([
            "communication-log-view",
            "cash-count-view",
            "daily-checklist-view",
            "waste-log-view",
        ]);
        return this.getContent();
    }

    getContent = () => {
        const menuItems = MenuItems();
        const checklistMenu = menuItems['check-lists']['submenu'];
        return (
            <div>
                <h1>CHECK LISTS</h1>
                <br/><br/><br/>
                <LandingPageMenu menuItems={checklistMenu}/>
            </div>
        )
    }
}

export default Nav;
