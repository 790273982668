import React from 'react';
import {
    FormField,
    Select,
} from "semantic-ui-react";
import Request from "../Request";
import { NotificationManager } from "react-notifications";

class StoreSelection extends React.Component {
    state = {
        stores: [],
        storeOptions: [],
        selectedStore: null,
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.getStores();
    }

    getStores = () => {
        new Promise((resolve, reject) => {
            let path = `/get-permitted-stores?permission=${this.props.permission || ''}`;
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let storeResponse = JSON.parse(response);
                if (true === storeResponse?.success) {
                    this.setState({ stores: storeResponse.data }, () => {
                        this.transformStoresToOptions();
                    });
                } else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
            })
            .catch(error => {
                this.setState({ stores: [] });
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
            });
    };

    transformStoresToOptions = () => {
        let stores = this.state.stores;
        let storeOptions = stores.map(store => {
            return {
                text: store.title,
                value: store.id,
            };
        })
        this.setState({ storeOptions: storeOptions })
    };

    handleStoreChange = (event, { value, options }) => {
        let selectedOption = options.filter(option => option.value === value)?.[0] || {};

        // Update the selected store in the component state
        this.setState({ selectedStore: selectedOption });

        // Pass the selected store value to the parent component
        if (this.props.onStoreChange) {
            this.props.onStoreChange(selectedOption);
        }
    };

    render() {

        return (

            <FormField
                control={Select}
                style={{
                    ...(this.props.isError ? { color: 'red', border: '1px solid red' } : {}),
                }}
                options={this.state.storeOptions}
                label={{ children: 'Store', htmlFor: 'form-select-control-store' }}
                placeholder='Store'
                value={this.props.storeId || null}
                search
                searchInput={{ id: 'form-select-control-store' }}
                onChange={this.handleStoreChange}
            />
        );
    }
}

export default StoreSelection;