import React from 'react';
import { Button, Card, Grid, Header, Icon, Modal } from "semantic-ui-react";
import './../../assets/css/pages/admin/stores.css';
import { NotificationContainer, NotificationManager } from "react-notifications";
import Request from "../../Request";
import Title from 'components/Title';
import { useRolePermissions } from './../../hooks/useRolePermissions';
import Forbidden from 'pages/Forbidden';

const { redirectToForbiddenIfUnauthorized, isPermitted } = useRolePermissions();

class Stores extends React.Component {
    state = {
        customSize: window.innerWidth <= 1000,
        stores: [],
        renderStores: false,
        showDeactivated: 1,
        modalOpen: false,
        removeId: null
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        redirectToForbiddenIfUnauthorized('stores-view');

        this.getStores();

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({ customSize: window.innerWidth <= 1000 });
    }

    getStores = () => {
        new Promise((resolve, reject) => {
            let path = '/stores-managers?trashed=' + (this.state.showDeactivated ? '0' : '1');
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let stores = JSON.parse(response);
                if (stores && stores.success === true && stores.data) {
                    this.setState({ stores: stores.data }, () => {
                        this.setState({ renderStores: true });
                    });
                }
                else {
                    this.setState({ stores: [] }, () => {
                        this.setState({ renderStores: true })
                    });
                }
            })
            .catch(error => {
                this.setState({ stores: [] }, () => {
                    this.setState({ renderStores: true })
                });
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
            });
    };

    deactivateStore = (id) => {
        if (id) {
            new Promise((resolve, reject) => {
                let path = '/stores/' + id + '/deactivate';
                this.request.put(path, null, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if (response && response.success === true) {
                        NotificationManager.success('Successfully deactivated Store', 'Success');
                        this.getStores();
                    }
                })
                .catch(error => {
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not deactivate Store', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not deactivate Store', 'Error');
        }
    };

    restoreStore = (id) => {
        if (id) {
            new Promise((resolve, reject) => {
                let path = '/stores/' + id + '/activate';
                this.request.put(path, null, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if (response && response.success === true) {
                        NotificationManager.success('Successfully activated Store', 'Success');
                        this.getStores();
                    }
                })
                .catch(error => {
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not activate Store', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not activate Store', 'Error');
        }
    };

    deleteStore = (id) => {
        this.setState({ modalOpen: false });
        if (id) {
            new Promise((resolve, reject) => {
                let path = '/stores/' + id;
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if (response && response.success === true) {
                        NotificationManager.success('Successfully removed Store', 'Success');
                        this.getStores();
                    }
                })
                .catch(error => {
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not remove Store', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not remove Store', 'Error');
        }
    };

    removeStoreConfirmation = (id) => {
        this.setState({ removeId: id, modalOpen: true });
    };

    showDeactivatedToogle = () => {
        let showDeactivated = this.state.showDeactivated;
        showDeactivated = showDeactivated === 1 ? 0 : 1;
        this.setState({ showDeactivated: showDeactivated }, () => {
            this.getStores();
        });
    };

    redirectToEdit = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/stores/edit/' + id;
    };

    redirectToView = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/stores/' + id;
    };

    redirectToUserView = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/users/' + id;
    };

    getManagersHtml = (store) => {
        let managers = store.managers || [];
        let franchisees = store.franchisees;
        let html = [];
        managers.forEach((manager, index) => {
            html.push(
                <div className="stores-admin-grid-column-managers-div" key={index}>
                    <a className="stores-admin-grid-column-managers-div-a" href={'/admin/users/' + manager.id}>{manager.first_name + ' ' + manager.last_name + ' '}</a>
                    &nbsp;
                    &nbsp;
                </div>
            );
        });
        if (managers.length > 0 && franchisees.length > 0) {
            html.push(
                <div className="stores-admin-grid-column-managers-div">
                    /
                    &nbsp;
                    &nbsp;
                </div>
            );
        }
        franchisees.forEach((manager, index) => {
            html.push(
                <div className="stores-admin-grid-column-managers-div" key={index}>
                    <a className="stores-admin-grid-column-managers-div-a" href={'/admin/users/' + manager.id}>{manager.first_name + ' ' + manager.last_name + ' '}</a>
                    &nbsp;
                    &nbsp;
                </div>
            );
        });
        return html;
    };

    getStoresContent = () => {
        return (
            <Grid className="stores-admin-grid">
                <Grid.Row className="stores-admin-grid-header-row">
                    <Grid.Column width={2}>
                        Title
                    </Grid.Column>
                    <Grid.Column width={2}>
                        Number
                    </Grid.Column>
                    <Grid.Column width={3}>
                        Address
                    </Grid.Column>
                    <Grid.Column width={4}>
                        Email
                    </Grid.Column>
                    <Grid.Column width={3}>
                        Managers/Franchisees
                    </Grid.Column>
                    <Grid.Column width={2}>
                    </Grid.Column>
                </Grid.Row>
                {this.state.stores.map((store, index) => {
                    return (
                        <Grid.Row className="stores-admin-grid-row" key={index}>
                            <Grid.Column width={2} className="stores-admin-grid-column">
                                <a className="stores-admin-grid-column-a" href={'/admin/stores/' + store.store.id}><b>{store.store.title || ''}</b></a>
                            </Grid.Column>
                            <Grid.Column width={2} className="stores-admin-grid-column">
                                {store.store.number || ''}
                            </Grid.Column>
                            <Grid.Column width={3} className="stores-admin-grid-column">
                                {store.store.street1 || ''}
                            </Grid.Column>
                            <Grid.Column width={4} className="stores-admin-grid-column">
                                {store.store.email || ''}
                            </Grid.Column>
                            <Grid.Column width={3} className="stores-admin-grid-column">
                                {this.getManagersHtml(store)}
                            </Grid.Column>
                            <Grid.Column width={2} className="stores-admin-grid-actions-column">
                                <Button.Group icon>
                                    {this.state.showDeactivated ?
                                        <div>
                                            <Button size='tiny' title="View Vendor" onClick={() => this.redirectToView(store.store.id)}>
                                                <Icon name='eye' />
                                            </Button>
                                            <Button size='tiny' title="Deactivate Store" onClick={() => this.deactivateStore(store.store.id)}>
                                                <Icon name='trash' />
                                            </Button>
                                        </div>
                                        :
                                        <div>
                                            <Button size='tiny' title="Restore Vendor" onClick={() => this.restoreStore(store.store.id)}>
                                                <Icon name='refresh' />
                                            </Button>
                                            <Button size='tiny' title="Restore Store" onClick={() => this.removeStoreConfirmation(store.store.id)}>
                                                <Icon name='times' />
                                            </Button>
                                        </div>
                                    }
                                </Button.Group>
                            </Grid.Column>
                        </Grid.Row>
                    )
                })
                }
                <Grid.Row className="stores-admin-grid-footer-row">
                    <Grid.Column width={2}>
                        <Button icon labelPosition='left' onClick={() => this.redirectToEdit('new')}>
                            <Icon name='plus' />
                            Store
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    getStoresCard = () => {
        let cards = [];
        let stores = this.state.stores;
        stores.forEach((store, index) => {
            cards.push(
                <Card className="stores-admin-card" fluid key={index}>
                    <Card.Content className="stores-admin-card-header" header={store.store.title} />
                    <Card.Content description={this.getOneCardDescription(store, index)} />
                </Card>
            );
        });
        cards.push(
            <div key="stores-admin-plus-button">
                <Button icon labelPosition='left' onClick={() => this.redirectToEdit('new')}>
                    <Icon name='plus' />
                    Store
                </Button>
            </div>
        );
        return cards;
    };

    getOneCardDescription = (store, index) => {
        return (
            <Grid className="stores-admin-card-grid">
                <Grid.Row className="stores-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Title: </b><a className="stores-admin-grid-column-a" href={'/admin/stores/' + store.store.id}><b>{store.store.title || ''}</b></a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="stores-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Number: </b>{store.store.number || ''}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="stores-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Address: </b>{store.store.street1}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="stores-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Email: </b>{store.store.email || ''}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="stores-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Managers: </b><br />
                        {store.managers.map((manager, index) => {
                            return (
                                <div className="stores-admin-grid-column-managers-div">
                                    <a className="stores-admin-grid-column-managers-div-a" href={'/admin/users/' + manager.id} key={index}>{manager.first_name + ' ' + manager.last_name + ' '}</a>
                                    &nbsp;
                                    &nbsp;
                                </div>
                            )
                        })
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="stores-admin-card-grid-row">
                    <Grid.Column width={16}>
                        {this.state.showDeactivated ?
                            <div className="stores-admin-card-grid-row-buttons-div">
                                <Button size='tiny' title="View Store" onClick={() => this.redirectToView(store.store.id)}>
                                    <Icon name='eye' />
                                    View Store
                                </Button>
                                <Button size='tiny' title="Deactivate Store" onClick={() => this.deactivateStore(store.store.id)}>
                                    <Icon name='trash' />
                                    Deactivate Store
                                </Button>
                            </div>
                            :
                            <div className="stores-admin-card-grid-row-buttons-div">
                                <Button size='tiny' title="Restore Store" onClick={() => this.restoreStore(store.store.id)}>
                                    <Icon name='refresh' />
                                    Restore Store
                                </Button>
                                {!isPermitted('stores-delete') &&
                                    <Button size='tiny' title="Restore Store" onClick={() => this.removeStoreConfirmation(store.store.id)}>
                                        <Icon name='times' />
                                        Delete Store
                                    </Button>
                                }
                            </div>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    };

    handleCloseModal = () => {
        this.setState({
            removeId: null,
            modalOpen: false
        });
    };

    getConfirmationModal = () => {
        let removeId = this.state.removeId;

        if (removeId || removeId === 0) {
            return (
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleCloseModal}
                    size='small'
                >
                    <Header content='Delete Store' />
                    <Modal.Content>
                        <h3>Are you sure you want to delete this Store?</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleCloseModal} inverted>
                            No
                        </Button>
                        <Button color='green' onClick={() => this.deleteStore(removeId)} inverted>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            )
        }
        return '';
    };

    render() {
        return (
            <div className="stores-admin-root">
                <Title
                    paramsPageTitle="Stores"
                />
                <div className="stores-admin-trash-button-div">
                    {!this.state.showDeactivated ?
                        <Button color="orange" onClick={this.showDeactivatedToogle}><Icon name="angle left" />Show Active</Button>
                        :
                        <Button color="orange" onClick={this.showDeactivatedToogle}><Icon name="trash" />Show Deactivated</Button>
                    }
                </div>
                {this.state.renderStores ?
                    this.state.customSize ? this.getStoresCard() : this.getStoresContent()
                    :
                    ''
                }
                {this.getConfirmationModal()}
                <NotificationContainer />
            </div>
        )
    }
}

export default Stores;
