import React from 'react';
import PropTypes from 'prop-types'
import {Card, Grid, List, Input, Button, Form, Image, Icon} from 'semantic-ui-react'
import './../assets/css/components/shiftComments.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import RichTextEditor from 'react-rte';
import Request from "../Request";
import { useRolePermissions } from './../hooks/useRolePermissions';

const { isPermitted } = useRolePermissions();

const typeToHeader = {
    open: 'Opening Shift',
    mid: 'Mid Shift',
    close: 'Closing Shift'
};

class ShiftComments extends React.Component
{

    toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
            {label: 'Italic', style: 'ITALIC'},
            {label: 'Underline', style: 'UNDERLINE'},
            {label: 'Strikethrough', style: 'STRIKETHROUGH'}
        ],
        BLOCK_TYPE_BUTTONS: [
            {label: 'UL', style: 'unordered-list-item'},
            {label: 'OL', style: 'ordered-list-item'}
        ]
    };

    static propTypes = {
        onChange: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.request = new Request();
    };

    state = {
        newComment: true,
        commentValue: RichTextEditor.createEmptyValue(),
        membersInputValue: '',
        file: null,
        filename: null,
        type: null,
        mobileSize: window.innerWidth <= 360, // for the save button
        tabletSize:  window.innerWidth <= 1024 && window.innerWidth >= 768, // for the save button
        commentChanged: false,
        memberEditIndex: null,
        store: null,
        date: null,
        header: '',
        shiftComment: {},
        loading: false,
    };

    componentDidMount() {
        if(this.props.comments && this.props.shiftType) {
            let type = this.props.shiftType;
            let comment = this.props.comments[type];
            this.setState({shiftComment: comment, commentValue: this.getRichTextValueParsed(comment.comments)});
        }

        this.initializeProps();

        window.addEventListener("resize", this.resize.bind(this));

        this.resize();
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.store !== this.props.store) || (prevProps.date !== this.props.date)) {
            this.initializeProps();
        }
    }

    initializeProps = () => {
        if(this.props.store) {
            this.setState({store: this.props.store});
        }

        if(this.props.date) {
            this.setState({date: this.props.date});
        }

        if(this.props.shiftType) {
            this.setState({header: typeToHeader[this.props.shiftType], type: this.props.shiftType})
        }

        if(this.props.comments && this.props.shiftType) {
            this.setState({newComment: !this.props.comments[this.props.shiftType].id});
        }
    };

    formatDate = (date) => {
        if(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        }
        return '';
    };

    resize() {
        this.setState({mobileSize: window.innerWidth <= 360, tabletSize: window.innerWidth <= 1024 && window.innerWidth >= 768});
    }

    getRichTextValueParsed = (value) => {
        return value ? RichTextEditor.createValueFromString(value, 'html') : RichTextEditor.createEmptyValue()
    };

    handleMemberInputChange = (event) => {
        this.setState({
            membersInputValue: event.target.value
        });
    };

    resetFile = () => {
        this.setState({file: null, filename: null});
    };

    handleFileChanged = (event) => {
        event.preventDefault();
        let file = event.target.files[0];
        this.setState({filename: event.target.files[0].name});
        if(file){
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: reader.result,
                    commentChanged: true
                });
            };
        }
    };

    handleCommentChange = (value) => {
        this.setState({commentValue: value});
        let comment = {...this.state.shiftComment};
        comment.comments = value.toString('html');
        this.setState({
            shiftComment: comment,
            commentChanged: true
        });
    };

    handleRemoveImage = (index) => {
        let comment = {...this.state.shiftComment};
        let media = comment.files.splice(index, 1);

        if(Array.isArray(media) && media.length ===1 && media[0].url) {
            new Promise((resolve, reject) => {
                let apiUrl = media[0].url;
                this.request.delete('', resolve, reject, apiUrl);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if(response && response.success === true) {
                        this.props.handleChangeComments();
                        NotificationManager.success('Successfully removed media content', 'Success');
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        if(error) {
                            NotificationManager.error(error, 'Error');
                        }
                        else {
                            NotificationManager.error('Could not remove media content', 'Error');
                        }
                    }
                });
        }
        else {
            NotificationManager.error('Could not remove media content', 'Error');
        }
    };

    getCommentImages = (file, index) => {
        let url = file.url;
        let isVideo = file.is_video;

        return (
            <Grid.Column width={this.state.tabletSize ? 8 : 3} key={index}>
                <div className="comm-sheet-media-div">
                    { isVideo ?
                        <video className="comm-sheet-videos" controls>
                            <source src={url} type="video/mp4"/>
                        </video>
                        :
                        <Image className="comm-sheet-images" as="a" target="_blank" href={url} src={url} />
                    }
                </div>
                <div className="comm-sheet-remove-image-link">
                    <a href={'#delete'} onClick={() => this.handleRemoveImage(index)}>Delete</a>
                </div>
            </Grid.Column>
        );
    };

    handleAddMember = () => {
        if(this.state.membersInputValue.trim()) {
            let comment = {...this.state.shiftComment};
            comment.names = comment.names ? comment.names : [];
            comment.names.push(this.state.membersInputValue);
            this.setState({
                shiftComment: comment,
                membersInputValue: '',
                commentChanged: true
            });
        }
    };

    editMember = (index) => {
        this.setState({memberEditIndex: index});
    };

    stopEditingMember = (event, index) => {
        let value = event.target.value;
        let shiftComment = {...this.state.shiftComment};
        if(!value) {
            shiftComment.names.splice(index, 1);
        }
        this.setState({memberEditIndex: null, shiftComment: shiftComment, commentChanged: true});
    };

    handleMemberInputEdit = (event, index) => {
        let shiftComment = {...this.state.shiftComment};
        shiftComment.names[index] = event.target.value;

        this.setState({shiftComment: shiftComment})
    };

    handleEnterMember = (event) => {
        if (event.key === 'Enter') {
            if(this.state.membersInputValue) {
                let comment = {...this.state.shiftComment};
                comment.names = comment.names ? comment.names : [];
                comment.names.push(this.state.membersInputValue);
                this.setState({
                    shiftComment: comment,
                    membersInputValue: '',
                    commentChanged: true
                });
            }
        }
    };

    handleEditMemberEnter = (event, index) => {
        if (event.key === 'Enter') {
            let value = event.target.value;
            let shiftComment = {...this.state.shiftComment};
            if(!value) {
                shiftComment.names.splice(index, 1);
            }
            this.setState({memberEditIndex: null, shiftComment: shiftComment, commentChanged: true});
        }
    };

    handleSaveComment = (event) => {
        if(this.state.newComment) {
            this.saveNewComment();
        }
        else {
            this.saveUpdatedComment();
        }
        this.setState({
            commentChanged: false
        });
        this.fileInput.current.value = null;
    };

    saveNewComment = () => {
        if (!isPermitted('communication-log-create')) {
            NotificationManager.error('You are not authorized', 'Error');
            return;
        }
        this.setState({loading: true});
        let comment = {...this.state.shiftComment};
        new Promise((resolve, reject) => {
            let path = '/comm-sheet/' + this.state.store.id + '/' + this.formatDate(this.state.date);
            let params = {
                storeId: this.state.store.id,
                date: this.formatDate(this.state.date),
                type: this.state.type,
                names: comment.names,
                comments: comment.comments || null,
            };
            if(this.state.filename && this.state.file) {
                params.file = this.state.file;
                params.filename = this.state.filename;
            }
            this.request.post(path, params, resolve, reject);
        })
        .then(response => {
            this.setState({loading: false});
            NotificationManager.success('Successfully saved new Communication Sheet', 'Success');
            this.resetFile();
            this.props.handleChangeComments();
        })
        .catch(error => {
            if(error) {
                NotificationManager.error(error, 'Error');
            }
            else {
                NotificationManager.error('Could not create new Communication Sheet', 'Error');
            }
        });
    };

    saveUpdatedComment = () => {
        if (!isPermitted('communication-log-update')) {
            NotificationManager.error('You are not authorized', 'Error');
            return;
        }
        this.setState({loading: true});
        let comment = {...this.state.shiftComment};
        new Promise((resolve, reject) => {
            let path = '/comm-sheet/' + comment.id;
            let params = {
                id: comment.id,
                names: comment.names,
                comments: comment.comments
            };
            if(this.state.filename && this.state.file) {
                params.file = this.state.file;
                params.filename = this.state.filename;
            }
            this.request.put(path, params, resolve, reject);
        })
            .then(response => {
                this.setState({loading: false});
                NotificationManager.success('Successfully updated Communication Sheet', 'Success');
                this.resetFile();
                this.props.handleChangeComments();
            })
            .catch(error => {
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not update Communication Sheet', 'Error');
                }
            });
    };

    getCardContent = () => {
        return (
            <Grid className="shiftCard" columns={2} stackable>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <h3 className="shiftCardMembersHeader">Team Members Names</h3>
                        <div className="shiftCardMembersInputs">
                            <div className="inputShift">
                                <Input
                                    icon={<Icon className="plus-icon-members" name='plus' circular link onClick={this.handleAddMember}/>}
                                    placeholder='Enter member'
                                    value={this.state.membersInputValue}
                                    onChange={this.handleMemberInputChange}
                                    onKeyDown={this.handleEnterMember}
                                />
                            </div>
                            <List className="addedMembersList" divided>
                                {
                                    !this.state.shiftComment.names || !Array.isArray(this.state.shiftComment.names) ? '' : this.state.shiftComment.names.map((member, index) => {
                                        return (
                                            <List.Item key={index}>
                                                <List.Content className="members-list">
                                                    { this.state.memberEditIndex === index ?
                                                        <Input focus onKeyDown={(e) => this.handleEditMemberEnter(e, index)} onBlur={(e) => this.stopEditingMember(e, index)} value={member} onChange={(event) => this.handleMemberInputEdit(event, index)}/>
                                                        :
                                                        <List.Header as='a' onClick={() => this.editMember(index)}>{member}</List.Header>
                                                    }
                                                </List.Content>
                                            </List.Item>
                                        )
                                    })
                                }
                            </List>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <Form reply>
                            <RichTextEditor
                                editorClassName="textEditor"
                                value={this.state.commentValue}
                                onChange={this.handleCommentChange}
                                toolbarConfig={this.toolbarConfig}
                            />
                            <Grid className="shiftCard" columns={2} stackable>
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <div className="fileInput">
                                            <input ref={this.fileInput} type="file" accept=".jpeg, .jpg, .png, .svg, .mp4, .mov, .gif" onChange={this.handleFileChanged}/>
                                        </div>
                                        <div className="fileInputTypes">(jpg, jpeg, png, gif, svg, mp4, mov)</div>
                                    </Grid.Column>
                                    { isPermitted('communication-log-edit') ?
                                        <Grid.Column width={8}>
                                            {this.state.loading ?
                                                <Button loading color="orange" floated={this.state.mobileSize ? '' : 'right'}>
                                                    Loading
                                                </Button>
                                                :
                                                this.state.commentChanged ?
                                                    <Button content='Save' onClick={this.handleSaveComment} floated={this.state.mobileSize ? '' : 'right'} color="orange" />
                                                    :
                                                    <Button disabled content='Save' onClick={this.handleSaveComment} floated={this.state.mobileSize ? '' : 'right'} color="orange" />

                                            }
                                        </Grid.Column> : ''
                                    }
                                </Grid.Row>
                                {!this.state.shiftComment.files || !Array.isArray(this.state.shiftComment.files) ? '' :
                                    <Grid.Row>{
                                        this.state.shiftComment.files.map((file, index) => {
                                            return (
                                                    this.getCommentImages(file, index)
                                            );
                                        })}
                                    </Grid.Row>
                                }
                            </Grid>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    render() {
        return (
            <div>
                <Card className="commentCard" fluid>
                    <Card.Content className="cardHeader" header={this.state.header} />
                    <Card.Content description={this.getCardContent()} />
                </Card>
                <NotificationContainer/>
            </div>
        )
    }
}
export default ShiftComments;