import React from 'react';
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Grid, Button, Card, Form, Label, Select } from "semantic-ui-react";
import PropTypes from "prop-types";
import Request from "../../../Request";
import './../../../assets/css/pages/admin/role-admin-edit.css';
import { useRolePermissions } from './../../../hooks/useRolePermissions';

const { redirectToForbiddenIfUnauthorized } = useRolePermissions();

class RolesEdit extends React.Component {
    state = {
        mobileSize: window.innerWidth <= 768,
        tabletSize: window.innerWidth <= 1024,
        editId: null,
        role: null,
        filename: null,
        defaultRole: {
            schematic: {
                name: '',
                requiresStore: false
            }
        },
        renderRole: false,
        loading: false,
    };

    static propTypes = {
        onChange: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.request = new Request();
    }

    componentDidMount() {
        redirectToForbiddenIfUnauthorized('roles-edit');
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        if (this.props.match.params.id) {
            this.setState({ editId: this.props.match.params.id }, () => {
                this.getRole();
            })
        }
    }

    resize() {
        this.setState({ tabletSize: window.innerWidth <= 1024, mobileSize: window.innerWidth <= 768 });
    }

    getRole = () => {
        if (this.state.editId) {
            new Promise((resolve, reject) => {
                let path = '/roles/' + this.state.editId;
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let role = JSON.parse(response);
                    if (role && role.success === true && role.data) {
                        this.setState({ role: role.data }, () => {
                            this.setState({ renderRole: true, loading: false });
                        })
                    }
                    else {
                        this.setState({ role: this.state.defaultRole }, () => {
                            this.setState({ renderRole: true, loading: false });
                        });
                    }
                })
                .catch(error => {
                    this.setState({ role: this.state.defaultRole }, () => {
                        this.setState({ renderRole: true, loading: false });
                    });
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Role', 'Error');
                    }
                });
        }
    };

    saveRole = () => {
        this.setState({ loading: true });
        new Promise((resolve, reject) => {
            let path = '/roles/' + this.state.editId;
            let params = {
                name: this.state.role.name,
                requiresStore: this.state.role.requiresStore
            };
            this.request.put(path, params, resolve, reject);
        })
            .then(response => {
                NotificationManager.success('Successfully saved Role', 'Success');
                this.getRole();
            })
            .catch(error => {
                this.setState({ loading: false });
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not edit Role', 'Error');
                }
            });
    };

    handleChangeName = (event) => {
        let role = this.state.role;
        if (event.target.value.trim() !== '') {
            role.name = event.target.value;
            this.setState({ role: role }, () => {
                this.setState({ renderRole: true })
            })
        }
    };

    handleChangeRequiresStore = (event, data) => {
        let role = this.state.role;
        role.requiresStore = data.value
        this.setState({ role: role }, () => {
            this.setState({ renderRole: true })
        })
    }

    redirectBack = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/roles/';
    };

    getRoleEditContent = () => {
        let role = this.state.role;
        if (this.state.renderRole) {
            return (
                <div>
                    <Grid className="role-edit-grid">
                        <Grid.Row columns={2} className="role-edit-rows">
                            <Grid.Column width={this.state.mobileSize ? 12 : (this.state.tabletSize ? 8 : 5)}>
                                <Form>
                                    <Form.Field>
                                        <Label className="role-edit-name-label" pointing='below'>Name</Label>
                                        <input
                                            className="role-edit-name-input"
                                            type='text'
                                            value={role.name || ''}
                                            placeholder="Enter name"
                                            onChange={this.handleChangeName}
                                        />
                                        <Form.Field>
                                            <Form.Field>
                                                <Label className="schematics-edit-name-label" pointing='below'>Role level</Label>
                                                <Select
                                                    placeholder='Select role level'
                                                    options={
                                                        [
                                                            { key: 'corporate', text: 'Corporate', value: '0' },
                                                            { key: 'store', text: 'Store level', value: '1' }
                                                        ]
                                                    }
                                                    defaultValue={role.requiresStore ? '1' : '0'}
                                                    onChange={this.handleChangeRequiresStore}
                                                >
                                                </Select>
                                            </Form.Field>
                                        </Form.Field>
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1} className="role-edit-rows">
                            <Grid.Column>
                                {this.state.loading ?
                                    <Button loading color="orange">
                                        Loading
                                    </Button>
                                    :
                                    <Button color="orange" onClick={() => this.saveRole()}>Save</Button>
                                }

                                <Button color="orange" onClick={() => this.redirectBack()}>Cancel</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <NotificationContainer />
                </div>
            );
        }
        return '';
    };

    render() {
        return (
            <Card className="role-edit-card" fluid>
                <Card.Content className="role-edit-card-header" header="Role edit" />
                <Card.Content description={this.state.renderRole ? this.getRoleEditContent() : ''} />
            </Card>
        )

    }
}

export default RolesEdit;