import Cookies from "universal-cookie";

function Logout() {
    let cookies = new Cookies();
    cookies.remove('token', { path: '/' });
    cookies.remove('user-name', { path: '/' });
    cookies.remove('user-id', { path: '/' });
    cookies.remove('management-token', { path: '/' });
    cookies.remove('management-permissions', { path: '/' });
    let url = process.env.REACT_APP_UI_URL;
    window.location.href = url + '/login';
}

export default Logout;