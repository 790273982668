import React from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "../../assets/css/pages/managment/checklists-admin-edit.css";
import { Grid, Input, Button, Select, Popup, Modal } from "semantic-ui-react";
import Request from "../../Request";
import Title from "components/Title";
import { useRolePermissions } from "./../../hooks/useRolePermissions";

const { redirectToForbiddenIfUnauthorized } = useRolePermissions();

class ChecklistsEdit extends React.Component {
  state = {
    store: {},
    changeStore: false,
    stores: [],
    options: [],
    openModal: false,
    renderStoreInfos: false,
    editId: null,
    editType: null,
    checklist: {},
    tabletSize: window.innerWidth <= 768,
    mobileSize: window.innerWidth <= 450,
    checklistOptions: [
      {
        id: 1,
        key: 1,
        value: "open",
        text: "Opening Shift",
      },
      {
        id: 2,
        key: 2,
        value: "mid",
        text: "Mid Shift",
      },
      {
        id: 3,
        key: 3,
        value: "close",
        text: "Closing Shift",
      },
      {
        id: 4,
        key: 4,
        value: "weekly",
        text: "Weekly Tasks",
      },
    ],
    pickedChecklistType: {
      id: 1,
      key: 1,
      value: "open",
      text: "Opening Shift",
    },
  };

  constructor() {
    super();
    this.request = new Request();
  }

  componentDidMount() {
    redirectToForbiddenIfUnauthorized(
      "checklists-edit",
      this.props.match.params.id
    );

    if (this.props.match.params.id && this.props.match.params.type) {
      this.setState(
        {
          editId: this.props.match.params.id,
          editType: this.props.match.params.type,
        },
        () => {
          this.getChecklist();
          this.getStoresData();
        }
      );
    }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({
      tabletSize: window.innerWidth <= 768,
      mobileSize: window.innerWidth <= 450,
    });
  }

  // FILTERS

  getStoresData = () => {
    new Promise((resolve, reject) => {
      let path = "/stores-managers";
      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let stores = JSON.parse(response);
        if (stores && stores.success === true && stores.data) {
          this.setState({ stores: stores.data, store: stores.data[0] }, () => {
            this.transformStoresToOptions();
            this.setState({ renderStoreInfos: true });
          });
        } else {
          NotificationManager.error("Could not get Stores", "Error");
        }
      })
      .catch((error) => {
        this.setState({ stores: [] });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get Stores", "Error");
        }
        this.setState({ renderStoreInfos: true });
      });
  };

  transformStoresToOptions = () => {
    let stores = this.state.stores;
    let options = [];
    stores.forEach((store, index) => {
      let tempOption = {
        id: store.store.id,
        key: store.store.id,
        value: store.store.id,
        text: store.store.title,
      };
      options.push(tempOption);
    });
    this.setState({ options: options });
  };

  handleStoresInfoButton = () => {
    if (this.state.tabletSize) {
      this.setState({ openModal: true });
    } else {
      this.setState({ openModal: false });
    }
  };

  handleRedirectToProfile = (username) => {
    let url = process.env.REACT_APP_UI_URL;
    window.location.href = url + "/profile/" + username;
  };

  getStoresPopupInfos = () => {
    return (
      <Grid className="store-info-popup-content" columns={2} stackable>
        <Grid.Row>
          {this.state.store && this.state.store.managers ? (
            <Grid.Column width={8} className="store-info-managers">
              {this.state.store &&
              this.state.store.managers &&
              Array.isArray(this.state.store.managers) ? (
                <div>
                  <h5>Managers</h5>
                  {this.state.store.managers &&
                  this.state.store.managers.length > 0 ? (
                    <ol>
                      {this.state.store.managers.map((manager, index) => {
                        return (
                          <li key={index}>
                            <a href={"/profile/" + manager.username}>
                              {(manager.first_name || "") +
                                " " +
                                (manager.last_name || "")}
                            </a>
                            <p>{manager.phone}</p>
                            <a href={"mailto:" + manager.email}>
                              {manager.email || ""}
                            </a>
                          </li>
                        );
                      })}
                    </ol>
                  ) : (
                    <span>None</span>
                  )}
                  <h5>Franchisees</h5>
                  {this.state.store.franchisees &&
                  this.state.store.franchisees.length > 0 ? (
                    <ol>
                      {this.state.store.franchisees.map((franchise, index) => {
                        return (
                          <li key={index}>
                            <a href={"/profile/" + franchise.username}>
                              {(franchise.first_name || "") +
                                " " +
                                (franchise.last_name || "")}
                            </a>
                            <p>{franchise.phone}</p>
                            <a href={"mailto:" + franchise.email}>
                              {franchise.email || ""}
                            </a>
                          </li>
                        );
                      })}
                    </ol>
                  ) : (
                    <span>None</span>
                  )}
                </div>
              ) : (
                ""
              )}
            </Grid.Column>
          ) : (
            ""
          )}
          <Grid.Column
            width={this.state.store && this.state.store.managers ? 8 : 16}
          >
            <h5>{this.state.store.store.title || ""}</h5>
            <p>{this.state.store.store.street1 || ""}</p>
            <p>{this.state.store.store.street2 || ""}</p>
            <h5>Phone</h5>
            <p>{this.state.store.store.phone || ""}</p>
            <h5>Email</h5>
            {this.state.store.store.email ? (
              <a href={"mailto:" + this.state.store.store.email} target="_top">
                {this.state.store.store.email}
              </a>
            ) : (
              ""
            )}
            {this.state.store.store.email2 ? (
              <a href={"mailto:" + this.state.store.store.email2} target="_top">
                {this.state.store.store.email2}
              </a>
            ) : (
              ""
            )}
            <h5>Fax</h5>
            <p>{this.state.store.store.fax || ""}</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  handleEditStore = (data) => {
    this.setState({ changeStore: true });
  };

  handleChangeStore = (event, data) => {
    const foundStore = this.state.stores.find(
      (element) => element.store.id === parseInt(data.value)
    );
    if (foundStore) {
      this.setState({ changeStore: false, store: foundStore });
    }
  };

  handleCloseStoresModal = () => {
    this.setState({ openModal: false });
  };

  getStoresModalInfos = () => {
    return (
      <Modal
        className="stores-info-modal"
        open={this.state.openModal}
        onClose={this.handleCloseStoresModal}
        size="mini"
      >
        <Modal.Header>Store Information</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {this.state.openModal ? (
              <div>
                <div className="modal-stores-info">
                  <h5>{this.state.store.store.title || ""}</h5>
                  <p>{this.state.store.store.street1 || ""}</p>
                  <p>{this.state.store.store.street2 || ""}</p>
                  <h5>Phone</h5>
                  <p>{this.state.store.store.phone || ""}</p>
                  <h5>Email</h5>
                  {this.state.store.store.email ? (
                    <a
                      href={"mailto:" + this.state.store.store.email}
                      target="_top"
                    >
                      {this.state.store.store.email}
                    </a>
                  ) : (
                    ""
                  )}
                  {this.state.store.store.email2 ? (
                    <a
                      href={"mailto:" + this.state.store.store.email2}
                      target="_top"
                    >
                      {this.state.store.store.email2}
                    </a>
                  ) : (
                    ""
                  )}
                  <h5>Fax</h5>
                  <p>{this.state.store.store.fax || ""}</p>
                </div>
                <br />
                {this.state.store &&
                this.state.store.managers &&
                Array.isArray(this.state.store.managers) ? (
                  <div className="modal-stores-info">
                    <h5>Managers</h5>
                    {this.state.store.managers &&
                    this.state.store.managers.length > 0 ? (
                      <ol>
                        {this.state.store.managers.map((manager, index) => {
                          return (
                            <li key={index}>
                              <a
                                href={"/profile/" + manager.username}
                                onClick={() =>
                                  this.handleRedirectToProfile(manager.username)
                                }
                              >
                                {(manager.first_name || "") +
                                  " " +
                                  (manager.last_name || "")}
                              </a>
                              <p>{manager.phone}</p>
                              <a href={"mailto:" + manager.email}>
                                {manager.email || ""}
                              </a>
                            </li>
                          );
                        })}
                      </ol>
                    ) : (
                      <span>None</span>
                    )}
                    <h5>Franchisees</h5>
                    {this.state.store.franchisees &&
                    this.state.store.franchisees.length > 0 ? (
                      <ol>
                        {this.state.store.franchisees.map(
                          (franchise, index) => {
                            return (
                              <li key={index}>
                                <a
                                  href={"/profile/" + franchise.username}
                                  onClick={() =>
                                    this.handleRedirectToProfile(
                                      franchise.username
                                    )
                                  }
                                >
                                  {(franchise.first_name || "") +
                                    " " +
                                    (franchise.last_name || "")}
                                </a>
                                <p>{franchise.phone}</p>
                                <a href={"mailto:" + franchise.email}>
                                  {franchise.email || ""}
                                </a>
                              </li>
                            );
                          }
                        )}
                      </ol>
                    ) : (
                      <span>None</span>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={this.handleCloseStoresModal} inverted>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  // FILTERS END

  redirectBack = () => {
    let url = process.env.REACT_APP_UI_URL;
    window.location.href = url + "/management/checklists";
  };

  getChecklist = () => {
    if (this.state.editId && this.state.editId !== "new") {
      new Promise((resolve, reject) => {
        let path = "/checklists/" + this.state.editId;
        this.request.get(path, resolve, reject);
      })
        .then((response) => {
          let checklist = JSON.parse(response);
          if (checklist && checklist.success === true && checklist.data) {
            this.setState({ checklist: checklist.data });
          } else {
            this.setState({ checklist: {} });
          }
        })
        .catch((error) => {
          this.setState({ checklist: {} });
          if (error) {
            NotificationManager.error(error, "Error");
          } else {
            NotificationManager.error("Could not get Checklist", "Error");
          }
        });
    } else {
      this.setState({
        checklist: {
          task: "",
          note: null,
        },
      });
    }
  };

  saveNewAddress = () => {
    let checklist = this.state.checklist;
    let type = this.state.pickedChecklistType.value;
    let store = this.state.store;
    let editId = this.state.editId;

    if (editId === "new" && checklist.task) {
      new Promise((resolve, reject) => {
        let path = "/checklists/" + store.store.id + "/" + type;
        let params = {
          task: checklist.task,
          note: checklist.note || null,
        };
        this.request.post(path, params, resolve, reject);
      })
        .then((response) => {
          NotificationManager.success("Successfully saved task", "Success");
          this.redirectBack();
        })
        .catch((error) => {
          if (error) {
            NotificationManager.error(error, "Error");
          } else {
            NotificationManager.error("Could not save task", "Error");
          }
        });
    }
  };

  updateAddress = () => {
    let checklist = this.state.checklist;
    let editId = this.state.editId;

    if (editId !== "new" && checklist.task) {
      new Promise((resolve, reject) => {
        let path = "/checklists/" + editId;
        let params = {
          task: checklist.task,
          note: checklist.note || null,
        };
        this.request.put(path, params, resolve, reject);
      })
        .then((response) => {
          NotificationManager.success("Successfully updated task", "Success");
          this.redirectBack();
        })
        .catch((error) => {
          if (error) {
            NotificationManager.error(error, "Error");
          } else {
            NotificationManager.error("Could not update task", "Error");
          }
        });
    }
  };

  handleInputChange = (event, field) => {
    let checklist = this.state.checklist;
    checklist[field] = event.target.value;
    this.setState({ checklist: checklist });
  };

  handleChangeType = (event, data) => {
    const foundType = this.state.checklistOptions.find(
      (element) => element.value === data.value
    );
    if (foundType) {
      this.setState({ pickedChecklistType: foundType });
    }
  };

  getStoresHtml = () => {
    return (
      <div className="checklists-admin-edit-stores">
        <div className="checklists-admin-edit-content">
          <div className="store-number">
            <Select
              text={this.state.store.store.title}
              value={this.state.store.store.id}
              options={this.state.options}
              onChange={this.handleChangeStore}
            />
          </div>
        </div>
        <div className="store-info-icon">
          <div className="ui icon buttons filters-buttons">
            <button className="ui button" onClick={this.handleStoresInfoButton}>
              {!this.state.openModal ? (
                <Popup
                  on="click"
                  trigger={<i className="info circle icon"></i>}
                  size="small"
                  pinned
                  wide="very"
                  position={
                    this.state.mobileSize ? "bottom center" : "bottom left"
                  }
                >
                  {this.state.renderStoreInfos
                    ? this.getStoresPopupInfos()
                    : ""}
                </Popup>
              ) : (
                ""
              )}
            </button>
            <button className="ui button" onClick={this.handleEditStore}>
              <i className="edit outline icon"></i>
            </button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="checklists-admin-edit-root">
        {this.getStoresModalInfos()}
        <Title paramsPageTitle="Checklists" />
        {this.state.editId === "new" && this.state.store.length > 0
          ? this.getStoresHtml()
          : ""}
        {this.state.editId === "new" ? (
          <div className="checklists-admin-edit-types-select">
            <Select
              text={this.state.pickedChecklistType.name}
              value={this.state.pickedChecklistType.value}
              options={this.state.checklistOptions}
              onChange={this.handleChangeType}
            />
          </div>
        ) : (
          ""
        )}
        <div>
          <div className="checklists-admin-edit-inputs">
            <b>Task:</b>
            <br />
            <Input
              fluid
              value={this.state.checklist.task || ""}
              onChange={(e) => this.handleInputChange(e, "task")}
            />
          </div>
          {this.state.pickedChecklistType.value === "weekly" ||
          this.state.editType === "weekly" ? (
            <div className="checklists-admin-edit-inputs">
              <b>Note:</b>
              <br />
              <Input
                fluid
                value={this.state.checklist.note || ""}
                onChange={(e) => this.handleInputChange(e, "note")}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <Button
          color="orange"
          onClick={
            this.state.editId === "new"
              ? this.saveNewAddress
              : this.updateAddress
          }
        >
          Save
        </Button>
        <Button color="orange" onClick={this.redirectBack}>
          Cancel
        </Button>
        <NotificationContainer />
      </div>
    );
  }
}

export default ChecklistsEdit;
