import React from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "./../../assets/css/pages/managment/incident-report.css";
import { Button, Icon } from "semantic-ui-react";
import Request from "../../Request";
import Title from "components/Title";
import {
  formatDate,
  formatTimeAMPM,
} from "../../hooks/DateTimeUtility";
import { useRolePermissions } from "../../hooks/useRolePermissions";

const { redirectToForbiddenIfUnauthorized, isPermitted, getPermissions } = useRolePermissions();

class IncidentReportView extends React.Component {
  state = {
    viewId: null,
    date: new Date(),
    showEdit: true,
    incidentReport: [],
    renderIncidentReport: false,
  };

  constructor() {
    super();
    this.request = new Request();
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ viewId: this.props.match.params.id }, () => {
        this.getIncidentReport();
      });
    }
  }

  getIncidentReport = () => {
    if (this.state.viewId) {
      new Promise((resolve, reject) => {
        let path = "/incident-report/" + this.state.viewId + "/view";
        this.request.get(path, resolve, reject);
      })
        .then((response) => {
          let incidentReport = JSON.parse(response);
          if (
            incidentReport &&
            incidentReport.success === true &&
            incidentReport.data
          ) {
            this.setState({ incidentReport: incidentReport.data }, () => {
              this.setState({ renderIncidentReport: true });
            });
          } else {
            this.setState({ incidentReport: {} }, () => {
              this.setState({ renderIncidentReport: true });
            });
          }
        })
        .catch((error) => {
          this.setState({ incidentReport: {} }, () => {
            this.setState({ renderIncidentReport: true });
          });
          if (error) {
            NotificationManager.error(error, "Error");
          } else {
            NotificationManager.error("Could not get Incident Report", "Error");
          }
        });
    }
  };

  showIncidentReport = () => {
    let incidentReport = this.state.incidentReport;
    console.log(incidentReport.storeId);
    return (
      <div className="incident-report-view-header-description-div">
        <Title paramsPageTitle="Incident Report View" />
        <div className="incident-report-view-links"></div>
        {isPermitted("incident-reports-edit", incidentReport.storeId) ? (
          <div className="incident-report-view-buttons">
            <Button
              className="incident-report-view-button"
              icon
              labelPosition="left"
              color="orange"
              size="tiny"
              onClick={() => this.redirectToEdit(incidentReport.id)}
            >
              <Icon name="pencil" />
              Edit
            </Button>
          </div>
        ) : (
          ""
        )}
        <br></br>
        <div>
          <table className="incident-report-view-table">
            <thead>
              <tr>
                <th className="incident-report-view-table-header">Name</th>
                <th className="incident-report-view-table-header">Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Incident Number</strong>
                </td>
                <td>IR- {incidentReport.incidentNumber}</td>
              </tr>
              <tr>
                <td>
                  <strong>Subject</strong>
                </td>
                <td>{incidentReport.subject}</td>
              </tr>
              <tr>
                <td>
                  <strong>Description</strong>
                </td>
                <td>{incidentReport.description}</td>
              </tr>
              <tr>
                <td>
                  <strong>Store</strong>
                </td>
                <td>{incidentReport.storeName}</td>
              </tr>
              <tr>
                <td>
                  <strong>Incident Date Time</strong>
                </td>
                <td>
                  {formatDate(incidentReport.incidentDate.date)}{" "}
                  {formatTimeAMPM(incidentReport.incidentTime.date)}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Manager</strong>
                </td>
                <td>{incidentReport.managerName}</td>
              </tr>
              <tr>
                <td>
                  <strong>Employees On Duty</strong>
                </td>
                <td>
                  {" "}
                  <div>{incidentReport.employeeOnDutyName1}</div>
                  <div>{incidentReport.employeeOnDutyName2}</div>
                  <div>{incidentReport.employeeOnDutyName3}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Witnesses</strong>
                </td>
                <td>
                  <div>
                    {incidentReport.witnessName1 ?? ""}{" "}
                    {incidentReport.witnessPhone1
                      ? `(${incidentReport.witnessPhone1})`
                      : ""}
                  </div>
                  <div>
                    {incidentReport.witnessName2 ?? ""}{" "}
                    {incidentReport.witnessPhone2
                      ? `(${incidentReport.witnessPhone2})`
                      : ""}
                  </div>
                  <div>
                    {incidentReport.witnessName3 ?? ""}{" "}
                    {incidentReport.witnessPhone3
                      ? `(${incidentReport.witnessPhone3})`
                      : ""}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Employee Signature</strong>
                </td>
                <td>{incidentReport.employeeSignature}</td>
              </tr>
              <tr>
                <td>
                  <strong>Entered Date</strong>
                </td>
                <td> {formatDate(incidentReport.enteredDate.date)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br></br>
        <Button color="red" onClick={this.redirectBack}>
          Back
        </Button>
      </div>
    );
  };

  redirectToEdit = (id) => {
    let url = process.env.REACT_APP_UI_URL;
    window.location.href = url + "/management/incident-report/" + id + "/edit";
  };

  redirectBack = () => {
    let url = process.env.REACT_APP_UI_URL;
    window.location.href = url + "/management/incident-report-list";
  };

  render() {
    return (
      <div className="vendors-admin-view-root">
        {this.state.renderIncidentReport ? this.showIncidentReport() : ""}
        <NotificationContainer />
      </div>
    );
  }
}

export default IncidentReportView;
