import React from 'react';
import { Card, Icon } from "semantic-ui-react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import './../assets/css/pages/dashboard.css';
import Request from "../Request";
import ModalImage from "react-modal-image";
import DefaultImage from "./../assets/img/default_image.png";
import DashboardMenu from 'components/DashboardMenu';
import CheckListFilters from 'components/CheckListFilters';
class Dashboard extends React.Component {
    state = {
        dashboard: {},
        urlParams: {
            store: null,
            date: null
        }
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.getDashboardData();
    }

    getDashboardData = () => {
        new Promise((resolve, reject) => {
            let path = '/dashboard';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let dashboard = JSON.parse(response);
                if (dashboard && dashboard.success === true && dashboard.data) {
                    this.setState({ dashboard: dashboard.data });
                }
                else {
                    this.setState({ dashboard: {} });
                }
            })
            .catch(error => {
                this.setState({ dashboard: [] });
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Dashboard data', 'Error');
                }
            });
    };

    getAnnouncementCardContent = () => {
        return (
            <div dangerouslySetInnerHTML={{ __html: this.state.dashboard.announcement || '' }}></div>
        )
    };

    redirectToEditDashboard = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/dashboard';
    };

    redirectToPromotions = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/promotion'
    };

    getPromotionsCardContent = () => {
        return (
            <div>
                <div className="home-promotion-card-image-div">
                    {this.state.dashboard.file ?
                        <ModalImage
                            className="home-promotion-card-images"
                            small={this.state.dashboard.file}
                            large={this.state.dashboard.file}
                            alt=""
                        />
                        :
                        <img
                            className="home-promotion-card-images"
                            src={DefaultImage}
                            alt={''}
                            onClick={this.redirectToEditDashboard}
                        />
                    }
                </div>
                <div className="home-promotion-card-info-div">
                    <p>{this.state.dashboard.description}</p>
                    <a href={'/promotion'}>Details <Icon name="external alternate"></Icon></a>
                </div>
            </div>
        )
    };

    onGetStores = (store) => {
        if (store && store.id) {
            let urlParams = this.state.urlParams;
            if (store.id !== parseInt(urlParams.store)) {
                urlParams.store = store.id;
            }
            this.setState({ store: store, renderShiftComments: false, urlParams: urlParams });
        }
    };

    handleChangeStore = (store) => {
        let urlParams = this.state.urlParams;
        if (store.id !== parseInt(urlParams.store)) {
            urlParams.store = store.id;
        }
        this.setState({ store: store, renderShiftComments: false, urlParams: urlParams });
    };

    render() {
        return (

            <div className="home-page">
                <CheckListFilters
                    paramsStoreId={this.state.urlParams.store}
                    date={this.state.urlParams.date}
                    onGetStores={this.onGetStores}
                    onChangeStore={this.handleChangeStore}
                    showStoreInfo={true}
                    showDatePicker={false}
                    welcomeMsg="Welcome to the  Dripp Portal"
                />
                <div className="home-menu-wrap">
                    <DashboardMenu activeStore={this.state.store} />
                </div>
                <div className="home-cards-div">
                    <Card className="home-card" fluid>
                        <Card.Content className="home-announcement-card-header" header="Important announcement" />
                        <Card.Content className="home-announcement-card-content" description={this.getAnnouncementCardContent()} />
                    </Card>
                    <br />
                    <Card className="home-card" fluid>
                        <Card.Content className="home-promotion-card-header" header={this.state.dashboard.title} />
                        <Card.Content className="home-promotion-card-content" description={this.getPromotionsCardContent()} />
                    </Card>
                </div>
                <NotificationContainer />
            </div>
        )
    }
}

export default Dashboard;