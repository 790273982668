import React from "react";
import CheckListFilters from "../../components/CheckListFilters";
import "../../assets/css/pages/check-lists/dailyCheckList.css";
import { Button, Placeholder } from "semantic-ui-react";
import DailyCheckListCard from "../../components/DailyCheckListCard";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import Request from "../../Request";
import { useRolePermissions } from "./../../hooks/useRolePermissions";
import Forbidden from "pages/Forbidden";

const { redirectToForbiddenIfUnauthorized, isPermitted } = useRolePermissions();

class DailyChecklist extends React.Component {
  state = {
    taskNameChanged: false,
    date: new Date(),
    store: {},
    dailyList: {
      open: {},
      mid: {},
      close: {},
      weekly: {},
    },
    urlParams: {
      store: null,
      date: null,
    },
    renderDailyList: false,
  };

  constructor() {
    super();
    this.request = new Request();
  }

  componentDidMount() {
    this.initializeParams();
    // redirectToForbiddenIfUnauthorized('daily-checklist-view');
    this.setState({ renderDailyList: false });
  }

  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  initializeParams = () => {
    let stateParams = this.state.urlParams;
    if (this.props.match.params) {
      if (this.props.match.params.store) {
        stateParams.store = this.props.match.params.store;
      }
      if (this.props.match.params.date) {
        let date = new Date(this.props.match.params.date);
        if (this.isValidDate(date)) {
          stateParams.date = date;
        } else {
          stateParams.date = new Date();
        }
      } else {
        stateParams.date = new Date();
      }
      this.setState({ urlParams: stateParams });
    } else {
      this.setState({ urlParams: { store: null, date: new Date() } });
    }
  };

  onGetStores = (store) => {
    if (store && store.id) {
      let urlParams = this.state.urlParams;
      if (store.id !== parseInt(urlParams.store)) {
        urlParams.store = store.id;
      }
      this.setState(
        { store: store, renderDailyList: false, urlParams: urlParams },
        () => {
          this.getData();
        }
      );
    }
  };

  getData = () => {
    if (this.state.store && this.state.store.id) {
      new Promise((resolve, reject) => {
        let path =
          "/daily-checklist/" +
          this.state.store.id +
          "/" +
          this.formatDate(this.state.date);
        this.request.get(path, resolve, reject);
      })
        .then((response) => {
          let checkListCardsData = JSON.parse(response);
          if (
            checkListCardsData &&
            checkListCardsData.success === true &&
            checkListCardsData.data
          ) {
            let dailyList = this.state.dailyList;
            ["open", "mid", "close", "weekly"].forEach((shift) => {
              if (checkListCardsData.data[shift]) {
                dailyList[shift] = checkListCardsData.data[shift];
              } else {
                dailyList[shift] = {};
              }
            });
            this.setState({ dailyList: dailyList }, () => {
              window.history.replaceState(
                {},
                null,
                "/check-lists/daily-check/" +
                  this.state.urlParams.store +
                  "/" +
                  this.formatDate(this.state.urlParams.date)
              );
              this.setState({ renderDailyList: true });
            });
          } else {
            this.setState({ renderDailyList: true });
          }
        })
        .catch((error) => {
          let defaultDailyListObject = {
            open: {},
            mid: {},
            close: {},
            weekly: {},
          };
          this.setState({ dailyList: defaultDailyListObject }, () => {
            this.setState({ renderDailyList: true });
          });
          if (error) {
            NotificationManager.error(error, "Error");
          } else {
            NotificationManager.error("Could not get Daily Lists", "Error");
          }
        });
    }
  };

  transformDataForUpdate = () => {
    let dailyTasks = this.state.dailyList;
    let updateData = { names: [] };
    ["open", "mid", "close", "weekly"].forEach((shift) => {
      if (dailyTasks[shift] && Array.isArray(dailyTasks[shift])) {
        dailyTasks[shift].forEach((task) => {
          updateData.names.push({
            checklist_id: task.checklist_id,
            name_id: task.name_id,
            name: task.name,
          });
        });
      }
    });
    return updateData;
  };

  formatDate = (date) => {
    if (date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    return "";
  };

  handleChangeDate = (event, date) => {
    let urlParams = this.state.urlParams;
    if (date.value !== urlParams.date) {
      urlParams.date = date.value;
    }
    this.setState(
      { date: date.value, renderDailyList: false, urlParams: urlParams },
      () => {
        this.getData();
      }
    );
  };

  handleChangeStore = (store) => {
    let urlParams = this.state.urlParams;
    if (store.id !== parseInt(urlParams.store)) {
      urlParams.store = store.id;
    }
    this.setState(
      { store: store, renderDailyList: false, urlParams: urlParams },
      () => {
        this.getData();
      }
    );
  };

  handleChangeDailyList = (tasks) => {
    if (tasks) {
      this.setState({ dailyList: tasks });
    }
  };

  handleSaveDailyLists = () => {
    if (!isPermitted("daily-checklist-create", this.state.store.id)) {
      NotificationManager.error("You are not authorized", "Error");
      return;
    }
    let params = this.transformDataForUpdate();
    new Promise((resolve, reject) => {
      let path = "/daily-checklist/" + this.formatDate(this.state.date);
      this.request.post(path, params, resolve, reject);
    })
      .then((response) => {
        NotificationManager.success(
          "Successfully updated Daily Lists",
          "Success"
        );
      })
      .catch((error) => {
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not update Daily Lists", "Error");
        }
      });
  };

  render() {
    let pagePermission = "daily-checklist-view";
    console.log(this.state.store.id);
    return (
      <div>
        {isPermitted("daily-checklist-view", this.state.store.id) ? (
          <div className="daily-checklist">
            <CheckListFilters
              paramsStoreId={this.state.urlParams.store}
              date={this.state.urlParams.date}
              onGetStores={this.onGetStores}
              maxDate={new Date()}
              onChangeDate={this.handleChangeDate}
              onChangeStore={this.handleChangeStore}
              pageTitle="Daily Checklist"
              pagePermission={pagePermission}
            />
            <div className="daily-checklists-content">
              {this.state.renderDailyList ? (
                <div>
                  <DailyCheckListCard
                    handleChangeDailyList={(task) =>
                      this.handleChangeDailyList(task)
                    }
                    shiftType="open"
                    tasks={this.state.dailyList}
                    store={this.state.store}
                    date={this.state.date}
                  />
                  <DailyCheckListCard
                    handleChangeDailyList={(task) =>
                      this.handleChangeDailyList(task)
                    }
                    shiftType="mid"
                    tasks={this.state.dailyList}
                    store={this.state.store}
                    date={this.state.date}
                  />
                  <DailyCheckListCard
                    handleChangeDailyList={(task) =>
                      this.handleChangeDailyList(task)
                    }
                    shiftType="close"
                    tasks={this.state.dailyList}
                    store={this.state.store}
                    date={this.state.date}
                  />
                  <DailyCheckListCard
                    handleChangeDailyList={(task) =>
                      this.handleChangeDailyList(task)
                    }
                    shiftType="weekly"
                    tasks={this.state.dailyList}
                    store={this.state.store}
                    date={this.state.date}
                  />
                </div>
              ) : (
                <div>
                  <Placeholder fluid>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                  <br />
                  <Placeholder fluid>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                  <br />
                  <Placeholder fluid>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                  <Placeholder fluid>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder>
                </div>
              )}
            </div>
            <div className="daily-checklist-save-button">
              <Button
                content="Save"
                onClick={this.handleSaveDailyLists}
                color="orange"
              />
            </div>
            <NotificationContainer />
          </div>
        ) : (
          <Forbidden target="store" />
        )}
      </div>
    );
  }
}

export default DailyChecklist;
