import React from "react";
import {
  Button,
  Card,
  Grid,
  Header,
  Image,
  Modal,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  TableFooter,
  Menu,
  MenuItem,
  Icon,
  Table,
  FormGroup,
  FormField,
  Form,
  FormSelect,
  FormInput,
  Select,
} from "semantic-ui-react";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import moment from "moment";
import "./../../assets/css/pages/managment/incident-report.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Request from "../../Request";
import Title from "components/Title";
import {
  formatDate,
  formatTime,
  formatTimeAMPM,
} from "../../hooks/DateTimeUtility";
import { ucwords } from "../../hooks/Helpers";
import { useRolePermissions } from "../../hooks/useRolePermissions";

const { redirectToForbiddenIfUnauthorized, isPermitted } = useRolePermissions();

class IncidentReportList extends React.Component {
  state = {
    date: new Date(),
    selectionRange: {
      startDate: moment(),
      startDateId: "1",
      endDate: moment(),
      endDateId: "2",
      focusedInput: null,
    },

    showTrashed: false,
    customSize: window.innerWidth <= 1150,
    incidentReports: [],
    renderIncidentReports: false,
    modalOpen: false,
    removeId: null,
    managerOptions: [],
    storeOptions: [],
    userOptions: [],
    selectedFilters: {},
  };

  constructor() {
    super();
    this.request = new Request();
  }

  componentDidMount() {
    this.getStoresManagers();
    this.getManagers();
    this.setDefaultFilters();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ customSize: window.innerWidth <= 1150 });
  }

  setDefaultFilters = () => {
    const { selectedFilters } = this.state;
    this.setState(
      {
        selectedFilters: {
          ...selectedFilters,
          ["incidentStartDate"]: formatDate(
            this.state.selectionRange.startDate
          ),
          ["incidentEndDate"]: formatDate(this.state.selectionRange.endDate),
        },
      },
      () => {
        this.filterIncidentReports();
      }
    );
  };

  getStoresManagers = () => {
    new Promise((resolve, reject) => {
      let path = "/stores-as-managers";
      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let stores = JSON.parse(response);
        if (stores && stores.success === true && stores.data) {
          this.setState({ stores: stores.data, store: stores.data[0] }, () => {
            this.transformStoresToOptions();
          });
        } else {
          this.setState({ stores: [] });
        }
      })
      .catch((error) => {
        this.setState({ stores: [] });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get Stores", "Error");
        }
      });
  };

  transformStoresToOptions = () => {
    let stores = this.state.stores;
    let options = [];
    options.push({ id: null, key: null, value: null, text: "Select" });
    stores.forEach((store, index) => {
      let tempOption = {
        id: store.id,
        key: store.id,
        value: store.id,
        text: store.title,
      };
      options.push(tempOption);
    });

    this.setState({ storeOptions: options });
  };

  getManagers = () => {
    new Promise((resolve, reject) => {
      let path = "/user-managers";
      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let users = JSON.parse(response);
        if (users && users.success === true && users.data) {
          this.setState({ users: users.data }, () => {
            this.setState({ renderUsers: true });
            this.transformUsersToOptions();
          });
        } else {
          this.setState({ users: [] }, () => {
            this.setState({ renderUsers: true });
          });
        }
      })
      .catch((error) => {
        this.setState({ users: [] }, () => {
          this.setState({ renderUsers: true });
        });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get Users", "Error");
        }
      });
  };

  transformUsersToOptions = () => {
    let users = this.state.users;
    let options = [];
    options.push({ id: null, key: null, value: null, text: "Select" });
    users.forEach((user, index) => {
      let tempOption = {
        id: user.id,
        key: user.id,
        value: user.id,
        text: user.name,
      };
      options.push(tempOption);
    });
    this.setState({ userOptions: options });
  };

  handleSelectChanged = (e, data, filterName) => {
    const { selectedFilters } = this.state;
    this.setState({
      selectedFilters: {
        ...selectedFilters,
        [filterName]: data.value,
      },
    });
  };

  handleChangeDateInput = (startDate, endDate, filterName) => {
    const { selectedFilters } = this.state;
    let selection = this.state.selectionRange;
    selection.startDate = startDate;
    selection.endDate = endDate;
    this.setState({
      selectedFilters: {
        ...selectedFilters,
        [filterName + "StartDate"]: formatDate(startDate),
        [filterName + "EndDate"]: formatDate(endDate),
      },
    });
  };

  changedDatePickerFocus = (focusedInput) => {
    let selection = this.state.selectionRange;
    selection.focusedInput = focusedInput;
    this.setState({ selectionRange: selection });
  };

  deleteIncidentReport = (id) => {
    this.setState({ modalOpen: false });
    if (id) {
      new Promise((resolve, reject) => {
        let path = "/incident-report/" + id;
        this.request.delete(path, resolve, reject);
      })
        .then((response) => {
          response = JSON.parse(response);
          if (response && response.success === true) {
            NotificationManager.success(
              "Successfully removed Incident Report",
              "Success"
            );
            this.getIncidentReports();
          }
        })
        .catch((error) => {
          if (error) {
            NotificationManager.error(error, "Error");
          } else {
            NotificationManager.error(
              "Could not remove Incident Report",
              "Error"
            );
          }
        });
    } else {
      NotificationManager.error("Could not remove Incident Report", "Error");
    }
  };

  redirectToAddPage = (id) => {
    let url = process.env.REACT_APP_UI_URL;
    window.location.href = url + "/management/incident-report/";
  };

  redirectToViewPage = (id) => {
    let url = process.env.REACT_APP_UI_URL;
    window.location.href = url + "/management/incident-report/" + id;
  };

  handleCloseModal = () => {
    this.setState({
      removeId: null,
      modalOpen: false,
    });
  };

  getConfirmationModal = () => {
    let removeId = this.state.removeId;

    if (removeId || removeId === 0) {
      return (
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleCloseModal}
          size="small"
        >
          <Header content="Delete User" />
          <Modal.Content>
            <h3>Are you sure you want to delete this Incident Report?</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={this.handleCloseModal} inverted>
              No
            </Button>
            <Button
              color="green"
              onClick={() => this.deleteIncidentReport(removeId)}
              inverted
            >
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return "";
  };

  removeIncidentReportConfirmation = (id) => {
    this.setState({ removeId: id, modalOpen: true });
  };

  filterIncidentReports = () => {
    const filters = { filters: this.state.selectedFilters };
    new Promise((resolve, reject) => {
      let path = "/incident-reports/";
      this.request.post(path, filters, resolve, reject);
    })
      .then((response) => {
        let incidentReports = JSON.parse(response);
        if (
          incidentReports &&
          incidentReports.success === true &&
          incidentReports.data
        ) {
          this.setState({ incidentReports: incidentReports.data }, () => {
            this.setState({ renderIncidentReports: true });
          });
        } else {
          this.setState({ incidentReports: [] }, () => {
            this.setState({ renderIncidentReports: true });
          });
        }
      })
      .catch((error) => {
        this.setState({ incidentReports: [] }, () => {
          this.setState({ renderIncidentReports: true });
        });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get Incident Reports", "Error");
        }
      });
  };

  removeFilters = () => {
    this.setState(
      {
        selectedFilters: {
          storeId: null,
          managerId: null,
          incidentStartDate: formatDate(moment()),
          incidentEndDate: formatDate(moment()),
        },
        selectionRange: {
          startDate: moment(),
          startDateId: "1",
          endDate: moment(),
          endDateId: "2",
          focusedInput: null,
        },
      },
      () => {
        this.filterIncidentReports();
      }
    );
  };

  getFilters = () => {
    return (
      <Form>
        <FormGroup widths="equal">
          <FormField>
            <label>Incident Date</label>
            <div className="order-history-date-filters-div-range">
              <DateRangePicker
                startDate={this.state.selectionRange.startDate}
                startDateId={this.state.selectionRange.startDateId}
                endDate={this.state.selectionRange.endDate}
                endDateId={this.state.selectionRange.endDateId}
                onDatesChange={({ startDate, endDate }) =>
                  this.handleChangeDateInput(startDate, endDate, "incident")
                }
                focusedInput={this.state.selectionRange.focusedInput}
                onFocusChange={(focusedInput) =>
                  this.changedDatePickerFocus(focusedInput)
                }
                minDate={moment(moment().subtract(100, "years"))}
                isOutsideRange={(day) =>
                  moment() < moment(moment().subtract(100, "years"))
                }
                numberOfMonths={this.state.tabletSize ? 1 : 2}
                displayFormat="YYYY-MM-DD"
              />
            </div>
          </FormField>
          <FormField>
            <label>Stores</label>
            <Select
              placeholder="Select"
              options={this.state.storeOptions}
              value={this.state.selectedFilters.storeId}
              onChange={(e, data) =>
                this.handleSelectChanged(e, data, "storeId")
              }
            />
          </FormField>
          <FormField>
            <label>Managers</label>
            <Select
              placeholder="Select"
              options={this.state.userOptions}
              value={this.state.selectedFilters.managerId}
              onChange={(e, data) =>
                this.handleSelectChanged(e, data, "managerId")
              }
            />
          </FormField>
          <FormField>
            <label className="visibility-hidden">filter</label>
            <Button color="orange" onClick={() => this.filterIncidentReports()}>
              Filter <Icon name="filter" />
            </Button>
          </FormField>
          <FormField>
            <label className="visibility-hidden">Reset</label>
            <Button color="orange" onClick={() => this.removeFilters()}>
              Reset
            </Button>
          </FormField>
        </FormGroup>
      </Form>
    );
  };

  getIncidentReports = () => {
    const filters = { filters: this.state.selectedFilters };
    new Promise((resolve, reject) => {
      let path = "/incident-reports/";
      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let incidentReports = JSON.parse(response);
        if (
          incidentReports &&
          incidentReports.success === true &&
          incidentReports.data
        ) {
          this.setState({ incidentReports: incidentReports.data }, () => {
            this.setState({ renderIncidentReports: true });
          });
        } else {
          this.setState({ incidentReports: [] }, () => {
            this.setState({ renderIncidentReports: true });
          });
        }
      })
      .catch((error) => {
        this.setState({ incidentReports: [] }, () => {
          this.setState({ renderIncidentReports: true });
        });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get Incident Reports", "Error");
        }
      });
  };

  render() {
    return isPermitted("incident-reports-view") ? (
      <div className="users-admin-root">
        <Title paramsPageTitle="Incident Report List" />
        <div>
          {isPermitted("incident-reports-create") ? (
            <Button
              icon
              onClick={(e) => this.redirectToAddPage("new")}
              className="orange"
            >
              <Icon name="plus" /> Add Incident Report
            </Button>
          ) : (
            ""
          )}
        </div>
        <div className="incident-report-save-button-div">
          <div className="table-filter">{this.getFilters()}</div>

          <Table basic unstackable selectable>
            <TableHeader>
              <TableRow>
                <TableHeaderCell width={2}>Incident Number</TableHeaderCell>
                <TableHeaderCell width={2}>Submitted By</TableHeaderCell>
                <TableHeaderCell width={2}>Incident Date</TableHeaderCell>
                <TableHeaderCell width={2}>Incident Time</TableHeaderCell>
                <TableHeaderCell width={2}>Store</TableHeaderCell>
                <TableHeaderCell width={2}>Manager</TableHeaderCell>
                <TableHeaderCell width={2}>Subject</TableHeaderCell>
                <TableHeaderCell width={2}>Date Submitted</TableHeaderCell>
                <TableHeaderCell width={2}>Action</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              { this.state.incidentReports.length > 0 ? (
              this.state.incidentReports.map((incidentReport, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      IR- {incidentReport.incidentNumber || ""}
                    </TableCell>
                    <TableCell>
                      {ucwords(incidentReport.employeeSignature) || ""}
                    </TableCell>
                    <TableCell>
                      {formatDate(incidentReport.incidentDate.date) || ""}
                    </TableCell>
                    <TableCell>
                      {formatTimeAMPM(incidentReport.incidentTime.date) || ""}
                    </TableCell>
                    <TableCell>{incidentReport.storeName || ""}</TableCell>
                    <TableCell>{incidentReport.managerName || ""}</TableCell>
                    <TableCell>{incidentReport.subject || ""}</TableCell>
                    <TableCell>
                      {incidentReport.enteredDate.date !== ""
                        ? formatDate(incidentReport.enteredDate.date)
                        : ""}
                    </TableCell>
                    <TableCell>
                      <Button.Group icon>
                        <div className="action-buttons">
                          <Button
                            size="tiny"
                            onClick={(e) =>
                              this.redirectToViewPage(incidentReport.id)
                            }
                            title="View Incident Report"
                          >
                            <Icon name="eye" />
                          </Button>

                          {isPermitted(
                            "incident-reports-delete",
                            incidentReport.storeId
                          ) ? (
                            <Button
                              size="tiny"
                              title="Deactivate User"
                              onClick={() =>
                                this.removeIncidentReportConfirmation(
                                  incidentReport.id
                                )
                              }
                            >
                              <Icon name="trash" />
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </Button.Group>
                    </TableCell>
                  </TableRow>
                );
              })) : (
                <TableRow>
                  <TableCell colSpan={10}>No data available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        {this.getConfirmationModal()}
        <NotificationContainer />
      </div>
    ) : (
      <TableRow>
        <TableHeaderCell colSpan="10">
          {isPermitted("incident-reports-create") ? (
            <Button
              icon
              onClick={(e) => this.redirectToAddPage("new")}
              className="orange"
            >
              <Icon name="plus" /> Add incident report
            </Button>
          ) : (
            ""
          )}
        </TableHeaderCell>
      </TableRow>
    );
  }
}

export default IncidentReportList;
