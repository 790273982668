import React from "react";
import { Button, Card, Grid, Header, Icon, Modal } from "semantic-ui-react";
import "./../../assets/css/pages/admin/resource-index.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Request from "../../Request";
import Title from "components/Title";
import { Link } from "react-router-dom";
import moment from "moment";
import Pagination from "components/Pagination";
import Filters from "./Partials/Filters";
import { useRolePermissions } from "../../hooks/useRolePermissions";

const { isPermitted } = useRolePermissions();

class RetailScoreSheetIndex extends React.Component {
  state = {
    customSize: window.innerWidth <= 1150,
    retailScores: [],
    renderRetailScores: false,
    modalOpen: false,
    removeId: null,
    currentPage: 1,
    totalPages: 0,
    pagesToShow: 3,
    filters: {
      date_from: null,
      date_to: null,
      limit: 10,
      offset: 0,
      store_id: null,
      search: "",
    },
  };

  constructor() {
    super();
    this.request = new Request();
  }

  componentDidMount() {
    this.getRetailScores();

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ customSize: window.innerWidth <= 1150 });
  }

  goToPage = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, async () => {
      await this.adjustOffset();
      this.getRetailScores();
    });
  };

  goToFirstPage = () => {
    this.setState({ currentPage: 1 }, async () => {
      await this.adjustOffset();
      this.getRetailScores();
    });
  };

  goToLastPage = () => {
    this.setState({ currentPage: this.state.totalPages }, async () => {
      await this.adjustOffset();
      this.getRetailScores();
    });
  };

  goToPreviousPage = () => {
    this.setState(
      (prevState) => ({
        currentPage: Math.max(1, prevState.currentPage - 1),
      }),
      async () => {
        await this.adjustOffset();
        this.getRetailScores();
      }
    );
  };

  goToNextPage = () => {
    this.setState(
      (prevState) => ({
        currentPage: Math.min(prevState.totalPages, prevState.currentPage + 1),
      }),
      async () => {
        await this.adjustOffset();
        this.getRetailScores();
      }
    );
  };

  handleSearchChange = (event) => {
    this.setState({
      filters: { ...this.state.filters, search: event.target.value },
      currentPage: 1,
    });
  };

  handleStoreChange = (selectedOption) => {
    this.setState({
      filters: { ...this.state.filters, store_id: selectedOption.value },
      currentPage: 1,
    });
  };

  handleDatesChange = (startDate, endDate) => {
    if (startDate && endDate) {
      this.setState({
        filters: {
          ...this.state.filters,
          date_from: startDate.format("YYYY-MM-DD"),
          date_to: endDate.format("YYYY-MM-DD"),
        },
      });
    }
  };

  handleFilterResults = async () => {
    await this.adjustOffset();
    this.getRetailScores();
  };

  handleRemoveFilters = async () => {
    let filters = {
      date_from: null,
      date_to: null,
      limit: 10,
      offset: 0,
      store_id: null,
      search: null,
    };
    this.setState({ filters }, async () => {
      await this.adjustOffset();
      this.getRetailScores();
    });
  };

  adjustOffset = async () => {
    this.setState({
      filters: {
        ...this.state.filters,
        offset: (this.state.currentPage - 1) * this.state.filters.limit,
      },
    });
  };

  getRetailScores = () => {
    new Promise((resolve, reject) => {
      let path = `/retail-scores`;
      let queryString = this.buildFilterQueryString();
      path += `?${queryString}`;
      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let retailScoreResponse = JSON.parse(response);
        if (
          retailScoreResponse &&
          retailScoreResponse.success === true &&
          retailScoreResponse.data
        ) {
          let retailScores = this.transformRetailScores(
            retailScoreResponse.data
          );
          let totalPages = Math.ceil(
            retailScoreResponse.total_records / this.state.filters.limit
          );
          this.setState({ retailScores, totalPages }, () => {
            this.setState({ renderRetailScores: true });
          });
        } else {
          this.setState({ retailScores: [] }, () => {
            this.setState({ renderRetailScores: true });
          });
        }
      })
      .catch((error) => {
        this.setState({ retailScores: [] }, () => {
          this.setState({ renderRetailScores: true });
        });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get retail scores", "Error");
        }
      });
  };

  buildFilterQueryString = () => {
    let obj = this.state.filters;

    return Object.keys(obj)
      .filter((key) => obj[key] !== null && obj[key] !== undefined)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      )
      .join("&");
  };

  transformRetailScores = (retailScores) => {
    return retailScores.map(
      ({
        id,
        score_date,
        user,
        manager,
        store,
        total_score,
        evaluated_score,
      }) => {
        return {
          id,
          score_date: moment(score_date).format("MMMM DD, YYYY"),
          user_name: `${user?.firstName} ${user?.lastName}`,
          manager_name: `${manager?.firstName} ${manager?.lastName}`,
          store_name: store?.title,
          total_score,
          evaluated_score,
        };
      }
    );
  };

  handleCloseModal = () => {
    this.setState({
      removeId: null,
      modalOpen: false,
    });
  };

  deleteRetailScore = (id) => {
    new Promise((resolve, reject) => {
      let path = `/retail-scores/${id}`;
      this.request.delete(path, resolve, reject);
    })
      .then((response) => {
        NotificationManager.success(
          "Successfully deleted retail score",
          "Success"
        );
        this.getRetailScores();
        this.handleCloseModal();
      })
      .catch((error) => {
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not delete retail score", "Error");
        }
      });
  };

  removeRetailScoreConfirmation = (id) => {
    this.setState({ removeId: id, modalOpen: true });
  };

  getConfirmationModal = () => {
    let removeId = this.state.removeId;

    if (removeId || removeId === 0) {
      return (
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleCloseModal}
          size="small"
        >
          <Header content="Delete Retail Score" />
          <Modal.Content>
            <h3>Are you sure you want to delete this Retail Score?</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={this.handleCloseModal} inverted>
              No
            </Button>
            <Button
              color="green"
              onClick={() => this.deleteRetailScore(removeId)}
              inverted
            >
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return "";
  };

  getOneCardDescription = (retailScore, index) => {
    return (
      <Grid className="resource-index-card-grid">
        <Grid.Row className="resource-index-card-grid-row">
          <Grid.Column width={16}>
            <b>Submission ID: </b>
            {retailScore.id || ""}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="resource-index-card-grid-row">
          <Grid.Column width={16}>
            <b>Date Submitted: </b>
            {retailScore.score_date || ""}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="resource-index-card-grid-row">
          <Grid.Column width={16}>
            <b>Store Name: </b>
            {retailScore.store_name || ""}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="resource-index-card-grid-row">
          <Grid.Column width={16}>
            <b>Total Score: </b>
            {retailScore.total_score || 0}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="resource-index-card-grid-row">
          <Grid.Column width={16}>
            <b>Evaluated Score: </b>
            {retailScore.evaluated_score || 0}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="resource-index-card-grid-row">
          <Grid.Column width={16}>
            <div className="resource-index-card-grid-row-buttons-div">
              {isPermitted("retail-score-sheet-edit") && (
                <Link to={`/retail-score-sheets/${retailScore.id}/edit`}>
                  <Button icon labelPosition="left">
                    <Icon name="edit" />
                    Edit Retail Score
                  </Button>
                </Link>
              )}
              {isPermitted("retail-score-sheet-view") && (
                <Link to={`/retail-score-sheets/${retailScore.id}/`}>
                  <Button icon labelPosition="left">
                    <Icon name="eye" />
                    View Retail Score
                  </Button>
                </Link>
              )}
              {isPermitted("retail-score-sheet-delete") && (
                <Button
                  icon
                  labelPosition="left"
                  onClick={() =>
                    this.removeRetailScoreConfirmation(retailScore.id)
                  }
                >
                  <Icon name="trash" />
                  Delete Retail Score
                </Button>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  getRetailScoreCard = () => {
    let cards = [];
    let retailScores = this.state.retailScores;
    retailScores.forEach((retailScore, index) => {
      cards.push(
        <Card className="resource-index-card" fluid key={index}>
          <Card.Content className="resource-index-card-header" />
          <Card.Content
            description={this.getOneCardDescription(retailScore, index)}
          />
        </Card>
      );
    });
    cards.push(
      <div key="retail-score-admin-plus-button">
        {isPermitted("retail-score-sheet-create") && (
          <Link to="/retail-score-sheets/create">
            <Button icon labelPosition="left">
              <Icon name="plus" />
              Retail Score
            </Button>
          </Link>
        )}
      </div>
    );
    return cards;
  };

  getRetailScoreContent = () => {
    return (
      <Grid className="resource-index-grid">
        <Grid.Row className="resource-index-grid-header-row">
          <Grid.Column width={2}>Submission ID</Grid.Column>
          <Grid.Column width={2}>Submitted By</Grid.Column>
          <Grid.Column width={2}>Store Name</Grid.Column>
          <Grid.Column width={2}>Date Submitted</Grid.Column>
          <Grid.Column width={2}>Total Score</Grid.Column>
          <Grid.Column width={2}>Evaluated Score</Grid.Column>
          <Grid.Column width={4} textAlign="right"></Grid.Column>
        </Grid.Row>
        {this.state.retailScores.length > 0 ? (
          this.state.retailScores.map((retailScore, index) => {
            return (
              <Grid.Row className="resource-index-grid-row" key={index}>
                <Grid.Column width={2} className="resource-index-grid-column">
                  {retailScore.id || ""}
                </Grid.Column>
                <Grid.Column width={2} className="resource-index-grid-column">
                  {retailScore.manager_name || ""}
                </Grid.Column>
                <Grid.Column width={2} className="resource-index-grid-column">
                  {retailScore.store_name || ""}
                </Grid.Column>
                <Grid.Column width={2} className="resource-index-grid-column">
                  {retailScore.score_date || ""}
                </Grid.Column>

                <Grid.Column width={2} className="resource-index-grid-column">
                  {retailScore.total_score || 0}
                </Grid.Column>
                <Grid.Column width={2} className="resource-index-grid-column">
                  {retailScore.evaluated_score || 0}
                </Grid.Column>
                <Grid.Column
                  width={4}
                  className="resource-index-grid-actions-column"
                >
                  <Button.Group icon>
                    <div>
                      {isPermitted("retail-score-sheet-edit") && (
                        <Button size="tiny" title="Edit Retail Score">
                          <Link
                            to={`/retail-score-sheets/${retailScore.id}/edit`}
                          >
                            <Icon name="edit" />
                          </Link>
                        </Button>
                      )}
                      {isPermitted("retail-score-sheet-view") && (
                        <Button size="tiny" title="Edit Retail Score">
                          <Link to={`/retail-score-sheets/${retailScore.id}/`}>
                            <Icon name="eye" />
                          </Link>
                        </Button>
                      )}
                      {isPermitted("retail-score-sheet-delete") && (
                        <Button
                          size="tiny"
                          title="Delete Retail Score"
                          onClick={() =>
                            this.removeRetailScoreConfirmation(retailScore.id)
                          }
                        >
                          <Icon name="trash" />
                        </Button>
                      )}
                    </div>
                  </Button.Group>
                </Grid.Column>
              </Grid.Row>
            );
          })
        ) : (
          <Grid.Row className="resource-index-grid-row">
            <Grid.Column width={10}><strong>No retail scores found</strong></Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  };

  render() {
    return (
      <div className="common-form retail-score-admin-root">
        <Title paramsPageTitle="Evaluation Report" />
        <div>
            {isPermitted("retail-score-sheet-create") && (
              <Link to="/retail-score-sheets/create">
                <Button icon labelPosition="left" className="orange">
                  <Icon name="plus" />
                  Retail Score
                </Button>
              </Link>
            )}
        </div>
        <div className="table-filter">
          <Filters
            filters={this.state.filters}
            onSearchChange={this.handleSearchChange}
            onStoreChange={this.handleStoreChange}
            onDatesChange={this.handleDatesChange}
            onFilterResults={this.handleFilterResults}
            onRemoveFilters={this.handleRemoveFilters}
          />
        </div>
        {this.state.renderRetailScores
          ? this.state.customSize
            ? this.getRetailScoreCard()
            : this.getRetailScoreContent()
          : ""}
           <div  className="pagination">
        <Pagination
          currentPage={this.state.currentPage}
          totalPages={this.state.totalPages}
          pagesToShow={this.state.pagesToShow}
          goToPage={(page) => this.goToPage(page)}
          goToFirstPage={this.goToFirstPage}
          goToLastPage={this.goToLastPage}
          goToPreviousPage={(page) => this.goToPreviousPage(page)}
          goToNextPage={(page) => this.goToNextPage(page)}
        />
        </div>
        {this.getConfirmationModal()}
        <NotificationContainer />
      </div>
    );
  }
}

export default RetailScoreSheetIndex;
