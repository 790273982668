import React from "react";
import { Segment } from "semantic-ui-react";

class Forbidden extends React.Component {
  render() {
    return (
      <div className="grid-wrapper center-items">
        {this.props.target && this.props.target === "store" ? (
          <h3>
            You do not have permission to view this page for the selected store.
          </h3>
        ) : (
          <h3>You dont have access to view this page</h3>
        )}
      </div>
    );
  }
}

export default Forbidden;
