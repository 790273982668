import React from 'react';
import './../../assets/css/pages/admin/recipes-admin-edit.css';
import {NotificationContainer, NotificationManager} from "react-notifications";
import {Grid, Button, Card, Form, Label, Select} from "semantic-ui-react";
import ModalImage from "react-modal-image";
import PropTypes from "prop-types";
import RichTextEditor from "react-rte";
import Request from "../../Request";
import { useRolePermissions } from './../../hooks/useRolePermissions';

const { redirectToForbiddenIfUnauthorized } = useRolePermissions();


class RecipesEdit extends React.Component
{
    state = {
        mobileSize: window.innerWidth <= 768,
        tabletSize: window.innerWidth <= 1024,
        editId: null,
        recipe: null,
        file: null,
        filename: null,
        defaultRecipe: {
            schematic: {
                content: '',
                name: ''
            },
            files: []
        },
        renderRecipe: false,
        loading: false,
        recipeTypes: [
            {
                id: 0,
                key: 'espresso-bar',
                value: 'espresso-bar',
                text: 'Espresso Bar'
            },
            {
                id: 1,
                key: 'coffee-bar',
                value: 'coffee-bar',
                text: 'Coffee Bar'
            },
            {
                id: 2,
                key: 'tea-bar',
                value: 'tea-bar',
                text: 'Tea Bar'
            },
            {
                id: 3,
                key: 'cooling-aids',
                value: 'cooling-aids',
                text: 'Cooling Aids'
            },
            {
                id: 4,
                key: 'cofftails-dreams',
                value: 'cofftails-dreams',
                text: 'Cofftails & Dreams'
            },
            {
                id: 5,
                key: 'batch-recipes',
                value: 'batch-recipes',
                text: 'Batch Recipes'
            },
            {
                id: 6,
                key: 'hot-food-prep',
                value: 'hot-food-prep',
                text: 'Hot Food Prep'
            },
        ],
        selectedRecipeTypeName: ''
    };

    toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
            {label: 'Italic', style: 'ITALIC'},
            {label: 'Underline', style: 'UNDERLINE'},
            {label: 'Strikethrough', style: 'STRIKETHROUGH'}
        ],
        BLOCK_TYPE_BUTTONS: [
            {label: 'UL', style: 'unordered-list-item'},
            {label: 'OL', style: 'ordered-list-item'}
        ]
    };

    static propTypes = {
        onChange: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.request = new Request();
    }

    componentDidMount() {
        redirectToForbiddenIfUnauthorized('recipes-edit');
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        if(this.props.match.params.id) {
            this.setState({editId: this.props.match.params.id}, () => {
                this.getRecipe();
            })
        }
    }

    resize() {
        this.setState({tabletSize: window.innerWidth <= 1024, mobileSize: window.innerWidth <= 768});
    }

    getRecipe = () => {
        if(this.state.editId) {
            new Promise((resolve, reject) => {
                let path = '/recipes/' + this.state.editId;
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let recipe = JSON.parse(response);
                    if(recipe && recipe.success === true && recipe.data) {
                        recipe.data.recipe.content = this.getRichTextValueParsed(recipe.data.recipe.content);
                        this.setState({recipe: recipe.data}, ()=>{
                            this.setState({renderRecipe:true, loading: false});
                        })

                        try {
                            const recipeTypeName = this.state.recipeTypes.find(recipeType => recipeType.value == this.state.recipe.recipe.type).text;
                            this.setState({selectedRecipeTypeName: recipeTypeName});
                        }
                         catch (error) {
                            
                        }
                    }
                    else {
                        this.setState({recipe: this.state.defaultRecipe}, ()=>{
                            this.setState({renderRecipe:true, loading: false});
                        });
                    }
                })
                .catch(error => {
                    this.setState({recipe: this.state.defaultRecipe}, ()=>{
                        this.setState({renderRecipe:true, loading: false});
                    });
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Recipe', 'Error');
                    }
                });
        }
    };

    saveRecipe = () => {
        this.setState({loading: true});
        new Promise((resolve, reject) => {
            let path = '/recipes/' + this.state.editId;
            let params = {
                content: this.state.recipe.recipe.content.toString('html'),
                name: this.state.recipe.recipe.name,
                type: this.state.recipe.recipe.type
            };
            if(this.state.filename && this.state.file) {
                params.file = this.state.file;
                params.filename = this.state.filename;
            }
            this.request.put(path, params, resolve, reject);
        })
            .then(response => {
                NotificationManager.success('Successfully saved Recipe', 'Success');
                this.resetFile();
                this.getRecipe();
            })
            .catch(error => {
                this.setState({loading: false});
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not edit Recipe', 'Error');
                }
            });
    };

    handleRemoveImage = (index) => {
        let recipe = this.state.recipe;
        let media = recipe.files.splice(index, 1);

        if(Array.isArray(media) && media.length ===1 && media[0].url) {
            new Promise((resolve, reject) => {
                let apiUrl = media[0].url;
                this.request.delete('', resolve, reject, apiUrl);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if(response && response.success === true) {
                        NotificationManager.success('Successfully removed media content', 'Success');
                        this.getRecipe();
                    }
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not remove media content', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not remove media content', 'Error');
        }
    };

    getRichTextValueParsed = (value) => {
        return value ? RichTextEditor.createValueFromString(value, 'html') : RichTextEditor.createEmptyValue()
    };

    handleChangeName = (event) => {
        let recipe = this.state.recipe;
        if(event.target.value.trim() !== '') {
            recipe.recipe.name = event.target.value;
            this.setState({recipe: recipe}, ()=>{
                this.setState({renderRecipe: true})
            })
        }
    };

    handleChangeRecipeType = (event, data) => {
        let recipe = this.state.recipe;
        recipe.recipe.type = data.value;
        this.setState({recipe: recipe}, ()=>{
            const recipeTypeName = this.state.recipeTypes.find(recipeType => recipeType.value == data.value).text;
            this.setState({renderRecipe: true, selectedRecipeTypeName: recipeTypeName})
        })
    }
    
    redirectBack = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/recipes/';
    };

    handleFileChanged = (event) => {
        event.preventDefault();
        let file = event.target.files[0];
        this.setState({filename: event.target.files[0].name});
        if(file){
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: reader.result
                });
            };
        }
    };

    resetFile = () => {
        this.setState({file: null, filename: null});
    };

    sortMedia = (media) => {
        return media.sort((file1, file2)=>{
            return (file1.is_video === file2.is_video)? 0 : file2.is_video? -1 : 1;
        })
    };

    handleRecipeContentChange = (value) => {
        let recipe = this.state.recipe;

        recipe.recipe.content = value;
        this.setState({
            recipe: recipe
        });
    };

    getRecipeEditContent = () => {
        let recipe = this.state.recipe;
        let media = this.sortMedia(recipe.files);
        if(this.state.renderRecipe) {
            return (
                <div>
                    <Grid className="recipes-edit-grid">
                        <Grid.Row columns={2} className="recipes-edit-rows">
                            <Grid.Column width={this.state.mobileSize ? 12 : (this.state.tabletSize ? 8 : 5)}>
                                <Form>
                                    <Form.Field>
                                        <Label className="recipes-edit-name-label" pointing='below'>Name</Label>
                                        <input
                                            className="recipes-edit-name-input"
                                            type='text'
                                            value={recipe.recipe.name || ''}
                                            placeholder="Enter name"
                                            onChange={this.handleChangeName}
                                        />
                                        <br/>
                                        <br/>
                                        <Label className="recipes-edit-category-label" pointing='below'>Recipe category</Label>
                                        <Select
                                            className="recipes-edit-category-select"
                                            text={this.state.selectedRecipeTypeName}
                                            value={this.state.recipe.type}
                                            options={this.state.recipeTypes}
                                            onChange={this.handleChangeRecipeType}/>
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2} className="recipes-edit-text-rows">
                            <Grid.Column width={12}>
                                <div >
                                    <h5>Recipe:</h5>
                                    <RichTextEditor
                                        editorClassName="textEditor"
                                        value={this.state.recipe.recipe.content}
                                        onChange={this.handleRecipeContentChange}
                                        toolbarConfig={this.toolbarConfig}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1} className="recipes-edit-rows">
                            <Grid.Column>
                                { this.state.loading ?
                                    <Button loading color="orange">
                                        Loading
                                    </Button>
                                    :
                                    <Button color="orange" onClick={()=>this.saveRecipe()}>Save</Button>
                                }

                                <Button color="orange" onClick={()=>this.redirectBack()}>Cancel</Button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={1} className="recipes-edit-rows">
                            <Grid.Column width={4}>
                                <div className="fileInput">
                                    <input ref={this.fileInput} type="file" accept=".jpeg, .jpg, .png, .svg, .mp4, .mov, .gif" onChange={this.handleFileChanged}/>
                                </div>
                                <div className="fileInputTypes">(jpg, jpeg, png, gif, svg, mp4, mov)</div>
                            </Grid.Column>
                        </Grid.Row>
                        {
                            Array.isArray(recipe.files) && recipe.files.length > 0 ?
                                <hr/>
                                : ''
                        }
                    </Grid>
                    <div>
                        <div className="recipes-edit-media">
                            {
                                media.map((file, index) => {
                                    return (

                                        <div key={index}>
                                            { file.is_video ?
                                                <div className="recipes-edit-video-media-div" key={index}>
                                                    <video className="recipes-edit-videos" controls key={index + 'recipes-edit-video'}>
                                                        <source src={file.url} type="video/mp4"/>
                                                    </video>
                                                    <div className="recipes-edit-remove-image-link">
                                                        <a href={'#delete'} onClick={() => this.handleRemoveImage(index)}>Delete</a>
                                                    </div>
                                                </div>
                                                :
                                                <div className="recipes-edit-images-media-div" key={index}>
                                                    <ModalImage
                                                        key={index + 'recipes-edit-image'}
                                                        className="recipes-edit-images"
                                                        small={file.url}
                                                        large={file.url}
                                                        alt=""
                                                    />
                                                    <div className="recipes-edit-remove-image-link">
                                                        <a href={'#delete'} onClick={() => this.handleRemoveImage(index)}>Delete</a>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <NotificationContainer/>
                </div>
            );
        }
        return '';
    };

    render() {
        return(
            <Card className="recipes-edit-card" fluid>
                <Card.Content className="recipes-edit-card-header" header="Recipes edit" />
                <Card.Content description={this.state.renderRecipe ? this.getRecipeEditContent() : ''} />
            </Card>
        )

    }
}

export default RecipesEdit;