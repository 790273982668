import React from 'react';
import { Menu, Grid } from "semantic-ui-react";
import { Link } from 'react-router-dom';
import { useRolePermissions } from './../hooks/useRolePermissions';
import './../assets/css/components/dashboard-menu.css';

const { isPermitted } = useRolePermissions();

class LandingPageMenu extends React.Component {
    render() {
        const menu = Object.values(this.props.menuItems);

        return (
            <div className="home-menu-wrap">
                <div className="grid-wrapper text-left landing-page">
                    <Grid columns={2} divided>
                        <Grid.Row>
                            {menu
                                .filter((item) => isPermitted(item.permission))
                                .map((item, key) => {
                                    return (
                                        <Grid.Column key={'column' + key}>
                                            <Menu.Item
                                                as={Link}
                                                to={item.path}
                                                name={item.name}
                                                key={'item' + key}
                                                className="menu-item text-left"
                                            >
                                                <span className="menu-label">{item.content}</span>
                                            </Menu.Item>
                                        </Grid.Column>
                                    )
                                })
                            }
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default LandingPageMenu;
