import React from 'react';
import { Grid, Button, Card, TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table, Modal, Header, Icon, Form, Label, Select } from "semantic-ui-react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import Request from "../../../Request";
import './../../../assets/css/pages/admin/roles-admin.css';
import { useRolePermissions } from './../../../hooks/useRolePermissions';

const { redirectToForbiddenIfUnauthorized, isPermitted } = useRolePermissions();

class RolesList extends React.Component {
    state = {
        roles: [],
        newRole: false,
        removeId: null,
        modalOpen: false,
        newRoleObject: {
            name: '',
            requiresStore: '0'
        }
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        redirectToForbiddenIfUnauthorized('roles-view');
        this.getRoles();
    }

    getRoles = () => {
        new Promise((resolve, reject) => {
            let path = '/roles';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let roles = JSON.parse(response);
                if (roles && roles.success === true && roles.data) {
                    this.setState({ roles: roles.data });
                }
            })
            .catch(error => {
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Unable to get roles data', 'Error');
                }
            });
    };

    deleteRoleConfirmation = (id) => {
        this.setState({ removeId: id, modalOpen: true });
    };

    deleteConfirmationModal = () => {
        let removeId = this.state.removeId;

        if (removeId || removeId === 0) {
            return (
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleCloseModal}
                    size='small'
                >
                    <Header content='Delete Role' />
                    <Modal.Content>
                        <h3>Are you sure you want to delete this Role?</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleCloseModal} inverted>
                            No
                        </Button>
                        <Button color='green' onClick={() => this.deleteRole(removeId)} inverted>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            )
        }
        return '';
    };

    handleCloseModal = () => {
        this.setState({ modalOpen: false });
        this.getRoles();
    }

    deleteRole = (id) => {
        this.setState({ modalOpen: false });
        if (id) {
            new Promise((resolve, reject) => {
                let path = '/roles/' + id;
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully deleted Roles', 'Success');
                    this.getRoles();
                })
                .catch(error => {
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not delete Roles', 'Error');
                    }
                });
        }
    };

    editRole = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/roles/' + id;
    };

    handleChangeNewName = (event) => {
        if (event.target.value.trim() !== '') {
            let role = this.state.newRoleObject;
            role.name = event.target.value;
            this.setState({ newRoleObject: role });
        }
    };

    handleChangeRequiresStore = (event, data) => {
        let role = this.state.newRoleObject;
        role.requiresStore = data.value
        this.setState({ newRoleObject: role });
    }

    getRolesContent = () => {
        return (<div>
            {this.state.roles && Array.isArray(this.state.roles) ?
                this.state.roles.length > 0 ?
                    <Table celled unstackable>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>
                                    Role
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    Level
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    Actions
                                </TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {this.state.roles.map((role, index) => (
                                <TableRow>
                                    <TableCell>
                                        {role.name}
                                    </TableCell>
                                    <TableCell>
                                        {role.requiresStore ? 'Store level' : 'Corporate'}
                                    </TableCell>
                                    <TableCell>
                                        {!role.isStandardRole ? (
                                            <div>
                                                {isPermitted('roles-edit') &&
                                                    <Button color="orange" onClick={() => this.editRole(role.id)}>Edit</Button>
                                                }
                                                {isPermitted('roles-delete') &&
                                                    <Button color="orange" onClick={() => this.deleteRoleConfirmation(role.id)}>Remove</Button>
                                                }
                                            </div>
                                        ) : (
                                            <div>
                                                <Button color="orange" disabled>Edit</Button>
                                                <Button color="orange" disabled>Remove</Button>
                                            </div>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    : <p>No roles found</p>
                : ''}
            <div>
                {this.state.newRole ?
                    <Grid className="roles-admin-grid" stackable>
                        <Grid.Row className="roles-admin-grid-rows" columns={3}>
                            <Grid.Column className="roles-admin-grid-columns">
                                <Form>
                                    <Form.Field>
                                        <Label className="schematics-edit-name-label" pointing='below'>Name</Label>
                                        <input
                                            className="roles-edit-name-input"
                                            type='text'
                                            value={this.state.newRoleObject.name}
                                            placeholder="Enter name"
                                            onChange={this.handleChangeNewName}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Label className="schematics-edit-name-label" pointing='below'>Role level</Label>
                                        <Select
                                            placeholder='Select role level'
                                            options={
                                                [
                                                    { key: 'corporate', text: 'Corporate', value: '0' },
                                                    { key: 'store', text: 'Store level', value: '1' }
                                                ]
                                            }
                                            defaultValue='0'
                                            onChange={this.handleChangeRequiresStore}
                                        >
                                        </Select>
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="roles-admin-grid-action-save-new-rows">
                            <Grid.Column className="recipes-admin-grid-action-save-new-columns" width={8}>
                                <Button onClick={this.saveNewRole} color="orange">Save</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    : ''
                }
                <div className="add-new-role">
                    <Button icon labelPosition='right' onClick={this.addNewRole} color="orange">
                        Add Role
                        <Icon name='plus' />
                    </Button>
                </div>
            </div>
        </div>
        );
    };

    addNewRole = () => {
        this.setState({ newRole: true })
    };

    saveNewRole = () => {
        if (this.state.newRoleObject.name) {
            new Promise((resolve, reject) => {
                let path = '/roles';
                let params = {
                    name: this.state.newRoleObject.name.trim(),
                    requiresStore: this.state.newRoleObject.requiresStore
                };
                this.request.post(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully saved new Role', 'Success');

                    this.setState({ renderRecipes: false, newRecepie: false }, () => {
                        this.getRoles();
                        this.resetFields();
                    });
                })
                .catch(error => {
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not create new Role', 'Error');
                    }
                });
        }
    };

    resetFields = () => {
        this.setState({
            newRoleObject: {
                name: '',
                requiresStore: false
            }
        });
    };

    render() {
        let roles = this.state.roles;
        return (
            <div className="promotion-page">
                <Card className="recipes-admin-card" fluid>
                    <Card.Content className="recipes-admin-card-header" header="Roles" />
                    <Card.Content description={this.getRolesContent()} />
                </Card>
                {this.deleteConfirmationModal()}
                <NotificationContainer />
            </div>
        )
    }
}

export default RolesList;