import React from "react";
import { Grid, Header, Segment, Button, Form } from "semantic-ui-react";
import "assets/css/pages/login.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Request from "../../Request";
import Cookies from "universal-cookie";

const jwt_decode = require("jwt-decode");

class ManagementLogin extends React.Component {
  state = {
    pin: "",
    cookies: null,
  };

  constructor(props) {
    super(props);
    this.request = new Request();
  }

  handleLogin = () => {
    let pin = this.state.pin;
    if (pin && pin.trim()) {
      new Promise((resolve, reject) => {
        let path = "/auth/manager";
        let params = {
          pin: pin,
        };
        this.request.post(path, params, resolve, reject);
      })
        .then((response) => {
          response = JSON.parse(response);
          if (response && response.token) {
            let decoded = jwt_decode(response.token);
            let date = new Date(decoded.exp * 1000);
            let cookies = new Cookies();
            cookies.set("management-token", response.token, {
              expires: date,
              secure: true,
            });
            cookies.set(
              "management-permissions",
              response.data.userRolePermissionData,
              { expires: date, secure: true }
            );
            this.setState({ cookies: cookies });
            this.redirectToDashboard();
          }
        })
        .catch((error) => {
          if (error) {
            NotificationManager.error(error, "Error");
          } else {
            NotificationManager.error("Manager login failed", "Error");
          }
        });
    }
  };

  redirectToDashboard = () => {
    let url = process.env.REACT_APP_UI_URL;
    // window.location.href = url + "/";
    window.location.href = url + "/management/new-orders";
  };

  handleInputChanged = (event) => {
    this.setState({ pin: event.target.value });
  };

  render() {
    return (
      <div className="login-content">
        <Grid columns={2} centered stackable verticalAlign="top">
          <Grid.Row>
            <Grid.Column>
              <Header as="h1" className="logo" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form size="large">
                <Segment>
                  <h1 className="login-h1">Manager Login</h1>
                  <Form.Input
                    type="password"
                    className="loginInput"
                    size="large"
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="Pin"
                    value={this.state.pin}
                    onChange={this.handleInputChanged}
                  />
                  <Button
                    color="light-grey"
                    fluid
                    size="large"
                    onClick={this.handleLogin}
                  >
                    Login
                  </Button>
                  <br />
                </Segment>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <NotificationContainer />
      </div>
    );
  }
}

export default ManagementLogin;
