import React, { Component } from 'react';
import {
    FormGroup,
    FormInput,
    Button,
    Icon,
    FormField,
    Form
} from "semantic-ui-react";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import StoreSelection from 'components/StoreSelection';

class RetailScoreFilter extends Component {
    state = {
        selectionRange: {
            startDate: null,
            startDateId: "startDateId",
            endDate: null,
            endDateId: "endDateId",
            focusedInput: null,
        },
    };

    handleChangeDateInput = (startDate, endDate) => {
        let selection = this.state.selectionRange;
        selection.startDate = startDate;
        selection.endDate = endDate;
        this.props.onDatesChange(startDate, endDate);
    };

    changedDatePickerFocus = (focusedInput) => {
        let selection = this.state.selectionRange;
        selection.focusedInput = focusedInput;
        this.setState({ selectionRange: selection });
    };

    render() {
        return (
            <Form>
                <FormGroup widths="equal">

                    <FormField>
                        <label>Search</label>
                        <FormInput
                            type="text"
                            name="incident"
                            value={this.props.filters.search}
                            onChange={(event) => this.props.onSearchChange(event)} />
                    </FormField>

                    <FormField>
                        <StoreSelection
                            storeId={this.props.filters.store_id}
                            permission="retail-score-sheet-list"
                            onStoreChange={this.props.onStoreChange} />
                    </FormField>
                    <FormField>
                        <label>Date Submitted</label>
                        <div className="date-range-filter-div">
                            <DateRangePicker
                                startDate={this.state.selectionRange.startDate}
                                startDateId={this.state.selectionRange.startDateId}
                                endDate={this.state.selectionRange.endDate}
                                endDateId={this.state.selectionRange.endDateId}
                                onDatesChange={({ startDate, endDate }) =>
                                    this.handleChangeDateInput(startDate, endDate)
                                }
                                focusedInput={this.state.selectionRange.focusedInput}
                                onFocusChange={(focusedInput) =>
                                    this.changedDatePickerFocus(focusedInput)
                                }
                                minDate={moment(moment().subtract(100, "years"))}
                                isOutsideRange={(day) =>
                                    moment() < moment(moment().subtract(100, "years"))
                                }
                                numberOfMonths={this.state.tabletSize ? 1 : 2}
                                displayFormat="YYYY-MM-DD"
                            />
                        </div>
                    </FormField>
                    <FormField>
                        <label className="visibility-hidden">filter</label>
                        <Button color="orange" onClick={() => this.props.onFilterResults()}>
                            Filter <Icon name="filter" />
                        </Button>
                    </FormField>
                    <FormField>
                        <label className="visibility-hidden">Reset</label>
                        <Button color="orange" onClick={() => this.props.onRemoveFilters()}>
                            Reset
                        </Button>
                    </FormField>
                </FormGroup>
            </Form>
        );
    }
}

export default RetailScoreFilter;
