import React from 'react';
import {NotificationContainer, NotificationManager} from "react-notifications";
import './../../assets/css/pages/admin/stores-admin-edit.css';
import {Input, Button} from "semantic-ui-react";
import Request from "../../Request";
import Title from 'components/Title';
import { useRolePermissions } from './../../hooks/useRolePermissions';

const { redirectToForbiddenIfUnauthorized } = useRolePermissions();

class StoresAdminEdit extends React.Component {
    state = {
        editId: null,
        store: {}
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        redirectToForbiddenIfUnauthorized('stores-edit');
        if(this.props.match.params.id) {
            this.setState({editId: this.props.match.params.id}, () => {
                this.getStore();
            })
        }
    }

    getStore = () => {
        if(this.state.editId && this.state.editId !== 'new') {
            new Promise((resolve, reject) => {
                let path = '/stores/' + this.state.editId + '/edit';
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let store = JSON.parse(response);
                    if(store && store.success === true && store.data) {
                        this.setState({store: store.data});
                    }
                    else {
                        this.setState({store: {}});
                    }
                })
                .catch(error => {
                    this.setState({store: {}});
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Store', 'Error');
                    }
                });
        }
    };

    updateStore = () => {
        let store = this.state.store;
        let editId = this.state.editId;
        if(editId !== 'new') {
            new Promise((resolve, reject) => {
                let path = '/stores/' + editId;
                let params = {
                    title: store.title,
                    number: store.number,
                    email: store.email,
                    email2: store.email2,
                    street1: store.street1,
                    street2: store.street2,
                    city: store.city,
                    state: store.state,
                    zip: store.zip,
                    phone: store.phone,
                    fax: store.fax,
                };
                this.request.put(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated Store', 'Success');
                    this.redirectBackToView();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update Store', 'Error');
                    }
                });
        }
    };

    submitNewStore = () => {
        let store = this.state.store;
        let editId = this.state.editId;
        if(editId === 'new') {
            new Promise((resolve, reject) => {
                let path = '/stores';
                let params = {
                    title: store.title,
                    number: store.number,
                    email: store.email,
                    email2: store.email2,
                    street1: store.street1,
                    street2: store.street2,
                    city: store.city,
                    state: store.state,
                    zip: store.zip,
                    phone: store.phone,
                    fax: store.fax,
                };
                this.request.post(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully saved Store', 'Success');
                    this.redirectBack();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not save Store', 'Error');
                    }
                });
        }
    };

    handleInputChanged = (event, field) => {
        let store = this.state.store;
        store[field] = event.target.value;
        this.setState({store: store});
    };

    redirectBackToView = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/stores/' + this.state.editId;
    };

    redirectBack = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/stores';
    };

    render() {
        let store = this.state.store;
        return (
            <div className="stores-admin-edit-root">
                <Title
                    paramsPageTitle={this.state.editId === 'new' ? 'Store NEW' : 'Store EDIT'}
                />
                <div className="stores-admin-edit-fields-div">
                    <b>Title*:</b><br/>
                    <Input
                        fluid
                        value={store.title || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'title')}
                    />
                    <br/>
                    <b>Number*:</b><br/>
                    <Input
                        fluid
                        value={store.number || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'number')}
                    />
                    <br/>
                    <b>Email*:</b><br/>
                    <Input
                        fluid
                        value={store.email || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'email')}
                    />
                    <br/>
                    <b>Email 2:</b><br/>
                    <Input
                        fluid
                        value={store.email2 || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'email2')}
                    />
                    <br/>
                    <b>Phone*:</b><br/>
                    <Input
                        fluid
                        value={store.phone || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'phone')}
                    />
                    <br/>
                    <b>Fax*:</b><br/>
                    <Input
                        fluid
                        value={store.fax || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'fax')}
                    />
                    <br/>
                    <b>Street*:</b><br/>
                    <Input
                        fluid
                        value={store.street1 || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'street1')}
                    />
                    <br/>
                    <b>Street 2:</b><br/>
                    <Input
                        fluid
                        value={store.street2 || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'street2')}
                    />
                    <br/>
                    <b>City*:</b><br/>
                    <Input
                        fluid
                        value={store.city || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'city')}
                    />
                    <br/>
                    <b>State*:</b><br/>
                    <Input
                        fluid
                        value={store.state || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'state')}
                    />
                    <br/>
                    <b>Zip*:</b><br/>
                    <Input
                        fluid
                        value={store.zip || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'zip')}
                    />
                    <br/>
                    <div className="stores-admin-dynamic-fields-div">
                        { this.state.editId === 'new' ?
                            <div>
                                <br/>
                                <Button color="orange" onClick={this.submitNewStore}>Submit</Button>
                                <Button color="orange" onClick={this.redirectBack}>Back</Button>
                            </div>
                            :
                            <div>
                                <br/>
                                <Button color="orange" onClick={this.updateStore}>Update</Button>
                                <Button color="orange" onClick={this.redirectBackToView}>Cancel</Button>
                            </div>
                        }
                    </div>
                    <NotificationContainer/>
                </div>
            </div>
        )
    }
}
export default StoresAdminEdit;