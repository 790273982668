import React from "react";
import CheckListFilters from "../../components/CheckListFilters";
import {
  Button,
  Card,
  Form,
  Grid,
  Input,
  Label,
  Placeholder,
  Icon,
} from "semantic-ui-react";
import "./../../assets/css/pages/check-lists/wasteLog.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Request from "../../Request";
import Title from "components/Title";
import Forbidden from "pages/Forbidden";
import { useRolePermissions } from "./../../hooks/useRolePermissions";

const { redirectToForbiddenIfUnauthorized, isPermitted } = useRolePermissions();

class WasteLog extends React.Component {
  state = {
    date: new Date(),
    store: {},
    renderWasteLogs: false,
    wasteLogData: [],
    mobileSize: window.innerWidth <= 767,
    urlParams: {
      store: null,
      date: null,
    },
  };

  constructor() {
    super();
    this.request = new Request();
  }

  componentDidMount() {
    redirectToForbiddenIfUnauthorized("waste-log-view");
    this.setState({ renderWasteLogs: false });

    window.addEventListener("resize", this.resize.bind(this));

    this.resize();
    this.initializeParams();
  }

  initializeParams = () => {
    let stateParams = this.state.urlParams;
    if (this.props.match.params) {
      if (this.props.match.params.store) {
        stateParams.store = this.props.match.params.store;
      }
      if (this.props.match.params.date) {
        let date = new Date(this.props.match.params.date);
        if (this.isValidDate(date)) {
          stateParams.date = date;
        } else {
          stateParams.date = new Date();
        }
      } else {
        stateParams.date = new Date();
      }
      this.setState({ urlParams: stateParams });
    } else {
      this.setState({ urlParams: { store: null, date: new Date() } });
    }
  };

  resize() {
    this.setState({ mobileSize: window.innerWidth <= 767 });
  }

  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  onGetStores = (store) => {
    if (store && store.id) {
      let urlParams = this.state.urlParams;
      if (store.id !== parseInt(urlParams.store)) {
        urlParams.store = store.id;
      }
      this.setState(
        { store: store, urlParams: urlParams, renderWasteLogs: false },
        () => {
          this.getWasteLogs();
        }
      );
    }
  };

  handleChangeDate = (event, date) => {
    let urlParams = this.state.urlParams;
    if (date.value !== urlParams.date) {
      urlParams.date = date.value;
    }
    this.setState(
      { date: date.value, urlParams: urlParams, renderWasteLogs: false },
      () => {
        this.getWasteLogs();
      }
    );
  };

  handleChangeStore = (store) => {
    let urlParams = this.state.urlParams;
    if (store.id !== parseInt(urlParams.store)) {
      urlParams.store = store.id;
    }
    this.setState(
      { store: store, urlParams: urlParams, renderWasteLogs: false },
      () => {
        this.getWasteLogs();
      }
    );
  };

  formatDate = (date) => {
    if (date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    return "";
  };

  getWasteLogs = () => {
    if (this.state.store && this.state.store.id) {
      new Promise((resolve, reject) => {
        let path =
          "/wastelog/" +
          this.state.store.id +
          "/" +
          this.formatDate(this.state.date);
        this.request.get(path, resolve, reject);
      })
        .then((response) => {
          let wasteLogs = JSON.parse(response);
          if (wasteLogs && wasteLogs.success === true && wasteLogs.data) {
            this.setState({ wasteLogData: wasteLogs.data }, () => {
              window.history.replaceState(
                {},
                null,
                "/check-lists/wastelog/" +
                  this.state.urlParams.store +
                  "/" +
                  this.formatDate(this.state.urlParams.date)
              );
              this.setState({ renderWasteLogs: true });
            });
          }
        })
        .catch((error) => {
          this.setState({ renderWasteLogs: true });
          if (error) {
            NotificationManager.error(error, "Error");
          } else {
            NotificationManager.error("Could not get Waste Logs", "Error");
          }
        });
    }
  };

  handleChangeLog = (event, index, field) => {
    let stateLogs = this.state.wasteLogData;
    let stateLog = stateLogs[index];
    stateLog[field] = event.target.value;
    stateLogs[index] = stateLog;
    this.setState({ wasteLogData: stateLogs });
  };

  getWasteLogContent = () => {
    return (
      <Grid className="wastelogs-rows" stackable>
        {this.state.wasteLogData && Array.isArray(this.state.wasteLogData)
          ? this.state.wasteLogData.map((log, index) => {
              return (
                <Grid.Row className="wastelog-row" key={index}>
                  <Grid.Column width={10}>
                    <Input
                      value={log.description || ""}
                      onChange={(e) =>
                        this.handleChangeLog(e, index, "description")
                      }
                      type="text"
                    />
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Input
                      value={log.qty || 0}
                      onChange={(e) => this.handleChangeLog(e, index, "qty")}
                      type="text"
                    />
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Input
                      value={log.value || 0}
                      onChange={(e) => this.handleChangeLog(e, index, "value")}
                      type="text"
                    />
                  </Grid.Column>
                  <Grid.Column width={2}>
                    <Input
                      value={log.loss || 0}
                      onChange={(e) => this.handleChangeLog(e, index, "loss")}
                      type="text"
                    />
                  </Grid.Column>
                </Grid.Row>
              );
            })
          : ""}
      </Grid>
    );
  };

  getWasteLogCardInputs = (log, index) => {
    return (
      <div>
        <Form className="waste-logs-input-forms">
          <Form.Field>
            <Label pointing="below">Description</Label>
            <input
              type="text"
              value={log.description || ""}
              placeholder="Enter description"
              onChange={(e) => this.handleChangeLog(e, index, "description")}
            />
          </Form.Field>
        </Form>
        <Form className="waste-logs-input-forms">
          <Form.Field>
            <Label pointing="below">Quantity</Label>
            <input
              type="text"
              value={log.qty || 0}
              placeholder="Enter quantity"
              onChange={(e) => this.handleChangeLog(e, index, "qty")}
            />
          </Form.Field>
        </Form>
        <Form className="waste-logs-input-forms">
          <Form.Field>
            <Label pointing="below">Value</Label>
            <input
              type="text"
              value={log.value || 0}
              placeholder="Enter value"
              onChange={(e) => this.handleChangeLog(e, index, "value")}
            />
          </Form.Field>
        </Form>
        <Form className="waste-logs-input-forms">
          <Form.Field>
            <Label pointing="below">LOSS $</Label>
            <input
              type="text"
              value={log.loss || 0}
              placeholder="Enter loss"
              onChange={(e) => this.handleChangeLog(e, index, "loss")}
            />
          </Form.Field>
        </Form>
      </div>
    );
  };

  getValidWasteLogsObjects = () => {
    let wasteLogs = this.state.wasteLogData;
    if (Array.isArray(wasteLogs)) {
      let index = wasteLogs.length - 1;
      while (index >= 0) {
        if (!wasteLogs[index].description) {
          wasteLogs.splice(index, 1);
          NotificationManager.warning(
            "Missing description on Waste Log #" + (index + 1),
            "Warning"
          );
        }
        index -= 1;
      }
    } else {
      NotificationManager.error("Could not save Waste Logs", "Error");
      return null;
    }
    return wasteLogs;
  };

  saveWasteLogs = () => {
    if (!isPermitted("waste-log-create")) {
      NotificationManager.error("You are not authorized", "Error");
      return;
    }
    this.setState({ renderWasteLogs: false });
    let wasteLogData = this.getValidWasteLogsObjects();
    if (wasteLogData) {
      new Promise((resolve, reject) => {
        let path =
          "/wastelog/" +
          this.state.store.id +
          "/" +
          this.formatDate(this.state.date);
        let params = {
          wastelog: wasteLogData,
        };
        this.request.post(path, params, resolve, reject);
      })
        .then((response) => {
          NotificationManager.success(
            "Successfully saved Waste Logs",
            "Success"
          );
          this.getWasteLogs();
        })
        .catch((error) => {
          if (error) {
            NotificationManager.error(error, "Error");
          } else {
            NotificationManager.error("Could not save Waste Logs", "Error");
          }
        });
    }
  };

  getMobileWasteLogs = () => {
    return (
      <div>
        {this.state.wasteLogData && Array.isArray(this.state.wasteLogData)
          ? this.state.wasteLogData.map((log, index) => {
              return (
                <Card className="wasteLogCard" fluid key={index}>
                  <Card.Content
                    className="cardHeader"
                    header={"Waste Log #" + (index + 1)}
                  />
                  <Card.Content
                    description={this.getWasteLogCardInputs(log, index)}
                  />
                </Card>
              );
            })
          : ""}
      </div>
    );
  };

  addNewWasteLogEntry = () => {
    let defaultWasteLogEntry = {
      description: "",
      qty: "",
      value: "",
      loss: "",
    };
    let wasteLogs = this.state.wasteLogData;
    wasteLogs.push(defaultWasteLogEntry);

    this.setState({ wasteLogData: wasteLogs });
  };

  printWasteLogs = () => {
    window.print();
    return false;
  };

  render() {
    return (
      <div className="waste-log">
        <Title paramsPageTitle="Waste log" />
        <CheckListFilters
          paramsStoreId={this.state.urlParams.store}
          date={this.state.urlParams.date}
          onGetStores={this.onGetStores}
          onChangeDate={this.handleChangeDate}
          onChangeStore={this.handleChangeStore}
        />
        {isPermitted(
          "service-providers-view",
          this.state.store ? this.state.store.id : null
        ) ? (
          <div>
            <div className="waste-logs-print-button">
              <Button icon floated="right" onClick={this.printWasteLogs}>
                <Icon name="print" />
              </Button>
            </div>
            <hr />
            {this.state.renderWasteLogs ? (
              <div className="waste-log-content">
                {!this.state.mobileSize ? (
                  <div>
                    <div className="logs-grid-content">
                      <Grid className="wastelog-header-row">
                        <Grid.Row>
                          <Grid.Column width={10}>Description</Grid.Column>
                          <Grid.Column width={2}>Quantity</Grid.Column>
                          <Grid.Column width={2}>Value</Grid.Column>
                          <Grid.Column width={2}>LOSS $</Grid.Column>
                        </Grid.Row>
                      </Grid>
                      {this.getWasteLogContent()}
                    </div>
                  </div>
                ) : (
                  <div>{this.getMobileWasteLogs()}</div>
                )}
                {isPermitted("waste-log-create") && (
                  <div className="logs-buttons">
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Button
                            onClick={this.addNewWasteLogEntry}
                            content="Add Waste Log"
                            floated="left"
                            color="orange"
                          />
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Button
                            onClick={this.saveWasteLogs}
                            content="Save"
                            floated="right"
                            color="orange"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <br />
                <Placeholder fluid>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder>
              </div>
            )}
          </div>
        ) : (
          <Forbidden target="store" />
        )}

        <NotificationContainer />
      </div>
    );
  }
}

export default WasteLog;
