import React, { Component } from 'react';
import styles from './../assets/css/components/Pagination.module.css';

class Pagination extends Component {

    renderPageNumbers = () => {
        const { currentPage, totalPages, pagesToShow, goToPage, goToFirstPage, goToLastPage, goToPreviousPage, goToNextPage } = this.props;
        const pageNumbers = [];
        let startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
        let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

        // If there are more pages than the pagesToShow value, display ellipsis for leading pages
        if (totalPages > pagesToShow && startPage > 1) {
            pageNumbers.push(
                <li key="ellipsis-prev">
                    <span>...</span>
                </li>
            );
        }

        // Ensure endPage does not exceed totalPages
        if (endPage - startPage < pagesToShow - 1) {
            startPage = Math.max(1, endPage - pagesToShow + 1);
        }

        // Render page numbers
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i} className={i === currentPage ? styles.active : ''}>
                    <button onClick={() => goToPage(i)}>{i}</button>
                </li>
            );
        }

        // If there are more pages than the pagesToShow value, display ellipsis for trailing pages
        if (totalPages > pagesToShow && endPage < totalPages) {
            pageNumbers.push(
                <li key="ellipsis-next">
                    <span>...</span>
                </li>
            );
        }

        // Add previous page button
        if (currentPage > 1) {
            pageNumbers.unshift(
                <li key="prev">
                    <button onClick={goToPreviousPage}>Previous</button>
                </li>
            );
        }

        // Add next page button
        if (currentPage < totalPages) {
            pageNumbers.push(
                <li key="next">
                    <button onClick={goToNextPage}>Next</button>
                </li>
            );
        }

        // Add first page button
        if (totalPages > 0) {
            pageNumbers.unshift(
                <li key="first">
                    <button onClick={goToFirstPage}>First</button>
                </li>
            );
        }

        // Add last page button
        if (currentPage < totalPages) {
            pageNumbers.push(
                <li key="last">
                    <button onClick={goToLastPage}>Last</button>
                </li>
            );
        }

        return pageNumbers;
    };

    render() {
        return (
            <div>
                <ul className={styles.pagination}>{this.renderPageNumbers()}</ul>
            </div>
        );
    }
}

export default Pagination;
