import React from 'react';
import { Button, Icon, Card, Table, TableCell, TableBody, TableRow } from "semantic-ui-react";
import Title from 'components/Title';
import './../../assets/css/pages/managment/retail-score-sheet.css';
import { NotificationContainer, NotificationManager } from "react-notifications";
import Request from "../../Request";
import moment from 'moment';
import NotFound from 'pages/NotFound';
import { useRolePermissions } from "../../hooks/useRolePermissions";

const { redirectToForbiddenIfUnauthorized, isPermitted, getPermissions } = useRolePermissions();

class RetailScoreSheetView extends React.Component {
  constructor() {
    super();
    this.request = new Request();
    this.state = {
      evaluatedScore: 0,
      totalScore: 0,
      highestScore: 0,
      totalPercentage: 0,
      itemScores: [],
      retailScoreCategories: [],
      retailScoreSheet: {
        additional_notes: "",
        manager_name: "",
        score_date: new Date(),
        store_id: null,
      },
      notFound: false,
    };
  }

  componentDidMount() {
    this.getRetailScoreCategories();
    this.fetchRetailScoreSheet();
  }

  getRetailScoreCategories = () => {
    new Promise((resolve, reject) => {
      let path =
        "/retail-score-categories?include=retail_score_category_items&type='evaluation_report'";

      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let retailScoreCategoryResponse = JSON.parse(response);

        if (true === retailScoreCategoryResponse?.success) {
          this.setState(
            { retailScoreCategories: retailScoreCategoryResponse.data },
            () => {
              this.setItemScores();
            }
          );
        } else {
          NotificationManager.error("Could not get categories", "Error");
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ stores: [] });

        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get categories", "Error");
        }
      });
  };

  fetchRetailScoreSheet = () => {
    new Promise((resolve, reject) => {
      let path = "/retail-scores/" + this.props.match.params.id;
      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let retailScoreSheetResponse = JSON.parse(response);
        if (null === retailScoreSheetResponse.data) {
          this.setState({ notFound: true });

          return false;
        }

        let retailScoreSheet = this.state.retailScoreSheet;
        retailScoreSheet.score_date = moment(
          retailScoreSheetResponse.data.score_date
        ).toDate();
        retailScoreSheet.store_id = retailScoreSheetResponse.data.store.id;
        retailScoreSheet.store_name = retailScoreSheetResponse.data.store.title;
        retailScoreSheet.manager_name = retailScoreSheetResponse.data.manager
          ? retailScoreSheetResponse.data.manager.firstName +
            " " +
            retailScoreSheetResponse.data.manager.lastName
          : "";
        retailScoreSheet.additional_notes =
          retailScoreSheetResponse.data.additional_notes;

        let evaluatedScore = 0;

        let itemScores = this.state.itemScores;
        retailScoreSheetResponse.data.retail_item_score.forEach(
          (retailScoreSheetItem) => {
            itemScores.forEach((itemScore) => {
              if (
                itemScore.item_id ===
                retailScoreSheetItem.retail_score_category_item.id
              ) {
                itemScore.score = retailScoreSheetItem.total_score;
                evaluatedScore += retailScoreSheetItem.total_score;
              }
            });
          }
        );

        let totalPercentage = Number(
          ((evaluatedScore / this.state.totalScore) * 100).toFixed(2)
        );
        totalPercentage = isNaN(totalPercentage) ? 0 : totalPercentage;
        this.setState({
          evaluatedScore,
          retailScoreSheet,
          itemScores,
          totalPercentage,
        });
      })
      .catch((error) => {
        debugger;
        this.setState({ notFound: true });

        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get categories", "Error");
        }
      });
  };

  setItemScores = () => {
    let retailScoreCategories = this.state.retailScoreCategories;
    let itemScores = [];
    let totalScore = 0;
    let highestScore = 0;

    if (retailScoreCategories.length > 0) {
      retailScoreCategories.forEach((retailScoreCategory) => {
        if (retailScoreCategory.retail_score_category_items?.length > 0) {
          retailScoreCategory.retail_score_category_items.forEach(
            (retailScoreCategoryItem) => {
              totalScore += retailScoreCategoryItem.permissible_score;
              highestScore =
                retailScoreCategoryItem.permissible_score > highestScore
                  ? retailScoreCategoryItem.permissible_score
                  : highestScore;
              itemScores.push({
                item_id: retailScoreCategoryItem.id,
                score: 0,
              });
            }
          );
        }
      });
    }

    // Set itemScores in the state
    this.setState({ itemScores });

    // Set highestScore in the state
    this.setState({ highestScore });

    // Set totalScore in the state
    this.setState({ totalScore });
  };

  countScoresForPoint = (point) => {
    return this.state.itemScores.filter(
      (itemScore) => itemScore.score === point
    ).length;
  };

  getPermissibleScore = (categoryItemId) => {
    let itemScores = this.state.itemScores;

    if (itemScores.length > 0) {
      let itemScore = itemScores.find(
        (itemScore) => itemScore.item_id === categoryItemId
      );
      if (itemScore) {
        return itemScore.score;
      }
    }

    return 0;
  };

  redirectToEdit = (id) => {
    let url = process.env.REACT_APP_UI_URL;
    window.location.href = url + "/retail-score-sheets/" + id + "/edit";
  };

  redirectBack = () => {
    let url = process.env.REACT_APP_UI_URL;
    window.location.href = url + "/retail-score-sheets";
  };

  renderCardContent(categoryItems) {
    return (
      <div className="retail-score-item">
        <Table celled className="unstackable">
          <TableBody>
            {categoryItems?.map((categoryItem, index) => (
              <TableRow key={index}>
                <TableCell>{categoryItem.name}</TableCell>
                <TableCell>
                  {this.getPermissibleScore(categoryItem.id)} /{" "}
                  {categoryItem.permissible_score}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  renderSummary() {
    return (
      <div className="retail-score-summary">
        {this.renderRetailScoreInfo()}

        <div className="summary-box">
          <h3>Points Docked</h3>

          <Table celled className="table unstackable">
            <TableBody>
              {Array.from({ length: this.state.highestScore }, (_, index) => {
                let point = Number(index + 1);
                let pointCount = this.countScoresForPoint(point);
                return (
                  <TableRow key={point}>
                    <TableCell>
                      {point} pt: {pointCount} x {point}
                    </TableCell>
                    <TableCell>{pointCount * point}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <div className="summary-row summary-total total-points">
            <div className="summary-col summary-label">
              <b>Total Docked</b>
            </div>
            <div className="summary-col summary-value">
              {this.state.evaluatedScore}
            </div>
          </div>
          <div className="summary-row summary-total">
            <div className="summary-col summary-label">
              <b>Total Possible Points</b>
            </div>
            <div className="summary-col summary-value">
              {this.state.totalScore}
            </div>
          </div>
          <div className="summary-row summary-total">
            <div className="summary-col summary-label">
              <b>Less Total Docked</b>
            </div>
            <div className="summary-col summary-value">
              {this.state.totalScore - this.state.evaluatedScore}
            </div>
          </div>
          <div className="summary-row summary-grand-total">
            <div className="summary-col summary-label">
              <b>TOTAL SCORE</b>
            </div>
            <div className="summary-col summary-value">
              {this.state.evaluatedScore} ({this.state.totalPercentage}%)
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRetailScoreInfo() {
    return (
      <div className="retail-score-info">
        <div className="additional-info">
          <h3>Additional notes : </h3>
          <p> {this.state.retailScoreSheet.additional_notes} </p>
        </div>
        <div className="score-end-row">
          <div className="score-end-col">
            Manager: {this.state.retailScoreSheet.manager_name}
          </div>
          <div className="score-end-col">
            Date:{" "}
            {moment(this.state.retailScoreSheet.score_date).format(
              "DD/MM/YYYY"
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.notFound) {
      return <NotFound />;
    }
    return (
      <div className="retail-score-view">
        <Title paramsPageTitle={`Evaluation Report - ${this.state.retailScoreSheet.store_name}`} />
        {isPermitted(
          "retail-score-sheet-edit",
          this.state.retailScoreSheet.store_id
        ) ? (
          <div>
            <Button
              className="orange"
              icon
              labelPosition="left"
              color="orange"
              size="tiny"
              onClick={() => this.redirectToEdit(this.props.match.params.id)}
            >
              <Icon name="pencil" />
              Edit
            </Button>
          </div>
        ) : (
          ""
        )}
        <div className="retail-score-listing">
          {this.state.retailScoreCategories?.map(
            (retailScoreCategory, index) => (
              <Card key={index} className="retail-score-card" fluid>
                <Card.Content
                  className="retail-score-card-header"
                  header={retailScoreCategory.name}
                />
                <Card.Content
                  className="retail-score-card-content"
                  description={this.renderCardContent(
                    retailScoreCategory.retail_score_category_items
                  )}
                />
              </Card>
            )
          )}
        </div>
        {this.renderSummary()}
        <div className="common-form">
          <Button className="orange" onClick={() => this.redirectBack()}>
            {" "}
            Back
          </Button>
        </div>
        <NotificationContainer />
      </div>
    );
  }
}

export default RetailScoreSheetView;