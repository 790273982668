import React from "react";
import {
  Button,
  Card,
  Grid,
  Header,
  Image,
  Modal,
  Icon,
  FormGroup,
  FormField,
  FormSelect,
  FormTextArea,
  FormInput,
  Form,
  Select,
} from "semantic-ui-react";
import "./../../assets/css/pages/managment/incident-report.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Request from "../../Request";
import Title from "components/Title";
import { formatDate, formatTime } from "../../hooks/DateTimeUtility";
import { useRolePermissions } from "../../hooks/useRolePermissions";

const { redirectToForbiddenIfUnauthorized, isPermitted } = useRolePermissions();

class IncidentReportEdit extends React.Component {
  state = {
    showTrashed: false,
    customSize: window.innerWidth <= 1150,
    users: [],
    managers: [],
    stores: [],
    storeOptions: [],
    userOptions: [],
    managerOptions: [],
    renderUsers: false,
    modalOpen: false,
    removeId: null,
    editId: null,
    incidentReport: [],
    renderStores: false,
    renderIncidentReport: false,
  };

  constructor() {
    super();
    this.request = new Request();
  }

  componentDidMount() {
    this.getUsers();
    this.getManagers();
    this.getStores();
    if (this.props.match.params.id) {
      this.setState({ editId: this.props.match.params.id }, () => {
        this.getIncidentReport();
      });
    }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ customSize: window.innerWidth <= 1150 });
  }

  getUsers = () => {
    new Promise((resolve, reject) => {
      let path = "/user-list/?trashed=" + (this.state.showTrashed ? "1" : "0");
      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let users = JSON.parse(response);
        if (users && users.success === true && users.data) {
          this.setState({ users: users.data }, () => {
            this.setState({ renderUsers: true });
            this.transformUsersToOptions();
          });
        } else {
          this.setState({ users: [] }, () => {
            this.setState({ renderUsers: true });
          });
        }
      })
      .catch((error) => {
        this.setState({ users: [] }, () => {
          this.setState({ renderUsers: true });
        });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get Users", "Error");
        }
      });
  };

  transformUsersToOptions = () => {
    let users = this.state.users;
    let options = [];
    users.forEach((user, index) => {
      let tempOption = {
        id: user.id,
        key: user.id,
        value: user.id,
        text: user.name,
      };
      options.push(tempOption);
    });
    this.setState({ userOptions: options });
  };

  getStores = () => {
    new Promise((resolve, reject) => {
      let path = "/stores-of-user";
      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let stores = JSON.parse(response);
        if (stores && stores.success === true && stores.data) {
          this.setState({ stores: stores.data, store: stores.data[0] }, () => {
            this.transformStoresToOptions();
            this.setState({ renderStores: true });
          });
        } else {
          this.setState({ stores: [] });
        }
      })
      .catch((error) => {
        this.setState({ stores: [] });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get Stores", "Error");
        }
      });
  };

  transformStoresToOptions = () => {
    let stores = this.state.stores;
    let options = [];
    stores.forEach((store, index) => {
      if (
        isPermitted("incident-reports-create", store.id) ||
        isPermitted("incident-reports-edit", store.id)
      ) {
        let tempOption = {
          id: store.id,
          key: store.id,
          value: store.id,
          text: store.title,
        };
        options.push(tempOption);
      }
    });
    this.setState({ storeOptions: options });
  };

  getManagers = () => {
    new Promise((resolve, reject) => {
      let path = "/user-managers";
      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let managers = JSON.parse(response);
        if (managers && managers.success === true && managers.data) {
          this.setState({ managers: managers.data }, () => {
            this.setState({ renderManagers: true });
            this.transformManagerUsersToOptions();
          });
        } else {
          this.setState({ managers: [] }, () => {
            this.setState({ renderManagers: true });
          });
        }
      })
      .catch((error) => {
        this.setState({ managers: [] }, () => {
          this.setState({ renderManagers: true });
        });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get Managers", "Error");
        }
      });
  };

  transformManagerUsersToOptions = () => {
    let managers = this.state.managers;
    let options = [];
    managers.forEach((manager, index) => {
      let tempOption = {
        id: manager.id,
        key: manager.id,
        value: manager.id,
        text: manager.name,
      };
      options.push(tempOption);
    });
    this.setState({ managerOptions: options });
  };

  handleInputChanged = (event, field) => {
    let incidentReport = this.state.incidentReport;
    incidentReport[field] = event.target.value;
    this.setState({ incidentReport: incidentReport });
  };

  handleSelectChanged = (event, { value }, field) => {
    let incidentReport = this.state.incidentReport;
    incidentReport[field] = value;
    this.setState({ incidentReport: incidentReport });
  };

  updateIncidentReport = () => {
    let editId = this.state.editId;
    const formData = this.state.incidentReport;

    new Promise((resolve, reject) => {
      let path = "/incident-report/" + editId;
      this.request.post(path, formData, resolve, reject);
    })
      .then((response) => {
        NotificationManager.success(
          "Successfully saved incident report",
          "Success"
        );
        this.redirectBack();
      })
      .catch((error) => {
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not save incident report", "Error");
        }
      });
  };

  getIncidentReport = () => {
    if (this.state.editId) {
      new Promise((resolve, reject) => {
        let path = "/incident-report/" + this.state.editId + "/view";
        this.request.get(path, resolve, reject);
      })
        .then((response) => {
          let incidentReport = JSON.parse(response);
          if (
            incidentReport &&
            incidentReport.success === true &&
            incidentReport.data
          ) {
            incidentReport.data.incidentDate = formatDate(
              incidentReport.data?.incidentDate?.date
            );
            incidentReport.data.incidentTime = formatTime(
              incidentReport.data?.incidentTime?.date
            );

            incidentReport.data.enteredDate = formatDate(
              incidentReport.data?.enteredDate?.date
            );
            this.setState({ incidentReport: incidentReport.data }, () => {
              this.setState({ renderIncidentReport: true });
            });
          } else {
            this.setState({ incidentReport: {} }, () => {
              this.setState({ renderIncidentReport: false });
            });
          }
        })
        .catch((error) => {
          this.setState({ incidentReport: {} }, () => {
            this.setState({ renderIncidentReport: false });
          });
          if (error) {
            NotificationManager.error(error, "Error");
          } else {
            NotificationManager.error("Could not get Incident Report", "Error");
          }
        });
    }
  };

  redirectBack = () => {
    let url = process.env.REACT_APP_UI_URL;
    window.location.href = url + "/management/incident-report-list";
  };

  render() {
    if (this.state.renderStores && this.state.renderIncidentReport) {
      let incidentReport = this.state.incidentReport;

      return (
        <div className="users-admin-root">
          <Title paramsPageTitle="Incident Report Form Edit" />
          <div className="incident-report-save-button-div">
            <Form>
              <FormGroup widths="equal">
                <FormField>
                  <FormInput
                    required
                    label="Incident Date:"
                    type="date"
                    onChange={(e) => this.handleInputChanged(e, "incidentDate")}
                    value={incidentReport.incidentDate}
                  />
                </FormField>
                <FormField
                  required
                  control={Select}
                  options={this.state.managerOptions}
                  label={{
                    children: "Manager:",
                    htmlFor: "form-select-control-employee",
                  }}
                  placeholder=" "
                  search
                  searchInput={{ id: "form-select-control-employee" }}
                  onChange={(e, data) =>
                    this.handleSelectChanged(e, data, "managerId")
                  }
                  value={incidentReport.managerId}
                />
              </FormGroup>
              <FormGroup widths="equal">
                <FormField>
                  <FormInput
                    required
                    label="Incident Time:"
                    type="time"
                    onChange={(e) => this.handleInputChanged(e, "incidentTime")}
                    value={incidentReport.incidentTime}
                  />
                </FormField>
                {/* <FormField>
                  <FormSelect
                    required
                    fluid
                    label="Stores:"
                    options={this.state.storeOptions}
                    placeholder=""
                    onChange={(e, data) =>
                      this.handleSelectChanged(e, data, "storeId")
                    }
                    value={incidentReport.storeId}
                  />
                </FormField> */}
                <FormField>
                  <label>Stores</label>
                  <Select
                    placeholder="Select"
                    options={this.state.storeOptions}
                    value={incidentReport.storeId}
                    onChange={(e, data) =>
                      this.handleSelectChanged(e, data, "storeId")
                    }
                  />
                </FormField>
              </FormGroup>
              <FormField
                required
                control={Select}
                options={this.state.userOptions}
                label={{
                  children: "Employee on Duty Name1:",
                  htmlFor: "form-select-control-employee",
                }}
                placeholder=" "
                search
                searchInput={{ id: "form-select-control-employee" }}
                onChange={(e, data) =>
                  this.handleSelectChanged(e, data, "employeeOnDuty1Id")
                }
                value={incidentReport.employeeOnDuty1Id}
              />
              <FormField
                required
                control={Select}
                options={this.state.userOptions}
                label={{
                  children: "Employee on Duty Name2:",
                  htmlFor: "form-select-control-employee",
                }}
                placeholder=" "
                search
                searchInput={{ id: "form-select-control-employee" }}
                onChange={(e, data) =>
                  this.handleSelectChanged(e, data, "employeeOnDuty2Id")
                }
                value={incidentReport.employeeOnDuty2Id}
              />
              <FormField
                required
                control={Select}
                options={this.state.userOptions}
                label={{
                  children: "Employee on Duty Name3:",
                  htmlFor: "form-select-control-employee",
                }}
                placeholder=" "
                search
                searchInput={{ id: "form-select-control-employee" }}
                onChange={(e, data) =>
                  this.handleSelectChanged(e, data, "employeeOnDuty3Id")
                }
                value={incidentReport.employeeOnDuty3Id}
              />
              <FormField>
                <FormInput
                  required
                  label="Subject:"
                  value={incidentReport.subject || ""}
                  onChange={(e) => this.handleInputChanged(e, "subject")}
                />
              </FormField>
              <FormField>
                <FormTextArea
                  required
                  label="Incident Description:"
                  value={incidentReport.description || ""}
                  onChange={(e) => this.handleInputChanged(e, "description")}
                />
              </FormField>
              <FormGroup widths="equal">
                <FormField>
                  <FormInput
                    label="Witness Name1:"
                    value={incidentReport.witnessName1 || ""}
                    onChange={(e) => this.handleInputChanged(e, "witnessName1")}
                  />
                </FormField>
                <FormField>
                  <FormInput
                    label="Phone1:"
                    value={incidentReport.witnessPhone1 || ""}
                    onChange={(e) =>
                      this.handleInputChanged(e, "witnessPhone1")
                    }
                  />
                </FormField>
              </FormGroup>
              <FormGroup widths="equal">
                <FormField>
                  <FormInput
                    label="Witness Name2:"
                    value={incidentReport.witnessName2 || ""}
                    onChange={(e) => this.handleInputChanged(e, "witnessName2")}
                  />
                </FormField>
                <FormField>
                  <FormInput
                    label="Phone2:"
                    value={incidentReport.witnessPhone2 || ""}
                    onChange={(e) =>
                      this.handleInputChanged(e, "witnessPhone2")
                    }
                  />
                </FormField>
              </FormGroup>
              <FormGroup widths="equal">
                <FormField>
                  <FormInput
                    label="Witness Name3:"
                    value={incidentReport.witnessName3 || ""}
                    onChange={(e) => this.handleInputChanged(e, "witnessName3")}
                  />
                </FormField>
                <FormField>
                  <FormInput
                    label="Phone3:"
                    value={incidentReport.witnessPhone3 || ""}
                    onChange={(e) =>
                      this.handleInputChanged(e, "witnessPhone3")
                    }
                  />
                </FormField>
              </FormGroup>
              <FormGroup widths="equal">
                <FormField required>
                  <label>
                    Employee Signature <small>(TYPE FULL NAME)</small>:
                  </label>
                  <FormInput
                    value={incidentReport.employeeSignature || ""}
                    onChange={(e) =>
                      this.handleInputChanged(e, "employeeSignature")
                    }
                  />
                </FormField>
                <FormField>
                  <FormInput
                    required
                    label="Submission Date:"
                    type="date"
                    onChange={(e) => this.handleInputChanged(e, "enteredDate")}
                    value={incidentReport.enteredDate}
                  />
                </FormField>
              </FormGroup>
              <div className="right-align-button-outer">
                <Button color="red" onClick={this.redirectBack}>
                  Back
                </Button>
                <Button color="orange" onClick={this.updateIncidentReport}>
                  Submit
                </Button>
              </div>
            </Form>
          </div>

          <NotificationContainer />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default IncidentReportEdit;
