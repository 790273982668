import React from 'react';
import { Grid, Button, Icon, Card, Modal, Header } from "semantic-ui-react";
import './../../assets/css/pages/admin/documents.css';
import { NotificationContainer, NotificationManager } from "react-notifications";
import Request from "../../Request";
import Cookies from "universal-cookie";
import Title from 'components/Title';
import { useRolePermissions } from './../../hooks/useRolePermissions';

const { redirectToForbiddenIfUnauthorized, isPermitted } = useRolePermissions();

class Documents extends React.Component {
    state = {
        documents: [],
        renderDocuments: false,
        customSize: window.innerWidth <= 1360,
        tabletSize: window.innerWidth <= 900,
        token: null,
        modalOpen: false,
        removeId: null
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        redirectToForbiddenIfUnauthorized('documents-forms-view');
        this.setState({ renderDocuments: false }, () => {
            this.getHrDocuments();
        });

        this.saveToken();

        window.addEventListener("resize", this.resize.bind(this));

        this.resize();
    }

    saveToken = () => {
        let cookies = new Cookies();
        let decodedCookie = cookies.getAll();
        if (decodedCookie.token) {
            this.setState({ token: decodedCookie.token });
        }
        else {
            this.redirectToLogin();
        }
    };

    redirectToLogin() {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/logout';
    }

    resize() {
        this.setState({ customSize: window.innerWidth <= 1360, tabletSize: window.innerWidth <= 900 });
    }

    getHrDocuments = () => {
        new Promise((resolve, reject) => {
            let path = '/documents';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let documents = JSON.parse(response);
                if (documents && documents.success === true && documents.data) {
                    this.setState({ documents: documents.data }, () => {
                        this.setState({ renderDocuments: true });
                    });
                }
            })
            .catch(error => {
                this.setState({ renderDocuments: true });
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Hr-Documents', 'Error');
                }
            });
    };

    deleteDocument = (id) => {
        this.setState({ modalOpen: false });
        if (id) {
            new Promise((resolve, reject) => {
                let path = '/documents/' + id;
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if (response && response.success === true) {
                        NotificationManager.success('Successfully removed document', 'Success');
                        this.getHrDocuments();
                    }
                })
                .catch(error => {
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not remove document', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not remove document', 'Error');
        }
    };

    redirectToEditPage = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/documents/' + id;
    };

    getHrDocumentsContent = () => {
        let documents = this.state.documents;
        return (
            <Grid className="documents-grid">
                <Grid.Row className="documents-grid-header-row">
                    <Grid.Column width={this.state.customSize ? 5 : 6}>
                        Title
                    </Grid.Column>
                    <Grid.Column width={7}>
                        Filename
                    </Grid.Column>
                    <Grid.Column width={1}>
                        Size
                    </Grid.Column>
                    <Grid.Column width={this.state.customSize ? 3 : 2}>
                    </Grid.Column>
                </Grid.Row>
                {documents && Array.isArray(documents) ?
                    documents.map((document, index) => {
                        return (
                            <Grid.Row className="documents-grid-row">
                                <Grid.Column width={this.state.customSize ? 5 : 6}>
                                    <a href={'/admin/documents/' + document.id}><b>{document.title || ''}</b></a>
                                </Grid.Column>
                                <Grid.Column width={7}>
                                    {document.file || ''}
                                </Grid.Column>
                                <Grid.Column width={1}>
                                    {document.size || ''}
                                </Grid.Column>
                                <Grid.Column width={this.state.customSize ? 3 : 2}>
                                    <Button.Group icon>
                                        {isPermitted('documents-forms-edit') &&
                                            <Button size='tiny' onClick={(e) => this.redirectToEditPage(document.id)}>
                                                <Icon name='pencil' />
                                            </Button>
                                        }
                                        {isPermitted('documents-forms-delete') &&
                                            <Button size='tiny' onClick={(e) => this.removeDocumentConfirmation(document.id)}>
                                                <Icon name='times' />
                                            </Button>
                                        }
                                        {isPermitted('documents-forms-download') &&
                                            <Button size='tiny' as='a' href={process.env.REACT_APP_URL + '/documents/files/' + document.id + '/download?token=' + this.state.token}>
                                                <Icon name='download' />
                                            </Button>
                                        }
                                    </Button.Group>
                                </Grid.Column>
                            </Grid.Row>
                        );
                    })
                    : ''
                }
                <Grid.Row className="documents-grid-footer-row">
                    <Grid.Column width={2}>

                        {isPermitted('documents-forms-create') &&
                            <Button icon labelPosition='left' onClick={(e) => this.redirectToEditPage('new')}>
                                <Icon name='plus' />
                                Document
                            </Button>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    getHrDocumentsCard = () => {
        let documents = this.state.documents;
        let cards = [];
        cards.push(
            <div className="documents-card-check-button-div">
                <Button onClick={this.checkAllCheckboxes}>Check All</Button>
            </div>
        );
        if (documents && Array.isArray(documents)) {
            documents.forEach((document, index) => {
                cards.push(
                    <Card className="documents-card" fluid key={index}>
                        <Card.Content className="documents-card-header" header={document.title} />
                        <Card.Content description={this.getOneCardDescription(document, index)} />
                    </Card>
                );
            });
            cards.push(
                <div>
                    <Button icon labelPosition='left' onClick={(e) => this.redirectToEditPage('new')}>
                        <Icon name='plus' />
                        Document
                    </Button>
                </div>
            );
            return cards;
        }
        cards.push(
            <div>
                {isPermitted('documents-forms-create') &&
                    <Button icon labelPosition='left' onClick={(e) => this.redirectToEditPage('new')}>
                        <Icon name='plus' />
                        Document
                    </Button>
                }
            </div>
        );
        return cards;
    };

    handleCloseModal = () => {
        this.setState({
            removeId: null,
            modalOpen: false
        });
    };

    removeDocumentConfirmation = (id) => {
        this.setState({ removeId: id, modalOpen: true });
    };

    getConfirmationModal = () => {
        let removeId = this.state.removeId;

        if (removeId || removeId === 0) {
            return (
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleCloseModal}
                    size='small'
                >
                    <Header content='Delete Document' />
                    <Modal.Content>
                        <h3>Are you sure you want to delete this Document?</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleCloseModal} inverted>
                            No
                        </Button>
                        <Button color='green' onClick={() => this.deleteDocument(removeId)} inverted>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            )
        }
        return '';
    };

    getOneCardDescription = (document, index) => {
        return (
            <Grid className="hr-documents-card-grid">
                <Grid.Row className="documents-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Title: </b><a href={'/admin/documents/' + document.id}><b>{document.title || ''}</b></a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="hr-documents-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Filename: </b>{document.file}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="hr-documents-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Size: </b>{document.size}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="hr-documents-card-grid-row">
                    <Grid.Column width={16}>
                        <Button.Group icon>

                            {isPermitted('documents-forms-edit') &&
                                <Button size='tiny' onClick={(e) => this.redirectToEditPage(document.id)}>
                                    <Icon name='pencil' />
                                </Button>
                            }

                            {isPermitted('documents-forms-delete') &&
                                <Button size='tiny' onClick={(e) => this.removeDocumentConfirmation(document.id)}>
                                    <Icon name='times' />
                                </Button>
                            }

                            {isPermitted('documents-forms-download') &&
                                <Button size='tiny' as='a' href={process.env.REACT_APP_URL + '/documents/files/' + document.id + '/download'}>
                                    <Icon name='download' />
                                </Button>
                            }
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    };

    render() {
        return (
            <div className="documents-root">
                <Title
                    paramsPageTitle="HR Documents"
                />
                {this.state.renderDocuments ? (this.state.tabletSize ? this.getHrDocumentsCard() : this.getHrDocumentsContent()) : ''}
                {this.getConfirmationModal()}
                <NotificationContainer />
            </div>
        );
    }
}

export default Documents;
