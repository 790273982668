import React from 'react';
import { NotificationContainer, NotificationManager } from "react-notifications";
import './../../assets/css/pages/admin/document-new-and-edit.css';
import { Input, Button } from "semantic-ui-react";
import Request from "../../Request";
import Title from 'components/Title';
import Cookies from "universal-cookie";
import { useRolePermissions } from './../../hooks/useRolePermissions';

const { isPermitted } = useRolePermissions();

class DocumentsNewAndEdit extends React.Component {
    state = {
        editId: null,
        document: {},
        inputValue: '',
        file: null,
        filename: '',
        loading: false,
        token: null,
    };

    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.request = new Request();
    };

    componentDidMount() {
        if (this.props.match.params.id) {
            this.setState({ editId: this.props.match.params.id }, () => {
                this.getDocument();
            })
        }

        this.saveToken();
    }

    saveToken = () => {
        let cookies = new Cookies();
        let decodedCookie = cookies.getAll();
        if (decodedCookie.token) {
            this.setState({ token: decodedCookie.token });
        }
        else {
            this.redirectToLogin();
        }
    };

    getDocument = () => {
        if (this.state.editId && this.state.editId !== 'new') {
            new Promise((resolve, reject) => {
                let path = '/documents/' + this.state.editId;
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let document = JSON.parse(response);
                    if (document && document.success === true && document.data) {
                        this.setState({ document: document.data, inputValue: document.data.title, loading: false })
                    }
                    else {
                        this.setState({ document: {}, loading: false });
                    }
                })
                .catch(error => {
                    this.setState({ document: {}, loading: false });
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Document', 'Error');
                    }
                });
        }
    };

    saveNewDocument = () => {
        this.setState({ loading: true });
        let inputValue = this.state.inputValue.trim();
        let file = this.state.file;
        if (this.state.editId === 'new' && inputValue !== '' && file !== null) {
            new Promise((resolve, reject) => {
                let path = '/documents';
                let params = {
                    title: inputValue,
                    file: file,
                    filename: this.state.filename
                };
                this.request.post(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully saved document', 'Success');
                    this.resetFile();
                    this.redirectBack();
                })
                .catch(error => {
                    this.setState({ loading: false });
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not save document', 'Error');
                    }
                });
        }
    };

    updateDocument = () => {
        this.setState({ loading: true });
        let inputValue = this.state.inputValue.trim();
        let editId = this.state.editId;
        if (editId !== 'new' && inputValue !== '') {
            new Promise((resolve, reject) => {
                let path = '/documents/' + editId;
                let params = {
                    title: inputValue
                };
                this.request.put(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated document', 'Success');
                    this.resetFile();
                    this.redirectBack();
                })
                .catch(error => {
                    this.setState({ loading: false });
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update document', 'Error');
                    }
                });
        }
    };

    redirectBack = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/documents';
    };

    inputChanged = (event) => {
        this.setState({ inputValue: event.target.value })
    };

    handleFileChanged = (event) => {
        event.preventDefault();
        let file = event.target.files[0];
        this.setState({ filename: event.target.files[0].name });
        if (file) {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                this.setState({
                    file: reader.result
                });
            };
        }
    };

    resetFile = () => {
        this.setState({ file: null, filename: null });
    };

    render() {
        return (
            <div className="hr-documents-root">
                <Title
                    paramsPageTitle="HR Documents"
                />
                <div>
                    <b>Title:</b>
                </div>
                <div className="new-document-input-div">
                    <Input fluid placeholder="Enter title" value={this.state.inputValue} onChange={this.inputChanged} />
                </div>

                {this.state.editId && this.state.editId === 'new' ?
                    <div>
                        <div>
                            <b>Upload File:</b>
                        </div>
                        <div className="new-document-file-input">
                            <div>
                                <input ref={this.fileInput} type="file" onChange={this.handleFileChanged} />
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        {isPermitted('documents-forms-download') &&
                            <div className="update-document-file-name">
                                <b>Upload File: </b>
                                <span>{this.state.document.file}</span>
                                <a href={process.env.REACT_APP_URL + '/documents/files/' + this.state.document.id + '/download?token=' + this.state.token}> (Download)</a>
                            </div>
                        }
                    </div>
                }
                {(isPermitted('documents-forms-edit') || isPermitted('documents-forms-create')) &&
                    <div>
                        {this.state.loading ?
                            <div className="new-document-button-div">
                                <Button loading color="orange">
                                    Loading
                                </Button>
                            </div>
                            :
                            this.state.editId && this.state.editId === 'new' ?
                                <div className="new-document-button-div">
                                    <Button color="orange" onClick={this.saveNewDocument}>Submit</Button>
                                    <Button color="orange" onClick={this.redirectBack}>Cancel</Button>
                                </div>
                                :
                                <div className="new-document-button-div">
                                    <Button color="orange" onClick={this.updateDocument}>Update</Button>
                                    <Button color="orange" onClick={this.redirectBack}>Cancel</Button>
                                </div>
                        }
                    </div>
                }

                <NotificationContainer />
            </div>
        )
    }
}

export default DocumentsNewAndEdit;