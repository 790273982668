import React from 'react';
import { Button, Card, Grid, Header, Icon, Modal } from "semantic-ui-react";
import './../../assets/css/pages/admin/vendors.css';
import { NotificationContainer, NotificationManager } from "react-notifications";
import Request from "../../Request";
import Title from 'components/Title';
import { useRolePermissions } from './../../hooks/useRolePermissions';

const { redirectToForbiddenIfUnauthorized, isPermitted } = useRolePermissions();

class Vendors extends React.Component {
    state = {
        customSize: window.innerWidth <= 1000,
        vendors: [],
        renderVendors: false,
        showDeactivated: 0,
        modalOpen: false,
        removeId: null
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        redirectToForbiddenIfUnauthorized('vendors-view');
        this.getVendors();

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({ customSize: window.innerWidth <= 1000 });
    }

    getVendors = () => {
        new Promise((resolve, reject) => {
            let path = '/vendors?trashed=' + this.state.showDeactivated;
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let vendors = JSON.parse(response);
                if (vendors && vendors.success === true && vendors.data) {
                    this.setState({ vendors: vendors.data }, () => {
                        this.setState({ renderVendors: true });
                    });
                }
                else {
                    this.setState({ vendors: [] }, () => {
                        this.setState({ renderVendors: true })
                    });
                }
            })
            .catch(error => {
                this.setState({ vendors: [] }, () => {
                    this.setState({ renderVendors: true })
                });
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Vendors', 'Error');
                }
            });
    };

    deactivateVendor = (id) => {
        if (id) {
            new Promise((resolve, reject) => {
                let path = '/vendors/' + id + '/deactivate';
                this.request.put(path, null, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if (response && response.success === true) {
                        NotificationManager.success('Successfully deactivated Vendor', 'Success');
                        this.getVendors();
                    }
                })
                .catch(error => {
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not deactivate Vendor', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not deactivate Vendor', 'Error');
        }
    };

    restoreVendor = (id) => {
        if (id) {
            new Promise((resolve, reject) => {
                let path = '/vendors/' + id + '/activate';
                this.request.put(path, null, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if (response && response.success === true) {
                        NotificationManager.success('Successfully activated Vendor', 'Success');
                        this.getVendors();
                    }
                })
                .catch(error => {
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not activate Vendor', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not activate Vendor', 'Error');
        }
    };

    deleteVendor = (id) => {
        this.setState({ modalOpen: false });
        if (id) {
            new Promise((resolve, reject) => {
                let path = '/vendors/' + id;
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if (response && response.success === true) {
                        NotificationManager.success('Successfully removed Vendor', 'Success');
                        this.getVendors();
                    }
                })
                .catch(error => {
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not remove Vendor', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not remove Vendor', 'Error');
        }
    };

    showDeactivatedToogle = () => {
        let showDeactivated = this.state.showDeactivated;
        showDeactivated = showDeactivated === 1 ? 0 : 1;
        this.setState({ showDeactivated: showDeactivated }, () => {
            this.getVendors();
        });
    };

    redirectToViewPage = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/vendors/' + id;
    };

    getOneCardDescription = (vendor, index) => {
        return (
            <Grid className="vendors-admin-card-grid">
                <Grid.Row className="vendors-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Name: </b><a href={'/admin/vendors/' + vendor.id}><b>{vendor.name || ''}</b></a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="vendors-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Email: </b>{vendor.email || ''}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="vendors-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Location: </b>{vendor.city + ', ' + vendor.state}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="vendors-admin-card-grid-row">
                    <Grid.Column width={16}>
                        <b>Phone: </b>{vendor.phone || ''}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="vendors-admin-card-grid-row">
                    <Grid.Column width={16}>
                        {!this.state.showDeactivated ?
                            <div className="vendors-admin-card-grid-row-buttons-div">
                                <Button size='tiny' onClick={(e) => this.redirectToViewPage(vendor.id)} title="View Vendor">
                                    <Icon name='eye' />
                                    View Vendor
                                </Button>
                                <Button size='tiny' title="Deactivate Vendor" onClick={() => this.deactivateVendor(vendor.id)}>
                                    <Icon name='trash' />
                                    Deactivate Vendor
                                </Button>
                            </div>
                            :
                            <div className="vendors-admin-card-grid-row-buttons-div">
                                <Button size='tiny' title="Restore Vendor" onClick={() => this.restoreVendor(vendor.id)}>
                                    <Icon name='refresh' />
                                    Restore Vendor
                                </Button>
                                <Button size='tiny' title="Delete Vendor" onClick={() => this.removeVendorConfirmation(vendor.id)}>
                                    <Icon name='times' />
                                    Delete Vendor
                                </Button>
                            </div>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    };

    getVendorsCard = () => {
        let cards = [];
        let vendors = this.state.vendors;
        vendors.forEach((vendor, index) => {
            cards.push(
                <Card className="vendors-admin-card" fluid key={index}>
                    <Card.Content className="vendors-admin-card-header" header={vendor.name} />
                    <Card.Content description={this.getOneCardDescription(vendor, index)} />
                </Card>
            );
        });
        cards.push(
            <div key="vendors-admin-plus-button">
                <Button icon labelPosition='left' onClick={(e) => this.redirectToEditPage('new')}>
                    <Icon name='plus' />
                    Vendor
                </Button>
            </div>
        );
        return cards;
    };

    getVendorsContent = () => {
        return (
            <Grid className="vendors-admin-grid">
                <Grid.Row className="vendors-admin-grid-header-row">
                    <Grid.Column width={2}>
                        Name
                    </Grid.Column>
                    <Grid.Column width={6}>
                        Email
                    </Grid.Column>
                    <Grid.Column width={3}>
                        Location
                    </Grid.Column>
                    <Grid.Column width={3}>
                        Phone
                    </Grid.Column>
                    <Grid.Column width={2}>
                    </Grid.Column>
                </Grid.Row>
                {this.state.vendors.map((vendor, index) => {
                    return (
                        <Grid.Row className="vendors-admin-grid-row" key={index}>
                            <Grid.Column width={2}>
                                <a href={'/admin/vendors/' + vendor.id}><b>{vendor.name || ''}</b></a>
                            </Grid.Column>
                            <Grid.Column width={6} className="vendors-admin-grid-column">
                                {vendor.email || ''}
                            </Grid.Column>
                            <Grid.Column width={3} className="vendors-admin-grid-column">
                                {vendor.city + ', ' + vendor.state}
                            </Grid.Column>
                            <Grid.Column width={3} className="vendors-admin-grid-column">
                                {vendor.phone || ''}
                            </Grid.Column>
                            <Grid.Column width={2} className="vendors-admin-grid-actions-column">
                                <Button.Group icon>
                                    {!this.state.showDeactivated ?
                                        <div>
                                            <Button size='tiny' onClick={(e) => this.redirectToViewPage(vendor.id)} title="View Vendor">
                                                <Icon name='eye' />
                                            </Button>
                                            <Button size='tiny' title="Deactivate Vendor" onClick={() => this.deactivateVendor(vendor.id)}>
                                                <Icon name='trash' />
                                            </Button>
                                        </div>
                                        :
                                        <div>
                                            {isPermitted('vendors-edit') &&
                                                <Button size='tiny' title="Restore Vendor" onClick={() => this.restoreVendor(vendor.id)}>
                                                    <Icon name='refresh' />
                                                </Button>
                                            }
                                            {isPermitted('vendors-delete') &&
                                                <Button size='tiny' title="Delete Vendor" onClick={() => this.removeVendorConfirmation(vendor.id)}>
                                                    <Icon name='times' />
                                                </Button>
                                            }
                                        </div>
                                    }
                                </Button.Group>
                            </Grid.Column>
                        </Grid.Row>
                    )
                })
                }

                <Grid.Row className="vendors-admin-grid-footer-row">
                    {isPermitted('vendors-create') &&
                        <Grid.Column width={2}>
                            <Button icon labelPosition='left' onClick={(e) => this.redirectToEditPage('new')}>
                                <Icon name='plus' />
                                Vendor
                            </Button>
                        </Grid.Column>
                    }
                </Grid.Row>
            </Grid>
        );
    };

    redirectToEditPage = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/vendors/' + id + '/edit';
    };

    handleCloseModal = () => {
        this.setState({
            removeId: null,
            modalOpen: false
        });
    };

    getConfirmationModal = () => {
        let removeId = this.state.removeId;

        if (removeId || removeId === 0) {
            return (
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleCloseModal}
                    size='small'
                >
                    <Header content='Delete Vendor' />
                    <Modal.Content>
                        <h3>This will completely remove the vendor with all its categories and products.</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleCloseModal} inverted>
                            No
                        </Button>
                        <Button color='green' onClick={() => this.deleteVendor(removeId)} inverted>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            )
        }
        return '';
    };

    removeVendorConfirmation = (id) => {
        this.setState({ removeId: id, modalOpen: true });
    };

    render() {
        return (
            <div className="vendors-admin-root">
                <Title
                    paramsPageTitle="Vendors"
                />
                <div className="vendors-admin-trash-button-div">
                    {this.state.showDeactivated ?
                        <Button color="orange" onClick={this.showDeactivatedToogle}><Icon name="angle left" />Show Active</Button>
                        :
                        <Button color="orange" onClick={this.showDeactivatedToogle}><Icon name="trash" />Show Deactivated</Button>
                    }
                </div>
                {this.state.renderVendors ?
                    this.state.customSize ? this.getVendorsCard() : this.getVendorsContent()
                    :
                    ''
                }
                {this.getConfirmationModal()}
                <NotificationContainer />
            </div>
        );
    }
}

export default Vendors;
