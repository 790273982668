import React from 'react';
import './../../assets/css/pages/admin/recipes-admin.css';
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Grid, Button, Card, List, Modal, Header, Icon, Select, Form, Label, Popup } from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RichTextEditor from "react-rte";
import Request from "../../Request";
import { useRolePermissions } from './../../hooks/useRolePermissions';

const { isPermitted, redirectToForbiddenIfUnauthorized, isCorporate } = useRolePermissions();

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: 8
});
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 8 * 2,
    margin: `0`,

    // change background colour if dragging
    background: isDragging ? "lightgray" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
});


class Recipes extends React.Component {
    state = {
        recipes: [],
        renderRecipes: false,
        modalOpen: false,
        removeId: null,
        dragAndDropData: [],
        options: [
            {
                id: 0,
                key: 'espresso-bar',
                value: 'espresso-bar',
                text: 'Espresso Bar'
            },
            {
                id: 1,
                key: 'coffee-bar',
                value: 'coffee-bar',
                text: 'Coffee Bar'
            },
            {
                id: 2,
                key: 'tea-bar',
                value: 'tea-bar',
                text: 'Tea Bar'
            },
            {
                id: 3,
                key: 'cooling-aids',
                value: 'cooling-aids',
                text: 'Cooling Aids'
            },
            {
                id: 4,
                key: 'cofftails-dreams',
                value: 'cofftails-dreams',
                text: 'Cofftails & Dreams'
            },
            {
                id: 5,
                key: 'batch-recipes',
                value: 'batch-recipes',
                text: 'Batch Recipes'
            },
            {
                id: 6,
                key: 'hot-food-prep',
                value: 'hot-food-prep',
                text: 'Hot Food Prep'
            },
        ],
        selectedRecipe: {
            id: 0,
            key: 'espresso-bar',
            value: 'espresso-bar',
            text: 'Espresso Bar'
        },
        newRecipeObject: {
            content: null,
            name: ''
        },
        newRecepie: false,
        store: {},
        globalStore: {
            franchisees: [],
            managers: [],
            store: {
                title: 'Global'
            }
        },
        changeStore: false,
        stores: [],
        storesOptions: [],
        openModal: false,
        renderStoreInfos: false,
        tabletSize: window.innerWidth <= 768, // for the modal position
        mobileSize: window.innerWidth <= 360, // for the popup position
        isGlobalStore: true
    };

    toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Italic', style: 'ITALIC' },
            { label: 'Underline', style: 'UNDERLINE' },
            { label: 'Strikethrough', style: 'STRIKETHROUGH' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' }
        ]
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        redirectToForbiddenIfUnauthorized('recipes-view');
        this.setState({ renderStoreInfos: false });
        this.getStoresData();
        this.setState({ renderRecipes: false }, () => {
            let newObject = this.state.newRecipeObject;
            newObject.content = this.getRichTextValueParsed('');
            this.setState({ newRecipeObject: newObject })
        });
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({ mobileSize: window.innerWidth <= 360, tabletSize: window.innerWidth <= 768 }, () => {
            if (!window.innerWidth <= 768) {
                this.setState({ openModal: false });
            }
        });
    }

    // FILTERS

    getStoresData = () => {
        new Promise((resolve, reject) => {
            let path = '/stores-managers';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let stores = JSON.parse(response);
                if (stores && stores.success === true && stores.data) {
                    let store = stores.data[0];
                    if(isCorporate()) { 
                        store = this.state.globalStore ; 
                    }
                    this.setState({ stores: stores.data, store: store }, () => {
                        this.transformStoresToOptions();
                        this.setState({ renderStoreInfos: true }, () => {
                            this.getRecipesData();
                          });
                    });
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
            })
            .catch(error => {
                this.setState({ stores: [] });
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
                this.setState({ renderStoreInfos: true });
            });
    };

    transformStoresToOptions = () => {
        let stores = this.state.stores;
        let options = [];
        if(isCorporate()) {
            options = [
                {
                    id: 99999,
                    key: 99999,
                    value: 99999,
                    text: 'Global'
                }
            ];
        }
        stores.forEach((store, index) => {
            let tempOption = {
                id: store.store.id,
                key: store.store.id,
                value: store.store.id,
                text: store.store.title
            };
            options.push(tempOption);
        });
        this.setState({ storesOptions: options })
    };

    handleStoresInfoButton = () => {
        if (this.state.tabletSize) {
            this.setState({ openModal: true })
        } else {
            this.setState({ openModal: false });
        }
    };

    handleRedirectToProfile = (username) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/profile/' + username;
    };

    getStoresPopupInfos = () => {
        return (
            <Grid className="store-info-popup-content" columns={2} stackable>
                <Grid.Row>
                    {this.state.store && this.state.store.managers ?
                        <Grid.Column width={8} className="store-info-managers">
                            {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                <div>
                                    <h5>Managers</h5>
                                    {this.state.store.managers && this.state.store.managers.length > 0 ?
                                        <ol>
                                            {this.state.store.managers.map((manager, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + manager.username}>
                                                            {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                        </a>
                                                        <p>{manager.phone}</p>
                                                        <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                            None
                                        </span>
                                    }
                                    <h5>Franchisees</h5>
                                    {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                        <ol>
                                            {this.state.store.franchisees.map((franchise, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + franchise.username}>
                                                            {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                        </a>
                                                        <p>{franchise.phone}</p>
                                                        <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                            None
                                        </span>
                                    }
                                </div>
                                : ''
                            }
                        </Grid.Column> : ''
                    }
                    <Grid.Column width={this.state.store && this.state.store.managers ? 8 : 16}>
                        <h5>{this.state.store.store.title || ''}</h5>
                        <p>{this.state.store.store.street1 || ''}</p>
                        <p>{this.state.store.store.street2 || ''}</p>
                        <h5>Phone</h5>
                        <p>{this.state.store.store.phone || ''}</p>
                        <h5>Email</h5>
                        {this.state.store.store.email ? <a href={'mailto:' + this.state.store.store.email} target="_top">{this.state.store.store.email}</a> : ''}
                        {this.state.store.store.email2 ? <a href={'mailto:' + this.state.store.store.email2} target="_top">{this.state.store.store.email2}</a> : ''}
                        <h5>Fax</h5>
                        <p>{this.state.store.store.fax || ''}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    handleEditStore = (data) => {
        this.setState({ changeStore: true })
    };

    handleChangeStore = (event, data) => {
        const foundStore = this.state.stores.find(element => element.store.id === parseInt(data.value));
        if (foundStore) {
            this.setState({ changeStore: false, store: foundStore, isGlobalStore: false }, () => {
                this.getRecipesData();
            });
        }
        else if (parseInt(data.value) === 99999) {
            this.setState({ changeStore: false, store: this.state.globalStore, isGlobalStore: true }, () => {
                this.getRecipesData();
            });
        }
    };

    handleCloseStoresModal = () => {
        this.setState({ openModal: false });
    };

    getStoresModalInfos = () => {
        return (
            <Modal className="stores-info-modal" open={this.state.openModal} onClose={this.handleCloseStoresModal} size="mini">
                <Modal.Header>Store Information</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.state.openModal ?
                            <div>
                                <div className="modal-stores-info">
                                    <h5>{this.state.store.store.title || ''}</h5>
                                    <p>{this.state.store.store.street1 || ''}</p>
                                    <p>{this.state.store.store.street2 || ''}</p>
                                    <h5>Phone</h5>
                                    <p>{this.state.store.store.phone || ''}</p>
                                    <h5>Email</h5>
                                    {
                                        this.state.store.store.email ?
                                            <a href={'mailto:' + this.state.store.store.email} target="_top">
                                                {this.state.store.store.email}
                                            </a> : ''
                                    }
                                    {this.state.store.store.email2 ?
                                        <a href={'mailto:' + this.state.store.store.email2} target="_top">
                                            {this.state.store.store.email2}
                                        </a> : ''
                                    }
                                    <h5>Fax</h5>
                                    <p>{this.state.store.store.fax || ''}</p>
                                </div><br />
                                {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                    <div className="modal-stores-info">
                                        <h5>Managers</h5>
                                        {this.state.store.managers && this.state.store.managers.length > 0 ?
                                            <ol>
                                                {this.state.store.managers.map((manager, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + manager.username} onClick={() => this.handleRedirectToProfile(manager.username)}>
                                                                {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                            </a>
                                                            <p>{manager.phone}</p>
                                                            <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                        <h5>Franchisees</h5>
                                        {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                            <ol>
                                                {this.state.store.franchisees.map((franchise, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + franchise.username} onClick={() => this.handleRedirectToProfile(franchise.username)}>
                                                                {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                            </a>
                                                            <p>{franchise.phone}</p>
                                                            <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                    </div>
                                    : ''

                                }
                            </div>
                            : ''}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleCloseStoresModal} inverted>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    // FILTERS END

    getRecipesData = () => {
        new Promise((resolve, reject) => {
            let store = this.state.store.store.id ? '?store_id=' + this.state.store.store.id : ''
            let path = '/recipes/' + this.state.selectedRecipe.value + store;
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let recipes = JSON.parse(response);
                if (recipes && recipes.success === true && recipes.data) {
                    let temp = this.initialOrder(recipes.data);
                    this.setState({ recipes: temp }, () => {
                        this.setState({ renderRecipes: true });
                    })
                }
                else {
                    this.setState({ renderRecipes: true });
                }
            })
            .catch(error => {
                this.setState({ recipes: [] }, () => {
                    this.setState({ renderRecipes: true });
                });
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Recipes', 'Error');
                }
            });
    };

    removeRecipe = (id) => {
        this.setState({ modalOpen: false });
        new Promise((resolve, reject) => {
            let path = '/recipes/' + id;
            this.request.delete(path, resolve, reject);
        })
            .then(response => {
                response = JSON.parse(response);
                if (response && response.success === true) {
                    this.setState({ renderRecipes: false });
                    this.getRecipesData();
                }
                else {
                    NotificationManager.error('Could not delete Recipe', 'Error');
                    this.setState({ renderRecipes: true });
                }
            })
            .catch(error => {
                this.setState({ renderRecipes: true });
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not delete Recipe', 'Error');
                }
            });
    };

    saveNewRecepie = () => {
        if (this.state.newRecipeObject.name) {
            new Promise((resolve, reject) => {
                let path = '/recipes';
                let params = {
                    name: this.state.newRecipeObject.name.trim(),
                    content: this.state.newRecipeObject.content.toString('html'),
                    type: this.state.selectedRecipe.value,
                    store_id: this.state.store.store.id ? this.state.store.store.id : null
                };
                this.request.post(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully saved new Recipe', 'Success');
                    this.setState({ renderRecipes: false, newRecepie: false }, () => {
                        this.getRecipesData();
                        this.resetFields();
                    });
                })
                .catch(error => {
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not create new Recipe', 'Error');
                    }
                });
        }
    };

    resetFields = () => {
        this.setState({
            newRecipeObject: {
                content: this.getRichTextValueParsed(''),
                name: ''
            }
        });
    };

    handleChangeRecipe = (event, data) => {
        let options = this.state.options;

        const foundStore = options.find(element => element.value === data.value);

        if (foundStore) {
            this.setState({ selectedRecipe: foundStore }, () => {
                this.setState({ renderRecipes: false });
                this.getRecipesData();
            });
        }
    };

    removeRecipesConfirmation = (id) => {
        this.setState({ removeId: id, modalOpen: true });
    };

    handleCloseModal = () => {
        this.setState({
            removeId: null,
            modalOpen: false
        });
    };

    getConfirmationModal = () => {
        let removeId = this.state.removeId;

        if (removeId || removeId === 0) {
            return (
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleCloseModal}
                    size='small'
                >
                    <Header content='Delete Recipe' />
                    <Modal.Content>
                        <h3>Are you sure you want to delete this Recipe?</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleCloseModal} inverted>
                            No
                        </Button>
                        <Button color='green' onClick={() => this.removeRecipe(removeId)} inverted>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            )
        }
        return '';
    };

    redirectToEditPage = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/recipes/' + id;
    };

    initialOrder = (recipes) => {
        return recipes.sort((recipe1, recipe2) => {
            return recipe1.order > recipe2.order ? 1 : -1;
        })
    };

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        let destinationIndex = result.destination.index;
        let sourceIndex = result.source.index;
        let recipe = this.state.recipes[sourceIndex];
        const items = this.reorder(
            this.state.recipes,
            sourceIndex,
            destinationIndex
        );
        this.setState({
            recipes: items
        });

        new Promise((resolve, reject) => {
            let path = '/recipes/order/' + this.state.selectedRecipe.value;
            let params = {
                id: recipe.id,
                newIndex: destinationIndex + 1
            };
            this.request.put(path, params, resolve, reject);
        })
            .then(response => {
                NotificationManager.success('Successfully changed order of Recipes', 'Success');
            })
            .catch(error => {
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not change order of Recipes', 'Error');
                }
            });


    };

    showNewRecepiesFields = () => {
        this.setState({ newRecepie: true })
    };

    getRichTextValueParsed = (value) => {
        return value ? RichTextEditor.createValueFromString(value, 'html') : RichTextEditor.createEmptyValue()
    };

    handleRecipeNewContentChange = (value) => {
        let recipe = this.state.newRecipeObject;

        recipe.content = value;
        this.setState({
            newRecipeObject: recipe
        });
    };

    handleChangeNewName = (event) => {
        if (event.target.value.trim() !== '') {
            let recipe = this.state.newRecipeObject;
            recipe.name = event.target.value;
            this.setState({ newRecipeObject: recipe });
        }
    };

    getSchematicsContent = () => {
        return (<div>
            {this.state.renderRecipes && this.state.recipes && Array.isArray(this.state.recipes) ?
                this.state.recipes.length > 0 ?
                    <List divided verticalAlign='middle' relaxed>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable-recipes-admin">
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                        {this.state.recipes.map((recipe, index) => (
                                            <Draggable key={recipe.id} draggableId={recipe.id.toString()} index={index}>
                                                {(provided, snapshot) => (
                                                    <div className="recipes-admin-dragdrop-divs"
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <List.Item className="recipes-admin-list-item">
                                                            <Grid className="recipes-admin-grid" stackable>
                                                                <Grid.Row className="recipes-admin-grid-rows" columns={2}>
                                                                    <Grid.Column className="recipes-admin-grid-columns" width={8}>
                                                                        {recipe.name}
                                                                    </Grid.Column>
                                                                    <Grid.Column className="recipes-admin-grid-action-columns" width={8}>
                                                                        {isPermitted('recipes-edit') &&
                                                                            <Button color="orange" onClick={() => this.redirectToEditPage(recipe.id)}>Edit</Button>
                                                                        }
                                                                        {isPermitted('recipes-delete') &&
                                                                            <Button color="orange" onClick={() => this.removeRecipesConfirmation(recipe.id)}>Remove</Button>
                                                                        }
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            </Grid>
                                                        </List.Item>
                                                    </div>

                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </List>
                    : <p>No recipes found</p>
                : ''}
            <div>
                {this.state.newRecepie ?
                    <Grid className="recipes-admin-grid" stackable>
                        <Grid.Row className="recipes-admin-grid-rows" columns={2}>
                            <Grid.Column width={8}>
                                <div >
                                    <h5>Recipe:</h5>
                                    <RichTextEditor
                                        editorClassName="textEditor"
                                        value={this.state.newRecipeObject.content}
                                        onChange={this.handleRecipeNewContentChange}
                                        toolbarConfig={this.toolbarConfig}
                                    />
                                </div>
                            </Grid.Column>
                            <Grid.Column className="recipes-admin-grid-columns" width={8}>
                                <Form>
                                    <Form.Field>
                                        <Label className="schematics-edit-name-label" pointing='below'>Name</Label>
                                        <input
                                            className="recipes-edit-name-input"
                                            type='text'
                                            value={this.state.newRecipeObject.name}
                                            placeholder="Enter name"
                                            onChange={this.handleChangeNewName}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="recipes-admin-grid-action-save-new-rows">
                            <Grid.Column className="recipes-admin-grid-action-save-new-columns" width={8}>
                                <Button onClick={this.saveNewRecepie} color="orange">Save</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    : ''
                }
                <div className="add-new-recipe">
                    { isPermitted('recipes-create') &&
                        <Button icon labelPosition='right' onClick={this.showNewRecepiesFields} color="orange">
                        Add Recipe
                        <Icon name='plus' />
                    </Button>
                    }                                                    
                    
                </div>

            </div>
        </div>
        );
    };

    getNewOrdersFilters = () => {
        return (
            <div className="recipes-admin-filters">
                <Grid stackable>
                    <Grid.Row columns={3}>
                        <Grid.Column width={5}>
                            <div className="recipes-admin-stores">
                                <div className="recipes-admin-content">
                                    <div className="store-number">
                                        <Select
                                            text={this.state.store.store.title}
                                            value={this.state.store.store.id}
                                            options={this.state.storesOptions}
                                            onChange={this.handleChangeStore} />
                                    </div>
                                </div>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <div className="recipes-admin-types-select">
                                <Select
                                    text={this.state.selectedRecipe.text}
                                    value={this.state.selectedRecipe.value}
                                    options={this.state.options}
                                    onChange={this.handleChangeRecipe} />
                            </div>
                        </Grid.Column>
                        <Grid.Column width={5}>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    };

    render() {
        return (
            <div className="recipes-admin-content">
                {this.getStoresModalInfos()}
                {this.state.renderStoreInfos ? this.getNewOrdersFilters() : ''}
                <Card className="recipes-admin-card" fluid>
                    <Card.Content className="recipes-admin-card-header" header="Recipes" />
                    <Card.Content description={this.getSchematicsContent()} />
                </Card>
                {this.getConfirmationModal()}
                <NotificationContainer />
            </div>
        );
    }
}

export default Recipes;
