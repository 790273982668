import Cookies from "universal-cookie";

export function useRolePermissions() {
  /**
   * Check if the given permission is permitted based on the decoded cookies.
   *
   * @param {string} permission - The permission to check
   * @return {boolean} Whether the permission is permitted
   */
  const isPermitted = (permission, storeId = null) => {
    try {
      const permissions = getPermissions();
      // Bypass for admin[ROLE_ADMIN is hardcoded and not editable from backend]
      if (
        permissions.ROLE_ADMIN !== undefined ||
        "allow-for-all" === permission
      ) {
        return true;
      }

      for (const role in permissions) {
        if (
          !permissions[role].permissions ||
          !permissions[role].permissions.includes(permission)
        ) {
          continue;
        }

        if (permissions[role].requires_store == 0 || !storeId) {
          return true;
        }

        if (storeId && permissions[role].store_ids.includes(storeId)) {
          return true;
        }
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const isPermittedAmong = (permissions, storeId = null) => {
    if (permissions.length === 0) {
      return false;
    }

    let permissionStatus = false;
    permissions.forEach((permission) => {
      if (isPermitted(permission, storeId)) {
        permissionStatus = true;
        return;
      }
    });

    return permissionStatus;
  };

  const redirectToForbiddenIfUnauthorized = (permissions) => {
    
    let isPermittedToView = false;

    if(Array.isArray(permissions)) {
      permissions.forEach((permission) => {
        if (isPermitted(permission)) {
          isPermittedToView = true;
        }
      });
    }

    if(isPermittedToView) {
      return;
    }

    if (!isPermitted(permissions)) {
      let url = process.env.REACT_APP_UI_URL;
      window.location.href = url + "/forbidden";
    }
  };

  const getPermissions = () => {
    let cookies = new Cookies();
    let decodedCookie = cookies.getAll();

    if (!decodedCookie["management-token"]) {
      return decodedCookie.permissions || [];
    }

    let mergedPermissions = [
      ...Object.values(decodedCookie.permissions),
      ...Object.values(decodedCookie["management-permissions"]),
    ];

    // merge permissions with management permissions
    // let mergedPermissions = [
    //   ...decodedCookie.permissions,
    //   ...decodedCookie["management-permissions"],
    // ];

    return mergedPermissions;
  };

  /**
   * Check if the user has corporate level access.
   *
   * @return {boolean} Whether the permission is permitted
   */
  const isCorporate = () => {
    const permissions = getPermissions();
    if (typeof permissions !== 'object' || permissions === null) {
      console.error("Expected permissions to be an object", permissions);
      return false;
    }
  
    for (const role in permissions) {
      if (permissions[role].requires_store == 0) {
        return true;
      }
    }

    return false;
  };

  return {
    isPermitted,
    getPermissions,
    isPermittedAmong,
    redirectToForbiddenIfUnauthorized,
    isCorporate,
  };
}
