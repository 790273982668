import React from 'react';
import './../../assets/css/pages/admin/schematics-admin.css';
import { NotificationContainer, NotificationManager } from "react-notifications";
import { Grid, Button, Card, List, Modal, Header, Icon, Form } from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Request from "../../Request";
import { useRolePermissions } from './../../hooks/useRolePermissions';

const { redirectToForbiddenIfUnauthorized, isPermitted } = useRolePermissions();

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: 8
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 8 * 2,
    margin: `0`,

    // change background colour if dragging
    background: isDragging ? "lightgray" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
});

class Schematics extends React.Component {
    state = {
        schematics: [],
        renderSchematics: false,
        modalOpen: false,
        removeId: null,
        dragAndDropData: [],
        newSchematic: false,
        newSchematicName: ''
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        redirectToForbiddenIfUnauthorized('schematics-view');
        this.setState({ renderSchematics: false });
        this.getSchematicsData();
    }

    getSchematicsData = () => {
        new Promise((resolve, reject) => {
            let path = '/schematics';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let schematics = JSON.parse(response);
                if (schematics && schematics.success === true && schematics.data) {
                    let temp = this.initialOrder(schematics.data);
                    this.setState({ schematics: temp }, () => {
                        this.setState({ renderSchematics: true });
                    })
                }
                else {
                    this.setState({ renderSchematics: true });
                }
            })
            .catch(error => {
                this.setState({ schematics: [] }, () => {
                    this.setState({ renderSchematics: true });
                });
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Schematics', 'Error');
                }
            });
    };

    removeSchematic = (id) => {
        this.setState({ modalOpen: false });
        new Promise((resolve, reject) => {
            let path = '/schematics/' + id;
            this.request.delete(path, resolve, reject);
        })
            .then(response => {
                response = JSON.parse(response);
                if (response && response.success === true) {
                    this.setState({ renderSchematics: false });
                    this.getSchematicsData();
                }
                else {
                    NotificationManager.error('Could not delete Schema', 'Error');
                    this.setState({ renderSchematics: true });
                }
            })
            .catch(error => {
                this.setState({ renderSchematics: true });
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not delete Schema', 'Error');
                }
            });
    };

    onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        let destinationIndex = result.destination.index;
        let sourceIndex = result.source.index;
        let schematic = this.state.schematics[sourceIndex];
        const items = this.reorder(
            this.state.schematics,
            sourceIndex,
            destinationIndex
        );
        this.setState({
            schematics: items
        });

        new Promise((resolve, reject) => {
            let path = '/schematics/order';
            let params = {
                id: schematic.id,
                newIndex: destinationIndex + 1
            };
            this.request.put(path, params, resolve, reject);
        })
            .then(response => {
                NotificationManager.success('Successfully changed order of Schematics', 'Success');
            })
            .catch(error => {
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not change order of Schematics', 'Error');
                }
            });


    };

    saveNewSchematic = () => {
        if (this.state.newSchematicName) {
            new Promise((resolve, reject) => {
                let path = '/schematics';
                let params = {
                    name: this.state.newSchematicName.trim()
                };
                this.request.post(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully saved new Schematic', 'Success');
                    this.setState({ renderSchematics: false, newSchematic: false }, () => {
                        this.getSchematicsData();
                    });
                })
                .catch(error => {
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not create new Schematic', 'Error');
                    }
                });
        }
    };

    removeSchematicConfirmation = (id) => {
        this.setState({ removeId: id, modalOpen: true });
    };

    handleCloseModal = () => {
        this.setState({
            removeId: null,
            modalOpen: false
        });
    };

    getConfirmationModal = () => {
        let removeId = this.state.removeId;

        if (removeId || removeId === 0) {
            return (
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleCloseModal}
                    size='small'
                >
                    <Header content='Delete Schema' />
                    <Modal.Content>
                        <h3>Are you sure you want to delete this Schema?</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleCloseModal} inverted>
                            No
                        </Button>
                        <Button color='green' onClick={() => this.removeSchematic(removeId)} inverted>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            )
        }
        return '';
    };

    redirectToEditPage = (id) => {
        let url = process.env.REACT_APP_UI_URL; //TODO change on dev url
        window.location.href = url + '/admin/schematics/' + id;
    };

    initialOrder = (schematic) => {
        return schematic.sort((schema1, schema2) => {
            return schema1.order > schema2.order ? 1 : -1;
        })
    };

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    showNewSchematicFields = () => {
        this.setState({ newSchematic: true })
    };

    handleChangeNewName = (event) => {
        if (event.target.value.trim() !== '') {
            this.setState({ newSchematicName: event.target.value });
        }
    };

    getSchematicsContent = () => {
        return (<div>
            {this.state.renderSchematics && this.state.schematics && Array.isArray(this.state.schematics) ?
                <List divided verticalAlign='middle' relaxed>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable-schematics-admin">
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                    {this.state.schematics.map((schema, index) => (
                                        <Draggable key={schema.id} draggableId={schema.id.toString()} index={index}>
                                            {(provided, snapshot) => (
                                                <div className="schematics-admin-dragdrop-divs"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <List.Item className="schematics-admin-list-item">
                                                        <Grid className="schematics-admin-grid" stackable>
                                                            <Grid.Row className="schematics-admin-grid-rows" columns={2}>
                                                                <Grid.Column className="schematics-admin-grid-columns" width={8}>
                                                                    {schema.name}
                                                                </Grid.Column>
                                                                <Grid.Column className="schematics-admin-grid-action-columns" width={8}>
                                                                    {isPermitted('schematics-edit') &&
                                                                        <Button color="orange" onClick={() => this.redirectToEditPage(schema.id)}>Edit</Button>
                                                                    }
                                                                    {isPermitted('schematics-delete') &&
                                                                        <Button color="orange" onClick={() => this.removeSchematicConfirmation(schema.id)}>Remove</Button>
                                                                    }
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </List.Item>
                                                </div>

                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </List>
                : ''}
            <div>
                {this.state.newSchematic ?
                    <Grid className="schematics-admin-grid" stackable>
                        <Grid.Row className="schematics-admin-grid-rows" columns={2}>
                            <Grid.Column className="schematics-admin-grid-columns" width={8}>
                                <Form>
                                    <Form.Field>
                                        <input
                                            className="schematics-edit-name-input"
                                            type='text'
                                            value={this.state.newSchematicName}
                                            placeholder="Enter name"
                                            onChange={this.handleChangeNewName}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                            <Grid.Column className="schematics-admin-grid-action-columns schematics-admin-grid-action-save-new-columns" width={8}>
                                <Button color="orange" onClick={this.saveNewSchematic}>Save</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    : ''
                }
                <Button color="orange" icon labelPosition='right' onClick={this.showNewSchematicFields}>
                    Add Schematic
                    <Icon name='plus' />
                </Button>
            </div>
        </div>
        );
    };

    render() {
        return (
            <div className="schematics-admin-content">
                <Card className="schematics-admin-card" fluid>
                    <Card.Content className="schematics-admin-card-header" header="Schematics" />
                    <Card.Content description={this.getSchematicsContent()} />
                </Card>
                {this.getConfirmationModal()}
                <NotificationContainer />
            </div>
        );
    }
}

export default Schematics;
