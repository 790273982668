import React from 'react';
import {
    Button, TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Icon, Table, FormGroup,FormField,
    Form, FormInput, AccordionTitle, FormTextArea, Radio, Select,
    AccordionContent,
    Accordion,
} from "semantic-ui-react";
import './../../assets/css/components/table.css';
import './../../assets/css/components/form.css';
import './../../assets/css/pages/managment/retail-score-sheet.css';
import { NotificationContainer, NotificationManager } from "react-notifications";
import Request from "../../Request";
import Title from 'components/Title';
import StoreSelection from 'components/StoreSelection';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from 'moment';
import NotFound from 'pages/NotFound';

class RetailScoreSheetEdit extends React.Component {
  state = {
    customSize: window.innerWidth <= 1150,
    activeIndex: 0,
    evaluatedScore: 0,
    totalScore: 0,
    highestScore: 0,
    totalPercentage: 0,
    itemScores: [],
    retailScoreCategories: [],
    formData: {
      additional_notes: "",
      manager_id: null,
      score_date: new Date(),
      store_id: null,
    },
    loading: false,
    notFound: false,
    validationErrors: {},
  };

  constructor() {
    super();
    this.request = new Request();
  }

  async componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.getRetailScoreCategories();
    await this.fetchRetailScoreSheet();
    this.getManagersOfSelectedStore();
  }

  resize() {
    this.setState({ customSize: window.innerWidth <= 1150 });
  }

  getRetailScoreCategories = () => {
    new Promise((resolve, reject) => {
      let path = "/retail-score-categories?include=retail_score_category_items&type='evaluation_report'";
      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let retailScoreCategoryResponse = JSON.parse(response);

        if (true === retailScoreCategoryResponse?.success) {
          this.setState(
            { retailScoreCategories: retailScoreCategoryResponse.data },
            () => {
              this.setItemScores();
            }
          );
        } else {
          NotificationManager.error("Could not get categories", "Error");
        }
      })
      .catch((error) => {
        this.setState({ stores: [] });

        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get categories", "Error");
        }
      });
  };

  fetchRetailScoreSheet = async() => {
    await new Promise((resolve, reject) => {
      let path = "/retail-scores/" + this.props.match.params.id;
      this.request.get(path, resolve, reject);
    })
      .then(async(response) => {
        let retailScoreSheetResponse = JSON.parse(response);
        if (null === retailScoreSheetResponse.data) {
          this.setState({ notFound: true });

          return false;
        }

        let formData = this.state.formData;
        formData.score_date = moment(
          retailScoreSheetResponse.data.score_date
        ).toDate();
        formData.store_id = retailScoreSheetResponse.data.store.id;
        formData.manager_id = retailScoreSheetResponse.data.manager?.id;
        formData.additional_notes =
          retailScoreSheetResponse.data.additional_notes;

        let evaluatedScore = 0;

        let itemScores = this.state.itemScores;
        retailScoreSheetResponse.data.retail_item_score.forEach(
          (retailScoreSheetItem) => {
            itemScores.forEach((itemScore) => {
              if (
                itemScore.item_id ===
                retailScoreSheetItem.retail_score_category_item.id
              ) {
                itemScore.score = retailScoreSheetItem.total_score;
                evaluatedScore += retailScoreSheetItem.total_score;
              }
            });
          }
        );

        let totalPercentage = Number(
          ((evaluatedScore / this.state.totalScore) * 100).toFixed(2)
        );
        totalPercentage = isNaN(totalPercentage) ? 0 : totalPercentage;
        this.setState({
          evaluatedScore,
          formData,
          itemScores,
          totalPercentage,
        });
      })
      .catch((error) => {
        this.setState({ notFound: true });

        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get categories", "Error");
        }
      });
  };

  setItemScores = () => {
    let retailScoreCategories = this.state.retailScoreCategories;
    let itemScores = [];
    let totalScore = 0;
    let highestScore = 0;

    if (retailScoreCategories.length > 0) {
      retailScoreCategories.forEach((retailScoreCategory) => {
        if (retailScoreCategory.retail_score_category_items?.length > 0) {
          retailScoreCategory.retail_score_category_items.forEach(
            (retailScoreCategoryItem) => {
              totalScore += retailScoreCategoryItem.permissible_score;
              highestScore =
                retailScoreCategoryItem.permissible_score > highestScore
                  ? retailScoreCategoryItem.permissible_score
                  : highestScore;
              itemScores.push({
                item_id: retailScoreCategoryItem.id,
                score: 0,
              });
            }
          );
        }
      });
    }

    // Set itemScores in the state
    this.setState({ itemScores });

    // Set highestScore in the state
    this.setState({ highestScore });

    // Set totalScore in the state
    this.setState({ totalScore });
  };

  handleStoreChange = (selectedOption) => {
    let formData = this.state.formData;
    formData.store_id = selectedOption.value;
    formData.manager_id = null; 
    this.setState({ formData });
    this.getManagersOfSelectedStore();
  };

  getManagersOfSelectedStore = () => {
    let selectedStoreId = this.state.formData.store_id;
    new Promise((resolve, reject) => {
      let path = "/managers-of-store/" + selectedStoreId;
      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let managerResponse = JSON.parse(response);
        if (true === managerResponse?.success) {
          this.setState({ managers: managerResponse.data }, () => {
            this.transformManagersToOptions();
          });
        } else {
          NotificationManager.error("Could not get managers", "Error");
        }
      })
      .catch((error) => {
        this.setState({ managers: [] });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get managers", "Error");
        }
      });
  };

  transformManagersToOptions = () => {
    let managers = this.state.managers;
    let options = [];
    managers.forEach((manager, index) => {
      let tempOption = {
        id: manager.id,
        key: manager.id,
        value: manager.id,
        text: manager.name,
      };
      options.push(tempOption);
    });
    this.setState({ managerOptions: options });
  };


  onChangeDate = (event, data) => {
    let formData = this.state.formData;
    formData.score_date = new Date(data.value);
    this.setState({ formData });
  };

  isChecked = (itemId, score) => {
    if (this.state.itemScores.length === 0) {
      return false;
    }

    return (
      this.state.itemScores.find((itemScore) => itemScore.item_id === itemId)
        .score === score
    );
  };

  // Accordian
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  // Radio
  handleChange = (itemId, e, { value }) => {
    let itemScores = this.state.itemScores;
    let evaluatedScore = 0;

    itemScores.forEach((itemScore) => {
      if (itemScore.item_id === itemId) {
        itemScore.score = Number(value);
        evaluatedScore += itemScore.score;

        return false;
      }

      evaluatedScore += itemScore.score;
    });

    let totalPercentage = Number(
      ((evaluatedScore / this.state.totalScore) * 100).toFixed(2)
    );
    totalPercentage = isNaN(totalPercentage) ? 0 : totalPercentage;
    this.setState({ itemScores });
    this.setState({ evaluatedScore });
    this.setState({ totalPercentage });
  };

  handleInputChanged = (event, field) => {
    let formData = this.state.formData;
    formData[field] = event.target.value;
    this.setState({ formData });
  };

  handleSelectChanged = (event, { value }, field) => {
    let formData = this.state.formData;
    formData[field] = value;
    this.setState({ formData });
  };

  countScoresForPoint = (point) => {
    return this.state.itemScores.filter(
      (itemScore) => itemScore.score === point
    ).length;
  };

  submitRetailScore = () => {
    this.setState({ loading: true });

    let itemScores = {
      scores: this.state.itemScores,
    };
    let payload = { ...this.state.formData, ...itemScores };
    payload.score_date = moment(payload.score_date).format("YYYY-MM-DD");

    new Promise((resolve, reject) => {
      let path = `/retail-scores/${this.props.match.params.id}`;
      this.request.throwValidationErrors = true;
      this.request.put(path, payload, resolve, reject);
    })
      .then((response) => {

        NotificationManager.success(
          "Successfully saved new Retail Score Sheet",
          "Success"
        );
        this.setState({ validationErrors: {} });
        setTimeout(() => {
            this.redirectToListPage();
          }, 1000); 
        
      })
      .catch((error) => {
        if (error.statusCode === 422) {
          NotificationManager.error(error?.errorObject?.message, "Error");
          this.setState({ validationErrors: error?.errorObject?.errors });
        } else if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error(
            "Could not create new Retail Score Sheet",
            "Error"
          );
        }
      })
      .then(() => {
        this.setState({ loading: false });
      });
  };

  redirectBack = () => {
    let url = process.env.REACT_APP_UI_URL;
    window.location.href = url + "/retail-score-sheets";
  };

  redirectToListPage = () => {
    let url = process.env.REACT_APP_UI_URL;
    window.location.href = url + "/retail-score-sheets";
  };

  render() {
    const { activeIndex } = this.state;
      
    if (this.state.notFound) {
      return <NotFound />;
    }

    return (
     <div className="users-admin-root">
        <Title paramsPageTitle="EVALUATION REPORT" />
        <div className="common-form">
          <Form>
            <FormGroup widths="equal">
              <StoreSelection
                storeId={this.state.formData.store_id}
                permission="retail-score-sheet-edit"
                onStoreChange={this.handleStoreChange}
              />

              <SemanticDatepicker
                datePickerOnly={true}
                placeholder="YYYY-MM-DD"
                maxDate={null}
                clearable={false}
                label="Score Date:"
                value={this.state.formData.score_date}
                format="MMMM D, YYYY"
                onChange={(event, data) => this.onChangeDate(event, data)}
              />
            </FormGroup>

            <Accordion fluid styled>
              {this.state.retailScoreCategories.map(
                (retailScoreCategory, index) => (
                  <div key={index}>
                    <AccordionTitle
                      active={activeIndex === index}
                      index={index}
                      onClick={this.handleClick}
                    >
                      <Icon name="dropdown" />
                      {retailScoreCategory.name}
                    </AccordionTitle>
                    <AccordionContent active={activeIndex === index}>
                      <Table celled>
                        <TableHeader>
                          <TableRow>
                            <TableHeaderCell>Items</TableHeaderCell>
                            <TableHeaderCell>
                              Meets company standard
                            </TableHeaderCell>
                          </TableRow>
                        </TableHeader>

                        <TableBody>
                          {retailScoreCategory?.retail_score_category_items?.map(
                            (categoryItem, index) => (
                              <TableRow key={categoryItem.id}>
                                <TableCell>{categoryItem.name}</TableCell>
                                <TableCell>
                                  {Array.from(
                                    {
                                      length:
                                        categoryItem.permissible_score + 1,
                                    },
                                    (_, index) => (
                                      <Radio
                                        key={index}
                                        label={index}
                                        value={index}
                                        checked={this.isChecked(
                                          categoryItem.id,
                                          index
                                        )}
                                        onChange={(e, { value }) =>
                                          this.handleChange(
                                            categoryItem.id,
                                            e,
                                            { value }
                                          )
                                        }
                                      />
                                    )
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </AccordionContent>
                  </div>
                )
              )}
            </Accordion>
            <br></br>
            <FormTextArea
              label="Additional notes"
              onChange={(e) => this.handleInputChanged(e, "additional_notes")}
              value={this.state.formData.additional_notes}
            />
            <div className="retail-sheet-footer">
              <div className="half-col">
                <FormGroup>
                  <FormField
                    required
                    width={6}
                    control={Select}
                    options={this.state.managerOptions}
                    label={{
                      children: "Manager Name:",
                      htmlFor: "form-select-control-employee",
                    }}
                    placeholder=" "
                    search
                    searchInput={{ id: "form-select-control-employee" }}
                    onChange={(e, data) =>
                      this.handleSelectChanged(e, data, "manager_id")
                    }
                    value={this.state.formData.manager_id}
                  />
                  <span className="retail-score-error">
                    {this.state.validationErrors.manager_id}
                  </span>
                </FormGroup>
                
              </div>
              <div className="points-docked-wrapper half-col">
                <h4>Points Docked</h4>
                <Table celled>
                  <TableBody>
                    {Array.from(
                      { length: this.state.highestScore },
                      (_, index) => {
                        let point = Number(index + 1);
                        let pointCount = this.countScoresForPoint(point);
                        return (
                          <TableRow key={point}>
                            <TableCell>
                              {point} pt: {pointCount} x {point}
                            </TableCell>
                            <TableCell>{pointCount * point}</TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
                <h5>
                  Total Docked: <span>{this.state.evaluatedScore}</span>
                </h5>
                <h5>
                  Total Possible Points: <span>{this.state.totalScore}</span>
                </h5>
                <h5>
                  Less Total Docked:{" "}
                  <span>
                    {this.state.totalScore - this.state.evaluatedScore}{" "}
                  </span>
                </h5>
                <h3>
                  Total score:{" "}
                  <span>
                    {this.state.evaluatedScore} ({isNaN(this.state.totalPercentage) ? 0 : this.state.totalPercentage}%)
                  </span>
                </h3>
              </div>
            </div>
          </Form>
        </div>
        <div className='common-form'>
        <Button
                  className="orange"
                  onClick={() => this.submitRetailScore()}
                >
                  {" "}
                  Submit
                </Button>
                <Button className="orange" onClick={() => this.redirectBack()}>
                  {" "}
                  Back
                </Button>
        </div>
        <NotificationContainer />
      </div>
    );
  }
}

export default RetailScoreSheetEdit;