import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  Nav as CheckListsNav,
  CommSheet,
  DailyChecklist,
  CashCountSheet,
  WasteLog,
} from "pages/check-lists/CheckLists";
import {
  Nav as ManagementNav,
  NewOrders,
  OrderHistory,
  ServiceTicket,
  HrDocuments,
  KeyRelease,
  AddressBook,
  Checklists,
} from "pages/management/Management";
import {
  Nav as AdminNav,
  Users,
  Stores,
  Vendors,
  Documents,
  Schematics as SchematicsAdm,
  Recipes,
  Products,
  Categories,
} from "pages/admin/Admin";
import RecipesNav from "pages/recipes/RecipesNav";
import Schematics from "pages/Schematics";
import BatchRecipes from "pages/BatchRecipes";
import DrinkRecipes from "pages/DrinkRecipes";
import AllRecipes from "pages/recipes/AllRecipes";
import NotFound from "pages/NotFound";
import ForgotPasswordPage from "./../pages/ForgotPasswordPage";
import PasswordResetPage from "./../pages/PasswordResetPage";
import UserInvitePage from "./../pages/UserInvitePage";
import SchematicsEdit from "./../pages/admin/SchematicsEdit";
import DocumentsNewAndEdit from "./../pages/admin/DocumentsNewAndEdit";
import RecipesEdit from "./../pages/admin/RecipesEdit";
import AddressBookEdit from "../pages/management/AddressBookEdit";
import ChecklistsEdit from "../pages/management/ChecklistsEdit";
import UsersAdminView from "./../pages/admin/UsersAdminView";
import UsersAdminEdit from "./../pages/admin/UsersAdminEdit";
import VendorsAdminView from "./../pages/admin/VendorsAdminView";
import VendorsAdminEdit from "./../pages/admin/VendorsAdminEdit";
import StoresAdminView from "./../pages/admin/StoresAdminView";
import StoresAdminEdit from "./../pages/admin/StoresAdminEdit";
import ProductsEdit from "./../pages/admin/ProductsEdit";
import CategoriesEdit from "./../pages/admin/CategoriesEdit";
import Dashboard from "../pages/Dashboard";
import DashboardEdit from "../pages/admin/DashboardEdit";
import PromotionsEdit from "../pages/admin/PromotionsEdit";
import UsersProfile from "../pages/UsersProfile";
import Promotion from "../pages/Promotion";
import Login from "../pages/Login";
import ManagementLogin from "../pages/management/ManagementLogin.js";
import ManagementLogout from "../pages/management/ManagementLogout.js";
import UserAccess from "../pages/admin/UserAccess.js";
import RolesList from "../pages/admin/roles/index.js";
import RolesEdit from "../pages/admin/roles/edit.js";
import RetailScoreSheetList from "../pages/retail-score-sheet/Index.js";
import RetailScoreSheet from "../pages/retail-score-sheet/Create.js";
import RetailScoreSheetEdit from "../pages/retail-score-sheet/Edit.js";
import RetailScoreSheetView from "../pages/retail-score-sheet/View.js";
import RetailScoreCategoryIndex from "pages/retail-score-category/Index";
import RetailScoreCategoryCreate from "pages/retail-score-category/Create";
import RetailScoreCategoryEdit from "pages/retail-score-category/Edit";
import Logout from "../pages/Logout";
import Forbidden from "./../pages/Forbidden";
import IncidentReport from "../pages/management/IncidentReport.js";
import IncidentReportView from "../pages/management/IncidentReportView.js";
import IncidentReportEdit from "../pages/management/IncidentReportEdit.js";
import IncidentReportList from "../pages/management/IncidentReportList.js";

class Content extends React.Component {
  render() {
    return (
      <div className="content-main" id="content-main">
        <div className="content-inner">
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/login" exact component={Login} />
            <Route path="/logout" exact component={Logout} />
            <Route path="/promotion" exact component={Promotion} />
            <Route
              path="/forgot-password"
              exact
              component={ForgotPasswordPage}
            />
            <Route
              path="/reset-password/:code"
              exact
              component={PasswordResetPage}
            />
            <Route path="/user-invite/:code" exact component={UserInvitePage} />
            <Route path="/profile/:username" exact component={UsersProfile} />
            <Route
              path="/profile/:username/edit"
              exact
              component={UsersAdminEdit}
            />
            <Route path="/check-lists" exact component={CheckListsNav} />
            <Route path="/check-lists/comm-sheet" exact component={CommSheet} />
            <Route
              path="/check-lists/comm-sheet/:store/:date"
              exact
              component={CommSheet}
            />
            <Route
              path="/check-lists/daily-check"
              exact
              component={DailyChecklist}
            />
            <Route
              path="/check-lists/daily-check/:store/:date"
              exact
              component={DailyChecklist}
            />
            <Route
              path="/check-lists/cash-count"
              exact
              component={CashCountSheet}
            />
            <Route
              path="/check-lists/cash-count/:store/:date"
              exact
              component={CashCountSheet}
            />
            <Route path="/check-lists/wastelog" exact component={WasteLog} />
            <Route
              path="/check-lists/wastelog/:store/:date"
              exact
              component={WasteLog}
            />
            <Route path="/schematics" exact component={Schematics} />
            <Route path="/batch-recipes" exact component={BatchRecipes} />
            <Route path="/drink-recipes" exact component={DrinkRecipes} />
            <Route path="/recipes" exact component={RecipesNav} />
            <Route path="/recipes/:type" exact component={AllRecipes} />
            <Route path="/management" exact component={ManagementNav} />
            <Route path="/management/login" exact component={ManagementLogin} />
            <Route
              path="/management/logout"
              exact
              component={ManagementLogout}
            />
            <Route path="/management/new-orders" exact component={NewOrders} />
            <Route
              path="/management/order-history"
              exact
              component={OrderHistory}
            />
            <Route
              path="/management/service-ticket"
              exact
              component={ServiceTicket}
            />
            <Route
              path="/management/service-ticket/:store/:date"
              exact
              component={ServiceTicket}
            />
            <Route
              path="/management/hr-documents"
              exact
              component={HrDocuments}
            />
            <Route
              path="/management/key-release"
              exact
              component={KeyRelease}
            />
            <Route
              path="/management/address-book"
              exact
              component={AddressBook}
            />
            <Route
              path="/management/address-book/:id"
              exact
              component={AddressBookEdit}
            />
            <Route path="/management/checklists" exact component={Checklists} />
            <Route
              path="/management/checklists/:id/:type"
              exact
              component={ChecklistsEdit}
            />
            <Route path="/admin" exact component={AdminNav} />
            <Route path="/admin/users" exact component={Users} />
            <Route path="/admin/users/:id" exact component={UsersAdminView} />
            <Route
              path="/admin/users/edit/:id"
              exact
              component={UsersAdminEdit}
            />
            <Route path="/admin/stores" exact component={Stores} />
            <Route path="/admin/stores/:id" exact component={StoresAdminView} />
            <Route
              path="/admin/stores/edit/:id"
              exact
              component={StoresAdminEdit}
            />
            <Route path="/admin/vendors" exact component={Vendors} />
            <Route
              path="/admin/vendors/:id"
              exact
              component={VendorsAdminView}
            />
            <Route
              path="/admin/vendors/:id/edit"
              exact
              component={VendorsAdminEdit}
            />
            <Route path="/admin/documents" exact component={Documents} />
            <Route
              path="/admin/documents/:id"
              exact
              component={DocumentsNewAndEdit}
            />
            <Route path="/admin/schematics" exact component={SchematicsAdm} />
            <Route
              path="/admin/schematics/:id"
              exact
              component={SchematicsEdit}
            />
            <Route path="/admin/products" exact component={Products} />
            <Route path="/admin/products/:id" exact component={ProductsEdit} />
            <Route
              path="/admin/products/:id/:vendorId"
              exact
              component={ProductsEdit}
            />
            <Route path="/admin/recipes" exact component={Recipes} />
            <Route path="/admin/recipes/:id" exact component={RecipesEdit} />
            <Route path="/admin/categories" exact component={Categories} />
            <Route
              path="/admin/categories/:id"
              exact
              component={CategoriesEdit}
            />
            <Route path="/admin/dashboard" exact component={DashboardEdit} />
            <Route path="/admin/promotion" exact component={PromotionsEdit} />
            <Route path="/admin/roles" exact component={RolesList} />
            <Route path="/admin/roles/:id" exact component={RolesEdit} />
            <Route
              path="/permissions/user-access"
              exact
              component={UserAccess}
            />
            <Route
              path="/admin/permissions/user-access"
              exact
              component={UserAccess}
            />
            <Route path="/admin/user-access" exact component={UserAccess} />
            <Route path="/admin/roles" exact component={RolesList} />
            <Route path="/admin/roles/:id" exact component={RolesEdit} />
            <Route path="/forbidden" exact component={Forbidden} />
            <Route
              path="/management/incident-report"
              exact
              component={IncidentReport}
            />
            <Route
              path="/management/incident-report/:id/edit"
              exact
              component={IncidentReportEdit}
            />
            <Route
              path="/management/incident-report/:id"
              exact
              component={IncidentReportView}
            />
            <Route
              path="/management/incident-report-list"
              exact
              component={IncidentReportList}
            />
            <Route
              path="/retail-score-sheets"
              exact
              component={RetailScoreSheetList}
            />
            <Route
              path="/retail-score-sheets/create"
              exact
              component={RetailScoreSheet}
            />
            <Route
              path="/retail-score-sheets/:id/edit"
              exact
              component={RetailScoreSheetEdit}
            />
            <Route
              path="/retail-score-sheets/:id/"
              exact
              component={RetailScoreSheetView}
            />
            <Route
              path="/retail-score-categories"
              exact
              component={RetailScoreCategoryIndex}
            />
            <Route
              path="/retail-score-categories/create"
              exact
              component={RetailScoreCategoryCreate}
            />
            <Route
              path="/retail-score-categories/edit"
              exact
              component={RetailScoreCategoryEdit}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Content;
