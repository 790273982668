import React from 'react';
import { Menu, Grid } from "semantic-ui-react";
import { Link } from 'react-router-dom';
import { useRolePermissions } from '../hooks/useRolePermissions';
import './../assets/css/components/dashboard-menu.css';

const { isPermittedAmong } = useRolePermissions();

class DashboardMenu extends React.Component {

    render() {
        const menuItems = [
            {
                'name': 'check-lists',
                'content': 'Check Lists',
                'path': '/check-lists',
                'permissions': [
                    "communication-log-view",
                    "cash-count-view",
                    "daily-checklist-view",
                    "waste-log-view",
                ],
            },
            {
                'name': 'recipe',
                'content': 'Recipes',
                'path': '/recipes',
                'permissions': [
                    'recipes-view',
                ],
            },
            {
                'name': 'schematics',
                'content': 'Schematics',
                'path': '/schematics',
                'permissions': [
                    'schematics-view',
                ],
            },
            {
                'name': 'management',
                'content': 'Management',
                'path': '/management',
                'permissions': [
                    'allow-for-all',
                ],
            },
            // {
            //     'name': 'manager',
            //     'content': 'Regional Manager',
            //     'path': '/management',
            //     'permissions': [
            //         'allow-for-all',
            //     ],
            // },
            {
                'name': 'settings',
                'content': 'Admin',
                'path': '/admin',
                'permissions': [
                    'allow-for-all',
                ]
            },
            {
                'name': 'user',
                'content': 'Users',
                'path': '/admin/users',
                'permissions': [
                    'users-view',
                ],
            }
        ];

        return (
            <div className="grid-wrapper">
                <Grid columns={3} divided>
                    <Grid.Row>
                        {menuItems.map((item, key) => {
                            return (
                                isPermittedAmong(item.permissions, this.props.activeStore ? this.props.activeStore.id : null) ?
                                    <Grid.Column key={'column' + key} >
                                        <Menu.Item
                                            as={Link}
                                            to={item.path}
                                            name={item.name}
                                            key={'item' + key}
                                            className="menu-item"
                                        >
                                            <svg className={'menu-home-icon ' + item.name} />
                                            <span className="menu-label">{item.content}</span>
                                        </Menu.Item>
                                    </Grid.Column>
                                    : ''
                            )
                        })
                        }
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default DashboardMenu;
