import React from "react";
import {
  Button,
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Icon,
  Table,
  Checkbox,
} from "semantic-ui-react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Request from "../../Request";
import "./../../assets/css/pages/permissions/useraccess.css";
import Title from "components/Title";
import { useRolePermissions } from "./../../hooks/useRolePermissions";

const { redirectToForbiddenIfUnauthorized, isPermitted } = useRolePermissions();

class UserAccess extends React.Component {
  state = {
    roles: [],
    permissions: [],
    rolePermissions: [],
    mappedRoleData: [],
    mappedPermissionData: [],
    loading: false,
  };

  constructor() {
    super();
    this.request = new Request();
  }

  componentDidMount() {
    redirectToForbiddenIfUnauthorized("user-access-view");
    this.getRolePermissionData();

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ customSize: window.innerWidth <= 1150 });
  }

  getRolePermissionData = () => {
    new Promise((resolve, reject) => {
      let path = "/role-permissions";
      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let stores = JSON.parse(response);
        if (stores && stores.success === true && stores.data) {
          this.setState({
            roles: stores.data.roles,
            permissions: stores.data.permissions,
            rolePermissions: stores.data.rolePermissions,
          });

          this.mappRolePermissionData();
        }
      })
      .catch((error) => {
        this.setState({
          roles: [],
          Permissions: [],
          rolePermissions: [],
        });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get user access data", "Error");
        }
      });
  };

  mappRolePermissionData = () => {
    let mappedRoles = {};
    let mappedPermissions = {};

    this.state.roles.forEach((role) => {
      let roleLevel = role.requiresStore ? "Store" : "Corporate";
      if (!mappedRoles[roleLevel]) {
        mappedRoles[roleLevel] = [];
      }
      mappedRoles[roleLevel].push(role);
    });

    this.state.permissions.forEach((permission) => {
      if (!mappedPermissions[permission.permissionType]) {
        mappedPermissions[permission.permissionType] = [];
      }
      mappedPermissions[permission.permissionType].push(permission);
    });

    this.setState({
      mappedRoleData: mappedRoles,
      mappedPermissionData: mappedPermissions,
    });
  };

  snakeCaseToNormalCase = (input, camelCase = false) => {
    const words = input.split("_");
    let result;

    if (camelCase) {
      result = words
        .map((word, index) =>
          index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
        )
        .join("");
    } else {
      result = words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }

    return result;
  };

  handleCheckboxOnchange = (role, permission) => {
    setTimeout(() => {
      const permissionParentId = permission.parentId;
      const isItemChecked = document.getElementsByName(
        role.id + "_" + permission.id
      )[0].checked;

      // set parent permission as checked
      if (permissionParentId && isItemChecked) {
        let isChecked = document.getElementsByName(
          role.id + "_" + permissionParentId
        )[0].checked;
        if (!isChecked) {
          document
            .getElementsByName(role.id + "_" + permissionParentId)[0]
            .click();
        }
      }

      if (!isItemChecked) {
        // set child permissions as unchecked
        const childPermissions = document.querySelectorAll(
          'div [data-parentid="' +
            permission.id +
            '"][data-roleid="' +
            role.id +
            '"]'
        );

        childPermissions.forEach((childPermission) => {
          // find checkbox inside
          const checkbox = childPermission.parentElement.querySelector(
            'input[type="checkbox"]'
          );
          let isChecked = checkbox.checked;
          if (isChecked) {
            checkbox.click();
          }
        });
      }
    }, 0);
  };

  UserAccessContent = () => (
    <Table celled unstackable>
      <TableHeader>
        <TableRow>
          <TableHeaderCell colSpan="1"></TableHeaderCell>
          {Object.keys(this.state.mappedRoleData).map((roleLevel) => (
            <TableHeaderCell
              key={roleLevel}
              colSpan={this.state.mappedRoleData[roleLevel].length}
              className="capitalize-first-letter"
            >
              {roleLevel}
            </TableHeaderCell>
          ))}
        </TableRow>
        <TableRow>
          <TableHeaderCell>Ability</TableHeaderCell>
          {Object.keys(this.state.mappedRoleData).map((roleLevel) =>
            this.state.mappedRoleData[roleLevel].map((role) => (
              <TableHeaderCell key={role.id}>{role.name}</TableHeaderCell>
            ))
          )}
        </TableRow>
      </TableHeader>
      <TableBody>
        {Object.keys(this.state.mappedPermissionData).map((permissionType) => (
          <React.Fragment key={permissionType}>
            <TableRow>
              <TableCell
                colSpan={this.state.roles.length + 1}
                className="capitalize-first-letter subtitle"
              >
                <strong>{this.snakeCaseToNormalCase(permissionType)}</strong>
              </TableCell>
            </TableRow>
            {this.state.mappedPermissionData[permissionType].map(
              (permission) => (
                <TableRow key={permission.id} className="tableRow">
                  <TableCell>{permission.name}</TableCell>
                  {Object.keys(this.state.mappedRoleData).map((roleLevel) =>
                    this.state.mappedRoleData[roleLevel].map((role) => (
                      <TableCell key={`${role.id}_${permission.id}`}>
                        <Checkbox
                          test="test"
                          className={
                            role.role === "ROLE_ADMIN" ? "pointer-none" : ""
                          }
                          data-parentid={permission.parentId} // Use lowercase for custom attribute
                          data-roleid={role.id}
                          name={`${role.id}_${permission.id}`}
                          readOnly={role.role === "ROLE_ADMIN"}
                          defaultChecked={
                            role.role === "ROLE_ADMIN" ||
                            this.checkIsPermissionEnabledForRole(
                              role.id,
                              permission.id
                            )
                          }
                          onClick={() =>
                            this.handleCheckboxOnchange(role, permission)
                          }
                        />
                      </TableCell>
                    ))
                  )}
                </TableRow>
              )
            )}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );

  handleSave = () => {
    const selectedCheckboxes = [];

    this.state.roles.forEach((role) => {
      this.state.permissions.forEach((permission) => {
        const checkboxName = `${role.id}_${permission.id}`;
        const checkbox = document.querySelector(
          `input[name="${checkboxName}"]:checked`
        );
        if (checkbox) {
          selectedCheckboxes.push(checkboxName);
        }
      });
    });
    this.setState({ loading: true });
    new Promise((resolve, reject) => {
      let path = "/role-permissions";
      let params = {
        selectedRolePermissions: selectedCheckboxes,
      };
      this.request.put(path, params, resolve, reject);
    })
      .then((response) => {
        NotificationManager.success(
          "Role permission mapping updated successfully",
          "Success"
        );
      })
      .catch((error) => {
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error(
            "Failed to update roles permission mapping",
            "Error"
          );
        }
      })
      .finally(() => {
        this.setState({ loading: false }); // Set loading back to false after the request completes
      });
  };

  checkIsPermissionEnabledForRole(roleId, permissionId) {
    return (
      this.state.rolePermissions.find((rolePermission) => {
        return (
          rolePermission.roleId === roleId &&
          rolePermission.permissionId === permissionId
        );
      }) !== undefined
    );
  }

  render() {
    return (
      <div className="users-admin-root">
        <Title paramsPageTitle="User Access" />
        <div className="users-access-save-button-div">
          {this.UserAccessContent()}
          <br />
          <br />
          <div className="sticky-button-bar">
            {isPermitted("user-access-update") && (
              <button
                className="ui orange button"
                onClick={this.handleSave}
                disabled={this.state.loading}
              >
                {this.state.loading ? "Saving..." : "Save"}
              </button>
            )}
          </div>
        </div>

        <NotificationContainer />
      </div>
    );
  }
}

export default UserAccess;
