import React, { Component } from 'react';
import { Button, Icon, Accordion, Grid, Form, Label } from "semantic-ui-react";
import { NotificationManager, NotificationContainer } from "react-notifications";
import Title from "../../components/Title";
import './../../assets/css/pages/retail-score.css';
import Request from "../../Request";

class RetailScoreCategoryEdit extends Component {
    state = {
        activeIndex: -1,
        categories: [],
        validationErrors: {},
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        this.getCategories();
    }

    getCategories = () => {
        new Promise((resolve, reject) => {
            let path = '/retail-score-categories?include=retail_score_category_items';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let retailScoreCategoriesResponse = JSON.parse(response);
                if (true === retailScoreCategoriesResponse?.success) {
                    let categories = this.transformRetailScoreCategories(retailScoreCategoriesResponse.data);
                    this.setState({ categories }, () => {
                        this.setActiveIndex();
                    });
                } else {
                    NotificationManager.error('Could not get categories', 'Error');
                }
            })
            .catch(error => {
                this.setState({ categories: [] });
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get categories', 'Error');
                }
            });
    };

    transformRetailScoreCategories = (categories) => {
        return categories.map(({ id, name, retail_score_category_items }) => ({
            id,
            name,
            retail_score_category_items: retail_score_category_items?.map(({ id, name, permissible_score }) => ({ id, name, permissible_score })) || []
        }));
    };

    setActiveIndex = () => {
        const params = new URLSearchParams(this.props.location.search);
        const categoryId = Number(params.get('category_id'));
        let categories = this.state.categories;
        let activeIndex = categories.findIndex(category => category.id === categoryId);
        this.setState({ activeIndex });
    };

    onDragEnd = () => {
        NotificationManager.success('Successfully changed order of Retail Score', 'Success');
    };

    onItemPointChange = (categoryIndex, itemIndex, value) => {
        const categories = [...this.state.categories];
        categories[categoryIndex].retail_score_category_items[itemIndex].permissible_score = parseInt(value);
        this.setState({ categories });
    };

    onItemLabelChange = (categoryIndex, itemIndex, value) => {
        const categories = [...this.state.categories];
        categories[categoryIndex].retail_score_category_items[itemIndex].name = value;
        this.setState({ categories });
    };

    addItem = (categoryIndex) => {
        const categories = [...this.state.categories];
        categories[categoryIndex].retail_score_category_items.push({ name: '', permissible_score: 0 });
        this.setState({ categories });
    };

    updateCategory = (category) => {
        new Promise((resolve, reject) => {
            let path = `/retail-score-categories/${category.id}`;
            this.request.throwValidationErrors = true;
            this.request.put(path, category, resolve, reject);
        })
            .then(response => {
                this.setState({ validationErrors: {} });
                NotificationManager.success('Category updated successfully', 'Success');
            })
            .catch(error => {
                if (error.statusCode === 422) {
                    this.setValidationErrors(category.id, error?.errorObject?.errors)
                }
                else if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not able to update category', 'Error');
                }
            });
    };

    setValidationErrors = (categoryId, validationErrors) => {
        // Initialize an empty result object
        const result = { category_id: categoryId, name: '', retail_score_category_items: [] };

        // Iterate over the keys of the original object
        Object.keys(validationErrors).forEach(key => {
            const parts = key.split('.'); // Split the key by '.'
            const field = parts[parts.length - 1]; // Extract the field name
            const itemIndex = parts.length > 2 ? parseInt(parts[1]) : null; // Extract the item index if available

            // If the field belongs to the parent object
            if (parts.length === 1) {
                result.name = validationErrors[key];
            }
            // If the field belongs to an item within retail_score_category_items
            else if (parts.length === 3 && parts[0] === 'retail_score_category_items') {
                // Ensure the items array has enough elements to accommodate the current item index
                while (result.retail_score_category_items.length <= itemIndex) {
                    result.retail_score_category_items.push({});
                }

                // Set the error message for the corresponding field within the item
                result.retail_score_category_items[itemIndex][field] = validationErrors[key];
            }
        });

        this.setState({ validationErrors: result });
    };

    removeCategory = (categoryIndex) => {
        const categories = [...this.state.categories];
        categories.splice(categoryIndex, 1);
        this.setState({ categories });
    };

    removeItem = (categoryIndex, itemIndex) => {
        const categories = [...this.state.categories];
        categories[categoryIndex].retail_score_category_items.splice(itemIndex, 1);
        this.setState({ categories });
    };

    onCategoryNameChange = (categoryIndex, value) => {
        const categories = [...this.state.categories];
        categories[categoryIndex].name = value;
        this.setState({ categories });
    };

    addCategory = () => {
        const categories = [...this.state.categories];
        categories.push({ name: '', retail_score_category_items: [] });
        this.setState({ categories });
    };

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    getCardContent = (category, categoryIndex) => {
        const { activeIndex } = this.state;
        return (
            <div className="accordion-item" key={categoryIndex}>
                <Accordion.Title
                    active={activeIndex === categoryIndex}
                    index={categoryIndex}
                    onClick={this.handleClick}
                >
                    {category.name}
                    <Icon name='dropdown' />
                </Accordion.Title>
                <Accordion.Content
                    active={activeIndex === categoryIndex}
                    className='accordion-content'
                >
                    <div>
                        <Grid className="retail-score-grid" stackable>
                            <Grid.Row className="retail-score-grid-row" columns={2}>
                                <Grid.Column className="retail-score-grid-column" width={8}>
                                    <Form>
                                        <Grid className="retail-score-grid" stackable>
                                            <Grid.Row className="retail-score-grid-row" columns={2}>
                                                <Grid.Column className="retail-score-grid-column" width={2}>
                                                    <Button icon title="Remove item" onClick={() => this.removeCategory(categoryIndex)}>
                                                        <Icon name='minus' />
                                                    </Button>
                                                </Grid.Column>
                                                <Grid.Column className="retail-score-grid-column" width={12}>
                                                    <Form.Field>
                                                        <Label className="retail-score-group-label" pointing='below'>Category name</Label>
                                                        <input
                                                            className="retail-score-group-input"
                                                            type='text'
                                                            value={category.name}
                                                            placeholder="Name"
                                                            onChange={(e) => this.onCategoryNameChange(categoryIndex, e.target.value)}
                                                        />
                                                        {this.state.validationErrors?.category_id === category.id && this.state.validationErrors?.name ? (
                                                            <span className='retail-score-error'>{this.state.validationErrors?.name}</span>
                                                        ) : (
                                                            null
                                                        )}
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Form>
                                </Grid.Column>
                                <Grid.Column className="retail-score-grid-action-column" width={8}>
                                    <Grid className="retail-score-grid" stackable>
                                        <Grid.Row className="retail-score-grid-row" columns={2}>
                                            <Grid.Column className="retail-score-grid-column" width={10}>
                                                <h4>Item name</h4>
                                            </Grid.Column>
                                            <Grid.Column className="retail-score-grid-column" width={4}>
                                                <h4>Score</h4>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    {category.retail_score_category_items.map((item, itemIndex) => (
                                        <div className="retail-score-item" key={itemIndex}>
                                            <Form>
                                                <Grid className="retail-score-grid" stackable>
                                                    <Grid.Row className="retail-score-grid-row" columns={2}>
                                                        <Grid.Column className="retail-score-grid-column" width={10}>
                                                            <Form.Field>
                                                                <input
                                                                    className="retail-score-item-label"
                                                                    type='text'
                                                                    value={item.name}
                                                                    placeholder="Name"
                                                                    onChange={(e) => this.onItemLabelChange(categoryIndex, itemIndex, e.target.value)}
                                                                />
                                                                {
                                                                    this.state.validationErrors?.category_id === category.id &&
                                                                        this.state.validationErrors?.retail_score_category_items[itemIndex]?.name ?
                                                                        (
                                                                            <span className='retail-score-error'>{this.state.validationErrors?.retail_score_category_items[itemIndex]?.name}</span>
                                                                        ) : (
                                                                            null
                                                                        )
                                                                }
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column className="retail-score-grid-column" width={4}>
                                                            <Form.Field>
                                                                <input
                                                                    className="retail-score-item-point"
                                                                    type='number'
                                                                    value={item.permissible_score}
                                                                    placeholder="Score"
                                                                    onChange={(e) => this.onItemPointChange(categoryIndex, itemIndex, e.target.value)}
                                                                />
                                                                {
                                                                    this.state.validationErrors?.category_id === category.id &&
                                                                        this.state.validationErrors?.retail_score_category_items[itemIndex]?.permissible_score ?
                                                                        (
                                                                            <span className='retail-score-error'>{this.state.validationErrors?.retail_score_category_items[itemIndex]?.permissible_score}</span>
                                                                        ) : (
                                                                            null
                                                                        )
                                                                }
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column className="retail-score-grid-column" width={2}>
                                                            <Button icon title="Remove item" onClick={() => this.removeItem(categoryIndex, itemIndex)}>
                                                                <Icon name='trash' />
                                                            </Button>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Form>
                                        </div>
                                    ))}
                                    <div className='retail-score-grid-action'>
                                        <Button icon labelPosition='left' onClick={() => this.addItem(categoryIndex)}>
                                            <Icon name='plus' />
                                            Add Type
                                        </Button>
                                        <Button color="orange" onClick={() => this.updateCategory(category)}>
                                            Save
                                        </Button>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Accordion.Content>
            </div>
        );
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.categories !== prevState.categories) {
        }
    }

    render() {
        const { categories } = this.state;

        return (
            <div className="retail-score-page">
                <Title paramsPageTitle="Edit Retail Score Category" />
                <div className="retail-score-content">
                    {categories && categories.length ? (
                        <Accordion fluid styled>
                            {
                                categories.map((category, index) => {
                                    return this.getCardContent(category, index);
                                })
                            }
                        </Accordion>
                    ) : (
                        <p>No retail score found</p>
                    )}
                </div>
                <NotificationContainer />
            </div>
        );
    }
}

export default RetailScoreCategoryEdit;
