import Cookies from "universal-cookie";

const jwt_decode = require("jwt-decode");

function MenuItems() {
  let menu = {
    "check-lists": {
      title: {
        name: "check-lists",
        content: "Check Lists",
        index: 0,
      },
      permission: [
        "communication-log-view",
        "cash-count-view",
        "daily-checklist-view",
        "waste-log-view",
      ],
      submenu: {
        "comm-sheet": {
          name: "comm-sheet",
          content: "Communication Sheet",
          path: "/check-lists/comm-sheet",
          permission: "communication-log-view",
        },
        "cash-count": {
          name: "cash-count",
          content: "Cash Count Sheet",
          path: "/check-lists/cash-count",
          permission: "cash-count-view",
        },
        "daily-check": {
          name: "daily-check",
          content: "Daily Checklist",
          path: "/check-lists/daily-check",
          permission: "daily-checklist-view",
        },
        wastelog: {
          name: "wastelog",
          content: "Waste Log",
          path: "/check-lists/wastelog",
          permission: "waste-log-view",
        },
      },
    },
    recipes: {
      title: {
        name: "recipes",
        content: "Recipes",
        index: 1,
      },
      permission: ["recipes-view"],
      submenu: {
        "espresso-bar": {
          name: "espresso-bar",
          content: "Espresso Bar",
          path: "/recipes/espresso-bar",
          permission: "recipes-view",
        },
        "coffee-bar": {
          name: "coffee-bar",
          content: "Coffee Bar",
          path: "/recipes/coffee-bar",
          permission: "recipes-view",
        },
        "tea-bar": {
          name: "tea-bar",
          content: "Tea Bar",
          path: "/recipes/tea-bar",
          permission: "recipes-view",
        },
        "cooling-aids": {
          name: "cooling-aids",
          content: "Cooling Aids",
          path: "/recipes/cooling-aids",
          permission: "recipes-view",
        },
        "cofftails-dreams": {
          name: "cofftails-dreams",
          content: "Cofftails & Dreams",
          path: "/recipes/cofftails-dreams",
          permission: "recipes-view",
        },
        "batch-recipes": {
          name: "batch-recipes",
          content: "Batch Recipes",
          path: "/recipes/batch-recipes",
          permission: "recipes-view",
        },
        "hot-food-prep": {
          name: "hot-food-prep",
          content: "Hot Food Prep",
          path: "/recipes/hot-food-prep",
          permission: "recipes-view",
        },
      },
    },
    schematics: {
      title: {
        name: "schematics",
        content: "Schematics",
        path: "/schematics",
      },
      permission: ["schematics-view"],
    },
  };
  let cookies = new Cookies();
  let decodedCookie = cookies.getAll();
  if (decodedCookie.token) {
    let decoded = jwt_decode(decodedCookie.token);
    let managementToken = decodedCookie["management-token"];
    let roles = decoded.roles;
    let username = decoded.username;
    let isPermittedToManagementMenus =
      roles.includes("ROLE_ADMIN") || roles.includes("ROLE_MANAGER");

    menu.management = {
      title: {
        name: "management",
        content: "Management",
        index: 2,
      },
      permission: ["allow-for-all"],
      submenu: {
        "address-book": {
          name: "address-book",
          content: "Address Book",
          path: "/management/address-book",
          permission: "service-providers-view",
        },
        checklists: {
          name: "checklists",
          content: "Checklists",
          path: "/management/checklists",
          permission: "daily-checklist-view",
        },
        "hr-documents": {
          name: "hr-documents",
          content: "HR Documents",
          path: "/management/hr-documents",
          permission: "documents-forms-view",
        },
        "key-release": {
          name: "key-release",
          content: "Key Release",
          path: "/management/key-release",
          permission: "key-release-log-view",
        },
        "new-orders": {
          name: "new-orders",
          content: "New Orders",
          path: "/management/new-orders",
          permission: "order-history-create",
        },
        "order-history": {
          name: "order-history",
          content: "Order History",
          path: "/management/order-history",
          permission: "order-history-view",
        },
        "service-ticket": {
          name: "service-ticket",
          content: "Service Ticket",
          path: "/management/service-ticket",
          permission: "help-desk-history-create",
        },
        "incident-report": {
          name: "incident-report",
          content: "Incident Report",
          path: "/management/incident-report-list",
          permission: "incident-reports-list",
        },
        "retail-score-category": {
          name: "retail-score-category",
          content: "Retail Score Category",
          path: "/retail-score-categories",
          permission: "retail-score-categories-list",
        },
        "retail-score-sheet": {
          name: "retail-score-sheet",
          content: "Retail Score Sheets",
          path: "/retail-score-sheets",
          permission: "retail-score-sheet-list",
        },
      },
    };

    if (!managementToken && !isPermittedToManagementMenus) {
      menu.management.submenu["management-login"] = {
        name: "management-login",
        content: "Login",
        path: "/management/login",
        permission: "allow-for-all",
      };
    }

    if (managementToken && !isPermittedToManagementMenus) {
      menu.management.submenu["management-logout"] = {
        name: "management-logout",
        content: "Logout",
        path: "/management/logout",
        permission: "allow-for-all",
      };
    }

    menu.admin = {
      title: {
        name: "admin",
        content: "Admin",
        index: 3,
      },
      permission: [
        "dashboard",
        "promotions-view",
        "users-view",
        "stores-view",
        "vendors-view",
        "documents-forms-view",
        "schematics-view",
        "recipes-view",
        "categories-view",
        "products-login",
        "roles-view",
        "user-access-update",
      ],
      submenu: {
        dashboard: {
          name: "dashboard",
          content: "Dashboard",
          path: "/admin/dashboard",
          permission: "dashboard",
        },
        promotion: {
          name: "promotion",
          content: "Promotion",
          path: "/admin/promotion",
          permission: "promotions-view",
        },
        users: {
          name: "users",
          content: "Users",
          path: "/admin/users",
          permission: "users-view",
        },
        stores: {
          name: "stores",
          content: "Stores",
          path: "/admin/stores",
          permission: "stores-view",
        },
        vendors: {
          name: "vendors",
          content: "Vendors",
          path: "/admin/vendors",
          permission: "vendors-view",
        },
        documents: {
          name: "documents",
          content: "Documents",
          path: "/admin/documents",
          permission: "documents-forms-view",
        },
        "schematics-adm": {
          name: "schematics-adm",
          content: "Schematics",
          path: "/admin/schematics",
          permission: "schematics-view",
        },
        recipes: {
          name: "recipes",
          content: "Recipes",
          path: "/admin/recipes",
          permission: "recipes-view",
        },
        categories: {
          name: "categories",
          content: "Categories",
          path: "/admin/categories",
          permission: "categories-view",
        },
        products: {
          name: "products",
          content: "Products",
          path: "/admin/products",
          permission: "products-view",
        },
        roles: {
          name: "roles",
          content: "Roles",
          path: "/admin/roles",
          permission: "roles-view",
        },
        "user-access": {
          name: "user-access",
          content: "User Access",
          path: "/admin/user-access",
          permission: "user-access-update",
        },
      },
    };

    if (decodedCookie["user-name"] && decodedCookie["user-id"]) {
      menu.account = {
        title: {
          name: "account",
          content: decodedCookie["user-name"],
          index: 4,
        },
        permission: ["allow-for-all"],
        submenu: {
          "user-account": {
            name: "user-account",
            content: "Account",
            path: "/profile/" + username,
            permission: "allow-for-all",
          },
          "user-logout": {
            name: "user-logout",
            content: "Logout",
            path: "/logout",
            permission: "allow-for-all",
          },
        },
      };
    }

    return menu;
  }

  let url = process.env.REACT_APP_UI_URL;
  window.location.href = url + "/login";
}

export default MenuItems;
