import React from "react";
import {
  Button,
  Card,
  Grid,
  Header,
  Icon,
  Image,
  Modal,
} from "semantic-ui-react";
import "./../../assets/css/pages/admin/resource-index.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Request from "../../Request";
import Title from "components/Title";
import moment from "moment";
import { Link } from "react-router-dom";
import { useRolePermissions } from "../../hooks/useRolePermissions";

const { isPermitted } = useRolePermissions();

class RetailScoreCategoryIndex extends React.Component {
  state = {
    showTrashed: false,
    customSize: window.innerWidth <= 1150,
    categories: [],
    renderCategories: false, //remove
    modalOpen: false,
    removeId: null,
  };

  constructor() {
    super();
    this.request = new Request();
  }

  componentDidMount() {
    this.getCategories();

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ customSize: window.innerWidth <= 1150 });
  }

  getCategories = () => {
    new Promise((resolve, reject) => {
      let path = `/retail-score-categories?trashed=${
        this.state.showTrashed ? "1" : "0"
      }`;
      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let categoriesResponse = JSON.parse(response);
        if (
          categoriesResponse &&
          categoriesResponse.success === true &&
          categoriesResponse.data
        ) {
          let categories = this.transformCategories(categoriesResponse.data);
          this.setState({ categories }, () => {
            this.setState({ renderCategories: true });
          });
        } else {
          this.setState({ categories: [] }, () => {
            this.setState({ renderCategories: true });
          });
        }
      })
      .catch((error) => {
        this.setState({ categories: [] }, () => {
          this.setState({ renderCategories: true });
        });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get categories", "Error");
        }
      });
  };

  transformCategories = (categories) => {
    return categories.map(({ id, name, created_at }) => {
      const dateObject = moment.utc(created_at?.date);
      const formattedDate = dateObject.format("MMMM DD, YYYY");

      return {
        id,
        name,
        created_at: formattedDate,
      };
    });
  };

  showTrashedToogle = () => {
    let showTrashed = this.state.showTrashed;
    showTrashed = !showTrashed;
    this.setState({ showTrashed: showTrashed }, () => {
      this.getCategories();
    });
  };

  handleCloseModal = () => {
    this.setState({
      removeId: null,
      modalOpen: false,
    });
  };

  deleteCategory = (id) => {
    new Promise((resolve, reject) => {
      let path = `/retail-score-categories/${id}`;
      this.request.delete(path, resolve, reject);
    })
      .then((response) => {
        NotificationManager.success("Successfully deleted category", "Success");
        this.getCategories();
      })
      .catch((error) => {
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not delete category", "Error");
        }
      });
  };

  restoreCategory = (id) => {
    new Promise((resolve, reject) => {
      let path = `/retail-score-categories/${id}/restore`;
      this.request.put(path, {}, resolve, reject);
    })
      .then((response) => {
        NotificationManager.success("Category restored", "Success");
        this.getCategories();
      })
      .catch((error) => {
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not restore category", "Error");
        }
      });
  };

  forceDeleteCategory = (id) => {
    new Promise((resolve, reject) => {
      let path = `/retail-score-categories/${id}/force-delete`;
      this.request.delete(path, resolve, reject);
    })
      .then((response) => {
        NotificationManager.success("Category deleted", "Success");
        this.getCategories();
        this.handleCloseModal();
      })
      .catch((error) => {
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not delete category", "Error");
        }
      });
  };

  removeCategoryConfirmation = (id) => {
    this.setState({ removeId: id, modalOpen: true });
  };

  getConfirmationModal = () => {
    let removeId = this.state.removeId;

    if (removeId || removeId === 0) {
      return (
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleCloseModal}
          size="small"
        >
          <Header content="Delete Category" />
          <Modal.Content>
            <h3>Are you sure you want to delete this Category?</h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={this.handleCloseModal} inverted>
              No
            </Button>
            <Button
              color="green"
              onClick={() => this.forceDeleteCategory(removeId)}
              inverted
            >
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
    return "";
  };

  getOneCardDescription = (category, index) => {
    return (
      <Grid className="resource-index-card-grid">
        <Grid.Row className="resource-index-card-grid-row">
          <Grid.Column width={16}>
            <b>Name: </b>
            {category.name || ""}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="resource-index-card-grid-row">
          <Grid.Column width={16}>
            <b>Created At: </b>
            {category.created_at || ""}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="resource-index-card-grid-row">
          <Grid.Column width={16}>
            {!this.state.showTrashed ? (
              <div className="resource-index-card-grid-row-buttons-div">
                {isPermitted("retail-score-categories-edit") && (
                  <Link
                    to={`/retail-score-categories/edit?category_id=${category.id}`}
                  >
                    <Button icon labelPosition="left">
                      <Icon name="edit" />
                      Edit Category
                    </Button>
                  </Link>
                )}
                {isPermitted("retail-score-categories-delete") && (
                  <Button
                    icon
                    labelPosition="left"
                    onClick={() => this.deleteCategory(category.id)}
                  >
                    <Icon name="trash" />
                    Delete Category
                  </Button>
                )}
              </div>
            ) : (
              <div className="resource-index-card-grid-row-buttons-div">
                {isPermitted("retail-score-categories-delete") && (
                  <Button
                    icon
                    labelPosition="left"
                    onClick={() => this.restoreCategory(category.id)}
                  >
                    <Icon name="refresh" />
                    Restore Category
                  </Button>
                )}
                {isPermitted("retail-score-categories-delete") && (
                  <Button
                    icon
                    labelPosition="left"
                    onClick={() => this.removeCategoryConfirmation(category.id)}
                  >
                    <Icon name="times" />
                    Delete Category
                  </Button>
                )}
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  getCategoriesCard = () => {
    let cards = [];
    let categories = this.state.categories;
    categories.forEach((category, index) => {
      cards.push(
        <Card className="resource-index-card" fluid key={index}>
          <Card.Content
            className="resource-index-card-header"
            header={category.name}
          />
          <Card.Content
            description={this.getOneCardDescription(category, index)}
          />
        </Card>
      );
    });
    cards.push(
      <div key="retail-category-admin-plus-button">
        {isPermitted("retail-score-categories-create") && (
          <Link to="/retail-score-categories/create">
            <Button icon labelPosition="left">
              <Icon name="plus" />
              Category
            </Button>
          </Link>
        )}
      </div>
    );
    return cards;
  };

  getCategoriesContent = () => {
    return (
      <Grid className="resource-index-grid">
        <Grid.Row className="resource-index-grid-header-row">
          <Grid.Column width={2}>#</Grid.Column>
          <Grid.Column width={5}>Name</Grid.Column>
          <Grid.Column width={4}>Created On</Grid.Column>
          <Grid.Column width={5} textAlign="right"></Grid.Column>
        </Grid.Row>
        {this.state.categories.map((category, index) => {
          return (
            <Grid.Row className="resource-index-grid-row" key={index}>
              <Grid.Column width={2} className="resource-index-grid-column">
                {index + 1}
              </Grid.Column>
              <Grid.Column width={5} className="resource-index-grid-column">
                {category.name || ""}
              </Grid.Column>
              <Grid.Column width={4} className="resource-index-grid-column">
                {category.created_at || ""}
              </Grid.Column>
              <Grid.Column
                width={5}
                className="resource-index-grid-actions-column"
              >
                <Button.Group icon>
                  {!this.state.showTrashed ? (
                    <div>
                      {isPermitted("retail-score-categories-edit") && (
                        <Button size="tiny" title="Edit Category">
                          <Link
                            to={`/retail-score-categories/edit?category_id=${category.id}`}
                          >
                            <Icon name="edit" />
                          </Link>
                        </Button>
                      )}
                      {isPermitted("retail-score-categories-delete") && (
                        <Button
                          size="tiny"
                          title="Delete Category"
                          onClick={() => this.deleteCategory(category.id)}
                        >
                          <Icon name="trash" />
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div>
                      {isPermitted("retail-score-categories-delete") && (
                        <Button
                          size="tiny"
                          title="Restore Category"
                          onClick={() => this.restoreCategory(category.id)}
                        >
                          <Icon name="refresh" />
                        </Button>
                      )}
                      {isPermitted("retail-score-categories-delete") && (
                        <Button
                          size="tiny"
                          title="Delete Category"
                          onClick={() =>
                            this.removeCategoryConfirmation(category.id)
                          }
                        >
                          <Icon name="times" />
                        </Button>
                      )}
                    </div>
                  )}
                </Button.Group>
              </Grid.Column>
            </Grid.Row>
          );
        })}
        <Grid.Row className="resource-index-grid-footer-row">
          <Grid.Column width={2}>
            {isPermitted("retail-score-categories-create") && (
              <Link to="/retail-score-categories/create">
                <Button icon labelPosition="left" className="orange">
                  <Icon name="plus" />
                  Category
                </Button>
              </Link>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  render() {
    return (
      <div className="retail-category-admin-root">
        <Title paramsPageTitle="Retail Score Categories" />
        <div className="retail-category-admin-trash-button-div">
          {this.state.showTrashed ? (
            <Button color="orange" onClick={this.showTrashedToogle}>
              <Icon name="angle left" />
              Show Active
            </Button>
          ) : (
            <Button color="orange" onClick={this.showTrashedToogle}>
              <Icon name="trash" />
              Show Trashed
            </Button>
          )}
        </div>
        {this.state.renderCategories
          ? this.state.customSize
            ? this.getCategoriesCard()
            : this.getCategoriesContent()
          : ""}
        {this.getConfirmationModal()}
        <NotificationContainer />
      </div>
    );
  }
}

export default RetailScoreCategoryIndex;
