import React from 'react';
import { Button, Grid, Header, Icon, List, Modal, Select } from "semantic-ui-react";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import '../../assets/css/pages/managment/checklists-admin.css';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Request from "../../Request";
import Title from 'components/Title';
import { useRolePermissions } from './../../hooks/useRolePermissions';
import Forbidden from './../../pages/Forbidden';

const { redirectToForbiddenIfUnauthorized, isPermitted } = useRolePermissions();

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: 8
});

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 8 * 2,
    margin: `0`,

    // change background colour if dragging
    background: isDragging ? "lightgray" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
});

class Checklists extends React.Component {
    state = {
        store: {},
        changeStore: false,
        stores: [],
        options: [],
        openModal: false,
        renderStoreInfos: false,
        checklistOptions: [
            {
                id: 1,
                key: 1,
                value: 'open',
                text: 'Opening Shift'
            },
            {
                id: 2,
                key: 2,
                value: 'mid',
                text: 'Mid Shift'
            },
            {
                id: 3,
                key: 3,
                value: 'close',
                text: 'Closing Shift'
            },
            {
                id: 4,
                key: 4,
                value: 'weekly',
                text: 'Weekly Tasks'
            }
        ],
        pickedChecklistType: {
            id: 1,
            key: 1,
            value: 'open',
            text: 'Opening Shift'
        },
        checklists: [],
        renderChecklists: false,
        dragAndDropData: [],
        tabletSize: window.innerWidth <= 768, // for the modal position
        mobileSize: window.innerWidth <= 360, // for the popup position
        modalOpen: false,
        removeId: null
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        redirectToForbiddenIfUnauthorized('checklists-view');

        this.setState({ renderStoreInfos: false });
        this.getStoresData();

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({ mobileSize: window.innerWidth <= 360, tabletSize: window.innerWidth <= 768 }, () => {
            if (!window.innerWidth <= 768) {
                this.setState({ openModal: false });
            }
        });
    }

    // FILTERS

    getStoresData = () => {
        new Promise((resolve, reject) => {
            let path = '/stores-managers';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let stores = JSON.parse(response);
                if (stores && stores.success === true && stores.data) {
                    let activeStore = localStorage.getItem('activeStore');
                    if (activeStore && typeof activeStore !== 'undefined') {
                        activeStore = {
                            franchisees: [],
                            managers: [],
                            store: JSON.parse(activeStore)
                        };
                    } else {
                        activeStore = stores.data[0]
                    }

                    this.setState({ stores: stores.data, store: activeStore }, () => {
                        this.transformStoresToOptions();
                        this.setState({ renderStoreInfos: true });
                        this.getChecklists();
                    });
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
            })
            .catch(error => {
                this.setState({ stores: [] });
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
                this.setState({ renderStoreInfos: true });
            });
    };

    transformStoresToOptions = () => {
        let stores = this.state.stores;
        let options = [];
        stores.forEach((store, index) => {
            let tempOption = {
                id: store.store.id,
                key: store.store.id,
                value: store.store.id,
                text: store.store.title
            };
            options.push(tempOption);
        });
        this.setState({ options: options })
    };

    handleStoresInfoButton = () => {
        if (this.state.tabletSize) {
            this.setState({ openModal: true })
        } else {
            this.setState({ openModal: false });
        }
    };

    handleRedirectToProfile = (username) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/profile/' + username;
    };

    getStoresPopupInfos = () => {
        return (
            <Grid className="store-info-popup-content" columns={2} stackable>
                <Grid.Row>
                    {this.state.store && this.state.store.managers ?
                        <Grid.Column width={8} className="store-info-managers">
                            {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                <div>
                                    <h5>Managers</h5>
                                    {this.state.store.managers && this.state.store.managers.length > 0 ?
                                        <ol>
                                            {this.state.store.managers.map((manager, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + manager.username}>
                                                            {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                        </a>
                                                        <p>{manager.phone}</p>
                                                        <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                            None
                                        </span>
                                    }
                                    <h5>Franchisees</h5>
                                    {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                        <ol>
                                            {this.state.store.franchisees.map((franchise, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a href={'/profile/' + franchise.username}>
                                                            {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                        </a>
                                                        <p>{franchise.phone}</p>
                                                        <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                        :
                                        <span>
                                            None
                                        </span>
                                    }
                                </div>
                                : ''
                            }
                        </Grid.Column> : ''
                    }
                    <Grid.Column width={this.state.store && this.state.store.managers ? 8 : 16}>
                        <h5>{this.state.store.store.title || ''}</h5>
                        <p>{this.state.store.store.street1 || ''}</p>
                        <p>{this.state.store.store.street2 || ''}</p>
                        <h5>Phone</h5>
                        <p>{this.state.store.store.phone || ''}</p>
                        <h5>Email</h5>
                        {this.state.store.store.email ? <a href={'mailto:' + this.state.store.store.email} target="_top">{this.state.store.store.email}</a> : ''}
                        {this.state.store.store.email2 ? <a href={'mailto:' + this.state.store.store.email2} target="_top">{this.state.store.store.email2}</a> : ''}
                        <h5>Fax</h5>
                        <p>{this.state.store.store.fax || ''}</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    };

    handleEditStore = (data) => {
        this.setState({ changeStore: true })
    };

    handleChangeStore = (event, data) => {
        const foundStore = this.state.stores.find(element => element.store.id === parseInt(data.value));
        if (foundStore) {
            this.setState({ changeStore: false, store: foundStore }, () => {
                this.getChecklists();
            });
            localStorage.setItem('activeStore', JSON.stringify(foundStore.store));
        }
    };

    handleCloseStoresModal = () => {
        this.setState({ openModal: false });
    };

    getStoresModalInfos = () => {
        return (
            <Modal className="stores-info-modal" open={this.state.openModal} onClose={this.handleCloseStoresModal} size="mini">
                <Modal.Header>Store Information</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {this.state.openModal ?
                            <div>
                                <div className="modal-stores-info">
                                    <h5>{this.state.store.store.title || ''}</h5>
                                    <p>{this.state.store.store.street1 || ''}</p>
                                    <p>{this.state.store.store.street2 || ''}</p>
                                    <h5>Phone</h5>
                                    <p>{this.state.store.store.phone || ''}</p>
                                    <h5>Email</h5>
                                    {
                                        this.state.store.store.email ?
                                            <a href={'mailto:' + this.state.store.store.email} target="_top">
                                                {this.state.store.store.email}
                                            </a> : ''
                                    }
                                    {this.state.store.store.email2 ?
                                        <a href={'mailto:' + this.state.store.store.email2} target="_top">
                                            {this.state.store.store.email2}
                                        </a> : ''
                                    }
                                    <h5>Fax</h5>
                                    <p>{this.state.store.store.fax || ''}</p>
                                </div><br />
                                {this.state.store && this.state.store.managers && Array.isArray(this.state.store.managers) ?
                                    <div className="modal-stores-info">
                                        <h5>Managers</h5>
                                        {this.state.store.managers && this.state.store.managers.length > 0 ?
                                            <ol>
                                                {this.state.store.managers.map((manager, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + manager.username} onClick={() => this.handleRedirectToProfile(manager.username)}>
                                                                {(manager.first_name || '') + ' ' + (manager.last_name || '')}
                                                            </a>
                                                            <p>{manager.phone}</p>
                                                            <a href={'mailto:' + manager.email}>{manager.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                        <h5>Franchisees</h5>
                                        {this.state.store.franchisees && this.state.store.franchisees.length > 0 ?
                                            <ol>
                                                {this.state.store.franchisees.map((franchise, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <a href={'/profile/' + franchise.username} onClick={() => this.handleRedirectToProfile(franchise.username)}>
                                                                {(franchise.first_name || '') + ' ' + (franchise.last_name || '')}
                                                            </a>
                                                            <p>{franchise.phone}</p>
                                                            <a href={'mailto:' + franchise.email}>{franchise.email || ''}</a>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                            :
                                            <span>
                                                None
                                            </span>
                                        }
                                    </div>
                                    : ''

                                }
                            </div>
                            : ''}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleCloseStoresModal} inverted>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    };

    // FILTERS END

    initialOrder = (checklists) => {
        return checklists.sort((checklist1, checklist2) => {
            return checklist1.order > checklist2.order ? 1 : -1;
        })
    };

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        let destinationIndex = result.destination.index;
        let sourceIndex = result.source.index;
        let checklist = this.state.checklists[sourceIndex];
        const items = this.reorder(
            this.state.checklists,
            sourceIndex,
            destinationIndex
        );
        this.setState({
            checklists: items
        });

        new Promise((resolve, reject) => {
            let path = '/checklists/order/' + this.state.store.store.id + '/' + this.state.pickedChecklistType.value;
            let params = {
                id: checklist.id,
                newIndex: destinationIndex + 1
            };
            this.request.put(path, params, resolve, reject);
        })
            .then(response => {
                NotificationManager.success('Successfully changed order of Checklists', 'Success');
            })
            .catch(error => {
                if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not change order of Checklists', 'Error');
                }
            });
    };

    getChecklists = () => {
        if (this.state.store.store && this.state.store.store.id) {
            new Promise((resolve, reject) => {
                let path = '/checklists/' + this.state.store.store.id + '/' + this.state.pickedChecklistType.value;
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let checklists = JSON.parse(response);
                    if (checklists && checklists.success === true && checklists.data) {
                        let temp = this.initialOrder(checklists.data);
                        this.setState({ checklists: temp, renderChecklists: true });
                    }
                    else {
                        this.setState({ checklists: [], renderChecklists: true })
                    }
                })
                .catch(error => {
                    this.setState({ checklists: [], renderChecklists: true });
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get Checklists', 'Error');
                    }
                });
        }
    };

    deleteChecklist = (id) => {
        this.setState({ modalOpen: false });
        if (id) {
            new Promise((resolve, reject) => {
                let path = '/checklists/' + id;
                this.request.delete(path, resolve, reject);
            })
                .then(response => {
                    response = JSON.parse(response);
                    if (response && response.success === true) {
                        NotificationManager.success('Successfully removed task', 'Success');
                        this.getChecklists();
                    }
                })
                .catch(error => {
                    if (error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not remove task', 'Error');
                    }
                });
        }
        else {
            NotificationManager.error('Could not remove task', 'Error');
        }
    };

    handleChangeType = (event, data) => {
        const foundType = this.state.checklistOptions.find(element => element.value === data.value);
        if (foundType) {
            this.setState({ pickedChecklistType: foundType }, () => {
                this.getChecklists();
            });
        }
    };

    handleCloseModal = () => {
        this.setState({
            removeId: null,
            modalOpen: false
        });
    };

    removeChecklistConfirmation = (id) => {
        this.setState({ removeId: id, modalOpen: true });
    };

    getConfirmationModal = () => {
        let removeId = this.state.removeId;

        if (removeId || removeId === 0) {
            return (
                <Modal
                    open={this.state.modalOpen}
                    onClose={this.handleCloseModal}
                    size='small'
                >
                    <Header content='Delete Checklist' />
                    <Modal.Content>
                        <h3>Are you sure you want to delete this Checklist?</h3>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.handleCloseModal} inverted>
                            No
                        </Button>
                        <Button color='green' onClick={() => this.deleteChecklist(removeId)} inverted>
                            <Icon name='checkmark' /> Yes
                        </Button>
                    </Modal.Actions>
                </Modal>
            )
        }
        return '';
    };

    getNewOrdersFilters = () => {
        return (
            <div className="checklists-admin-filters">
                <Grid stackable>
                    <Grid.Row columns={2}>
                        <Grid.Column width={8}>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <div className="checklists-admin-types-select">
                                <div className="store-number">
                                    <Select
                                        text={this.state.pickedChecklistType.name}
                                        value={this.state.pickedChecklistType.value}
                                        options={this.state.checklistOptions}
                                        onChange={this.handleChangeType} />
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    };

    getStoresHtml = () => {
        return (

            <div className="checklists-admin-stores">
                <div className="checklists-admin-content">
                    <div className="store-number">
                        <Select
                            text={this.state.store.store.title}
                            value={this.state.store.store.id}
                            options={this.state.options}
                            onChange={this.handleChangeStore} />
                    </div>
                </div>
            </div>
        )
    }

    redirectToEditPage = (id) => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/management/checklists/' + id + '/' + (id === 'new' ? 'new' : this.state.pickedChecklistType.value);
    };

    getChecklistsHtml = () => {
        //console.log(this.state.checklists)
        return (<div>
            {this.state.renderChecklists && this.state.checklists && Array.isArray(this.state.checklists) ?
                <List divided verticalAlign='middle' relaxed>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable-checklists-admin">
                            {(provided, snapshot) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                    {this.state.checklists.map((checklist, index) => (
                                        <Draggable key={checklist.id} draggableId={checklist.id.toString()} index={index}>
                                            {(provided, snapshot) => (
                                                <div className="checklists-admin-dragdrop-divs"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <List.Item className="checklists-admin-list-item">
                                                        <Grid className="checklists-admin-grid" stackable>
                                                            <Grid.Row className="checklists-admin-grid-rows" columns={this.state.pickedChecklistType.value === 'weekly' ? 3 : 2}>
                                                                <Grid.Column className="checklists-admin-grid-columns" width={this.state.pickedChecklistType.value === 'weekly' ? 11 : 14}>
                                                                    {checklist.task}
                                                                </Grid.Column>
                                                                {this.state.pickedChecklistType.value === 'weekly' ?
                                                                    <Grid.Column className="checklists-admin-grid-weekly-columns" width={3}>
                                                                        {checklist.note}
                                                                    </Grid.Column>
                                                                    : ''
                                                                }
                                                                <Grid.Column className="checklists-admin-grid-action-columns" width={2}>
                                                                    <Button.Group icon>
                                                                        {isPermitted('checklists-edit', this.state.store.store ? this.state.store.store.id : null) &&
                                                                            <Button size='tiny' onClick={(e) => this.redirectToEditPage(checklist.id)}>
                                                                                <Icon name='pencil' />
                                                                            </Button>
                                                                        }
                                                                        {isPermitted('checklists-delete', this.state.store.store ? this.state.store.store.id : null) &&
                                                                            <Button size='tiny' onClick={(e) => this.removeChecklistConfirmation(checklist.id)}>
                                                                                <Icon name='times' />
                                                                            </Button>
                                                                        }
                                                                    </Button.Group>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </List.Item>
                                                </div>

                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </List>
                : ''}
            <div>
                <Button color="orange" icon labelPosition='right' onClick={() => this.redirectToEditPage('new')}>
                    Add Task
                    <Icon name='plus' />
                </Button>
            </div>
        </div>
        );
    };

    render() {
        return (
            <div className="checklists-admin-root">
                {this.getStoresModalInfos()}
                <Grid className="filters" columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column width={8} className="col-left">
                            <Title
                                paramsPageTitle="Checklists"
                            />
                        </Grid.Column>
                        <Grid.Column width={8} className="col-right">
                            {this.state.renderStoreInfos ? this.getStoresHtml() : ''}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                {isPermitted('checklists-view', this.state.store.store ? this.state.store.store.id : null) ?
                    <div>
                        {this.state.renderStoreInfos ? this.getNewOrdersFilters() : ''}

                        <div className="checklists-admin-table-div">
                            {this.state.renderChecklists ? this.getChecklistsHtml() : ''}
                        </div>
                        {this.getConfirmationModal()}
                        <NotificationContainer />
                    </div>
                    : <Forbidden target="store" />
                }
            </div>
        );
    }
}

export default Checklists;
