import React, { Component } from 'react';
import { Grid, Button, List, Icon, Form, Label } from "semantic-ui-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { NotificationManager, NotificationContainer } from "react-notifications";
import Title from "../../components/Title";
import './../../assets/css/pages/retail-score.css';
import Request from "../../Request";

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: 8
});

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: 8 * 2,
    margin: `0`,
    background: isDragging ? "lightgray" : "white",
    ...draggableStyle
});

class RetailScoreCategoryCreate extends Component {
    state = {
        categories: [],
        validationErrors: [],
    };

    constructor(props) {
        super(props);
        this.request = new Request();
    };

    onDragEnd = () => {
        NotificationManager.success('Successfully changed order of Retail Score', 'Success');
    };

    onItemPointChange = (categoryIndex, itemIndex, value) => {
        const categories = [...this.state.categories];
        categories[categoryIndex].retail_score_category_items[itemIndex].permissible_score = value;
        this.setState({ categories });
    };

    onItemLabelChange = (categoryIndex, itemIndex, value) => {
        const categories = [...this.state.categories];
        categories[categoryIndex].retail_score_category_items[itemIndex].name = value;
        this.setState({ categories });
    };

    addItem = (categoryIndex) => {
        const categories = [...this.state.categories];
        categories[categoryIndex].retail_score_category_items.push({ name: '', permissible_score: 0 });
        this.setState({ categories });
    };

    removeCategory = (categoryIndex) => {
        const categories = [...this.state.categories];
        categories.splice(categoryIndex, 1);
        this.setState({ categories });
    };

    removeItem = (categoryIndex, itemIndex) => {
        const categories = [...this.state.categories];
        categories[categoryIndex].retail_score_category_items.splice(itemIndex, 1);
        this.setState({ categories });
    };

    onCategoryNameChange = (categoryIndex, value) => {
        const categories = [...this.state.categories];
        categories[categoryIndex].name = value;
        this.setState({ categories });
    };

    addCategory = () => {
        const categories = [...this.state.categories];
        categories.push({ name: '', retail_score_category_items: [] });
        this.setState({ categories });
    };

    saveCategory = () => {
        new Promise((resolve, reject) => {
            let path = '/retail-score-categories';
            let params = this.state.categories;
            this.request.throwValidationErrors = true;
            this.request.post(path, params, resolve, reject);
        })
            .then(response => {
                NotificationManager.success('Category saved successfully', 'Success');
            })
            .catch(error => {
                if (error.statusCode == 422) {
                    this.setValidationErrors(error?.errorObject?.errors)
                }
                else if (error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not able to save new categories', 'Error');
                }
            });
    };

    setValidationErrors = (validationErrors) => {
        // Initialize an empty array to store the result
        const result = [];

        // Iterate over the keys of the original object
        Object.keys(validationErrors).forEach(key => {
            const parts = key.split('.'); // Split the key by '.'
            const categoryIndex = parseInt(parts[1]); // Extract the category index
            const field = parts[parts.length - 1]; // Extract the field name
            const itemIndex = parts.length > 4 ? parseInt(parts[3]) : null; // Extract the item index if available

            // Ensure the result array has enough elements to accommodate the current category index
            while (result.length <= categoryIndex) {
                result.push({ name: '', retail_score_category_items: [] });
            }

            // If the field belongs to the category itself
            if (parts.length === 3) {
                result[categoryIndex].name = validationErrors[key];
            }
            // If the field belongs to an item within retail_score_category_items
            else if (parts.length === 5 && parts[2] === 'retail_score_category_items') {
                const itemsArray = result[categoryIndex].retail_score_category_items;

                // Ensure the items array has enough elements to accommodate the current item index
                while (itemsArray.length <= itemIndex) {
                    itemsArray.push({});
                }

                // Set the error message for the corresponding field within the item
                itemsArray[itemIndex][field] = validationErrors[key];
            }
        });

        this.setState({ validationErrors: result });
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.state.categories !== prevState.categories) {
            console.log(this.state.categories);
        }
    }

    render() {
        const { categories } = this.state;

        return (
            <div className="retail-score-page">
                <Title paramsPageTitle="Add Retail Score Category" />
                <div className="retail-score-content">
                    {categories.length > 0 &&
                        <List divided verticalAlign='middle' relaxed>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable-retail-score">
                                    {(provided, snapshot) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                            {categories.map((category, categoryIndex) => (
                                                <Draggable key={categoryIndex} draggableId={categoryIndex.toString()} index={categoryIndex}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            className="retail-score-divs"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <List.Item className="retail-score-list-item">
                                                                <Grid className="retail-score-grid" stackable>
                                                                    <Grid.Row className="retail-score-grid-row" columns={2}>
                                                                        <Grid.Column className="retail-score-grid-column" width={8}>
                                                                            <Form>
                                                                                <Grid className="retail-score-grid" stackable>
                                                                                    <Grid.Row className="retail-score-grid-row" columns={2}>
                                                                                        <Grid.Column className="retail-score-grid-column" width={2}>
                                                                                            <Button icon title="Remove item" onClick={() => this.removeCategory(categoryIndex)}>
                                                                                                <Icon name='minus' />
                                                                                            </Button>
                                                                                        </Grid.Column>
                                                                                        <Grid.Column className="retail-score-grid-column" width={12}>
                                                                                            <Form.Field>
                                                                                                <Label className="retail-score-group-label" pointing='below'>Category name</Label>
                                                                                                <input
                                                                                                    className="retail-score-group-input"
                                                                                                    type='text'
                                                                                                    value={category.name}
                                                                                                    placeholder="Name"
                                                                                                    onChange={(e) => this.onCategoryNameChange(categoryIndex, e.target.value)}
                                                                                                />
                                                                                                <span className='retail-score-error'>{this.state.validationErrors[categoryIndex]?.name}</span>
                                                                                            </Form.Field>
                                                                                        </Grid.Column>
                                                                                    </Grid.Row>
                                                                                </Grid>
                                                                            </Form>
                                                                        </Grid.Column>
                                                                        <Grid.Column className="retail-score-grid-action-column" width={8}>
                                                                            <Grid className="retail-score-grid" stackable>
                                                                                <Grid.Row className="retail-score-grid-row" columns={2}>
                                                                                    <Grid.Column className="retail-score-grid-column" width={10}>
                                                                                        <h4>Item name</h4>
                                                                                    </Grid.Column>
                                                                                    <Grid.Column className="retail-score-grid-column" width={4}>
                                                                                        <h4>Score</h4>
                                                                                    </Grid.Column>
                                                                                </Grid.Row>
                                                                            </Grid>
                                                                            {category.retail_score_category_items.map((item, itemIndex) => (
                                                                                <div className="retail-score-item" key={itemIndex}>
                                                                                    <Form>
                                                                                        <Grid className="retail-score-grid" stackable>
                                                                                            <Grid.Row className="retail-score-grid-row" columns={2}>
                                                                                                <Grid.Column className="retail-score-grid-column" width={10}>
                                                                                                    <Form.Field>
                                                                                                        <input
                                                                                                            className="retail-score-item-label"
                                                                                                            type='text'
                                                                                                            value={item.name}
                                                                                                            placeholder="Name"
                                                                                                            onChange={(e) => this.onItemLabelChange(categoryIndex, itemIndex, e.target.value)}
                                                                                                        />
                                                                                                        <span className='retail-score-error'>{this.state.validationErrors[categoryIndex]?.['retail_score_category_items']?.[itemIndex]?.['name']}</span>
                                                                                                    </Form.Field>
                                                                                                </Grid.Column>
                                                                                                <Grid.Column className="retail-score-grid-column" width={4}>
                                                                                                    <Form.Field>
                                                                                                        <input
                                                                                                            className="retail-score-item-point"
                                                                                                            type='number'
                                                                                                            value={item.permissible_score}
                                                                                                            placeholder="Score"
                                                                                                            onChange={(e) => this.onItemPointChange(categoryIndex, itemIndex, e.target.value)}
                                                                                                        />
                                                                                                        <span className='retail-score-error'>{this.state.validationErrors[categoryIndex]?.['retail_score_category_items']?.[itemIndex]?.['permissible_score']}</span>
                                                                                                    </Form.Field>
                                                                                                </Grid.Column>
                                                                                                <Grid.Column className="retail-score-grid-column" width={2}>
                                                                                                    <Button icon title="Remove item" onClick={() => this.removeItem(categoryIndex, itemIndex)}>
                                                                                                        <Icon name='trash' />
                                                                                                    </Button>
                                                                                                </Grid.Column>
                                                                                            </Grid.Row>
                                                                                        </Grid>
                                                                                    </Form>
                                                                                </div>
                                                                            ))}
                                                                            <div className='retail-score-grid-action'>
                                                                                <Button icon labelPosition='left' onClick={() => this.addItem(categoryIndex)}>
                                                                                    <Icon name='plus' />
                                                                                    Add Type
                                                                                </Button>
                                                                            </div>
                                                                        </Grid.Column>
                                                                    </Grid.Row>
                                                                </Grid>
                                                            </List.Item>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </List>
                    }

                    <div className="add-new-retail-score">
                        <Button icon labelPosition='right' onClick={this.addCategory}>
                            Add New
                            <Icon name='plus' />
                        </Button>
                        {categories.length > 0 &&
                            <Button onClick={this.saveCategory} color="orange">
                                Save
                            </Button>
                        }
                    </div>

                </div>
                <NotificationContainer />
            </div>
        );
    }
}

export default RetailScoreCategoryCreate;
