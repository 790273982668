import React from 'react';
import {NotificationContainer, NotificationManager} from "react-notifications";
import './../../assets/css/pages/admin/users-admin-edit.css';
import {Input, Button, Select} from "semantic-ui-react";
import Request from "../../Request";
import Cookies from "universal-cookie";
import Title from 'components/Title';
import { useRolePermissions } from './../../hooks/useRolePermissions';

const { redirectToForbiddenIfUnauthorized } = useRolePermissions();
const jwt_decode = require('jwt-decode');

class UsersAdminEdit extends React.Component {

    state = {
        editId: null,
        options: [],
        store: {},
        stores: [
            {
                id: 1,
                title: 'store'
            },
            {
                id: 2,
                title: 'store2'
            }
        ],
        roles: [],
        role: {},
        rolesOptions: [],
        renderRoles: false,
        user: {},
        isAdmin: false,
        backToProfile: false,
        username: null
    };

    constructor() {
        super();
        this.request = new Request();
    }

    componentDidMount() {
        redirectToForbiddenIfUnauthorized('users-edit');
        let cookies = new Cookies();
        let decodedCookie = cookies.getAll();
        if(decodedCookie.token) {
            let decoded = jwt_decode(decodedCookie.token);
            let roles = decoded.roles;
            this.setState({isAdmin: roles.includes("ROLE_ADMIN")});
            if (this.props.match.params.id) {
                this.setState({editId: this.props.match.params.id}, ()=>{
                    this.getStores();
                })
            }
            else if(decodedCookie['user-id']) {
                this.setState({editId: decodedCookie['user-id'], backToProfile: true, username: decoded.username}, () => {
                    this.getUser();
                })
            }
            else {
                let url = process.env.REACT_APP_UI_URL;
                window.location.href = url + '/logout';
            }
        }
    }

    getUser = () => {
        if(this.state.editId && this.state.editId !== 'new') {
            new Promise((resolve, reject) => {
                let path = '/users/' + this.state.editId + '/edit';
                this.request.get(path, resolve, reject);
            })
                .then(response => {
                    let user = JSON.parse(response);
                    if(user && user.success === true && user.data) {
                        this.setState({user: user.data});
                    }
                    else {
                        this.setState({user: {}});
                    }
                })
                .catch(error => {
                    this.setState({user: {}});
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not get User', 'Error');
                    }
                });
        }
    };

    getRoles = () => {
        new Promise((resolve, reject) => {
            let path = '/stores-roles';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let roles = JSON.parse(response);
                if(roles && roles.success === true && roles.data) {
                    this.setState({roles: roles.data, role: roles.data[0]}, () => {
                        this.transformRolesToOptions();
                    });
                }
                else {
                    NotificationManager.error('Could not get Roles', 'Error');
                }
            })
            .catch(error => {
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Roles', 'Error');
                }
            });
    };

    getStores = () => {
        new Promise((resolve, reject) => {
            let path = '/stores';
            this.request.get(path, resolve, reject);
        })
            .then(response => {
                let stores = JSON.parse(response);
                if(stores && stores.success === true && stores.data) {
                    this.setState({stores: stores.data, store: stores.data[0]}, () => {
                        this.getUser();
                        this.getRoles();
                        this.transformStoresToOptions();
                    });
                }
                else {
                    this.getUser();
                    NotificationManager.error('Could not get Stores', 'Error');
                }
            })
            .catch(error => {
                this.setState({allStores: []}, ()=>{
                    this.getUser();
                });
                if(error) {
                    NotificationManager.error(error, 'Error');
                }
                else {
                    NotificationManager.error('Could not get Stores', 'Error');
                }
            });
    };

    submitNewUser = () => {
        let user = this.state.user;
        let store = this.state.store;
        let editId = this.state.editId;
        if(editId === 'new') {
            new Promise((resolve, reject) => {
                let path = '/users';
                let params = {
                    username: user.username,
                    first_name: user.firstName,
                    last_name: user.lastName,
                    email: user.email,
                    phone: user.phone,
                    pin: this.state.isAdmin ? (user.pin || null) : null,
                    store_id: store.id,
                    role_id: this.state.role.id ? this.state.role.id : 0,
                };
                this.request.post(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully saved User', 'Success');
                    this.redirectBack();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not save User', 'Error');
                    }
                });
        }
    };

    updateUser = () => {
        let user = this.state.user;
        let editId = this.state.editId;
        if(editId !== 'new') {
            new Promise((resolve, reject) => {
                let path = '/users/' + editId;
                let params = {
                    username: user.username,
                    first_name: user.firstName,
                    last_name: user.lastName,
                    email: user.email,
                    phone: user.phone,
                    pin: this.state.isAdmin ? (user.pin || null) : null
                };
                this.request.put(path, params, resolve, reject);
            })
                .then(response => {
                    NotificationManager.success('Successfully updated User', 'Success');
                    this.redirectBackToView();
                })
                .catch(error => {
                    if(error) {
                        NotificationManager.error(error, 'Error');
                    }
                    else {
                        NotificationManager.error('Could not update User', 'Error');
                    }
                });
        }
    };

    redirectBack = () => {
        let url = process.env.REACT_APP_UI_URL;
        window.location.href = url + '/admin/users';
    };

    transformStoresToOptions = () => {
        let stores = this.state.stores;
        let options = [];
        stores.forEach((store, index) => {
            let tempOption = {
                id: store.id,
                key: store.id,
                value: store.id,
                text: store.title
            };
            options.push(tempOption);
        });
        this.setState({options: options})
    };

    transformRolesToOptions = () => {
        let roles = this.state.roles;
        let options = [];
        roles.forEach((role, index) => {
            let tempOption = {
                id: role.id,
                key: role.id,
                value: role.id,
                text: role.name
            };
            options.push(tempOption);
        });
        if(options.length > 0) {
            this.setState({renderRoles: true});
        }
        this.setState({rolesOptions: options})
    };

    handleChangeStore = (event, data) => {
        const foundStore = this.state.stores.find(element => element.id === parseInt(data.value));
        if(foundStore) {
            this.setState({store: foundStore});
        }
    };

    handleChangeRole = (event, data) => {
        const foundRole = this.state.roles.find(element => element.id === parseInt(data.value));
        if(foundRole) {
            this.setState({role: foundRole});
        }
    };

    redirectBackToView = () => {
        let url = process.env.REACT_APP_UI_URL;
        if(this.state.backToProfile) {
            window.location.href = url + '/profile/' + this.state.username;
        }
        else {
            window.location.href = url + '/admin/users/' + this.state.editId;
        }
    };

    handleInputChanged = (event, field) => {
        let user = this.state.user;
        user[field] = event.target.value;
        this.setState({user: user});
    };

    render() {
        let user = this.state.user;
        return (
            <div className="users-admin-edit-root">
                <Title
                    paramsPageTitle={this.state.editId === 'new' ? 'User NEW' : 'User EDIT'}
                />
                <div className="users-admin-edit-fields-div">
                    <b>Username*:</b><br/>
                    <Input
                        fluid
                        value={user.username || ''}
                        disabled={this.state.isAdmin ? false : true}
                        onChange={(e)=>this.handleInputChanged(e, 'username')}
                    />
                    <br/>
                    <b>First Name*:</b><br/>
                    <Input
                        fluid
                        value={user.firstName || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'firstName')}
                    />
                    <br/>
                    <b>Last Name*:</b><br/>
                    <Input
                        fluid
                        value={user.lastName || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'lastName')}
                    />
                    <br/>
                    <b>Email*:</b><br/>
                    <Input
                        fluid
                        value={user.email || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'email')}
                    />
                    <br/>
                    <b>Phone*:</b><br/>
                    <Input
                        fluid
                        value={user.phone || ''}
                        onChange={(e)=>this.handleInputChanged(e, 'phone')}
                    />
                    { this.state.isAdmin ?
                        <div>
                            <br/>
                            <b>Pin:</b><br/>
                            <Input
                                type="password"
                                value={user.pin || ''}
                                onChange={(e)=>this.handleInputChanged(e, 'pin')}
                            />
                        </div>
                        :''
                    }
                </div>
                <div className="users-admin-dynamic-fields-div">
                    { this.state.editId === 'new' ?
                        <div>
                            <br/>
                            <b>Store: </b>
                            <Select
                                className="users-admin-dynamic-select-field"
                                text={this.state.store.title}
                                value={this.state.store.id}
                                options={this.state.options}
                                onChange={this.handleChangeStore}
                            />
                            { this.state.renderRoles ?
                                <Select
                                    className="users-admin-dynamic-roles-select-field"
                                    text={this.state.role.name}
                                    value={this.state.role.id}
                                    options={this.state.rolesOptions}
                                    onChange={this.handleChangeRole}
                                />
                                :''
                            }
                            <br/>
                            <br/>
                            <Button color="orange" onClick={this.submitNewUser}>Submit</Button>
                            <Button color="orange" onClick={this.redirectBack}>Back</Button>
                        </div>
                        :
                        <div>
                            <br/>
                            <Button color="orange" onClick={this.updateUser}>Update</Button>
                            <Button color="orange" onClick={this.redirectBackToView}>Cancel</Button>
                        </div>
                    }
                </div>
                <NotificationContainer/>
            </div>
        )
    }
}
export default UsersAdminEdit;