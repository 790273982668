import React from "react";
import PropTypes from "prop-types";
import "../../assets/css/pages/managment/serviceTicket.css";
import { Button, Grid, Input, Select } from "semantic-ui-react";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import CheckListFilters from "../../components/CheckListFilters";
import RichTextEditor from "react-rte";
import ModalImage from "react-modal-image";
import Request from "../../Request";
import Title from "components/Title";
import Forbidden from "pages/Forbidden";
import { useRolePermissions } from "./../../hooks/useRolePermissions";

const { redirectToForbiddenIfUnauthorized, isPermitted } = useRolePermissions();

class ServiceTicket extends React.Component {
  state = {
    date: new Date(),
    store: {},
    urgencyOptions: [
      {
        id: 1,
        key: 1,
        value: 1,
        text: "Low Priority",
      },
      {
        id: 2,
        key: 2,
        value: 2,
        text: "Critical",
      },
    ],
    pickedUrgency: {
      id: 1,
      key: 1,
      value: 1,
      text: "Low Priority",
    },
    descriptionValue: RichTextEditor.createEmptyValue(),
    nameValue: "",
    file: null,
    filename: null,
    files: [],
    serviceTicket: {
      serviceTicket: {
        urgency: null,
        description: "",
        requestBy: "",
        id: null,
      },
      files: [],
    },
    urlParams: {
      store: null,
      date: null,
    },
    tabletSize: window.innerWidth <= 1024, // for the modal position
    addressBook: [],
    renderAddressBook: false,
    pickedAddressBook: {},
    renderAddressBookPicked: false,
    loading: false,
  };

  toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
      { label: "Strikethrough", style: "STRIKETHROUGH" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  static propTypes = {
    onChange: PropTypes.func,
  };

  inlineAddressBookLinkColorStyle = {
    color: "orange",
  };

  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.request = new Request();
  }

  componentDidMount() {
    this.initializeParams();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ tabletSize: window.innerWidth <= 1200 });
  }

  initializeParams = () => {
    let stateParams = this.state.urlParams;
    if (this.props.match.params) {
      if (this.props.match.params.store) {
        stateParams.store = this.props.match.params.store;
      }
      if (this.props.match.params.date) {
        let date = new Date(this.props.match.params.date);
        if (this.isValidDate(date)) {
          stateParams.date = date;
        } else {
          stateParams.date = new Date();
        }
      } else {
        stateParams.date = new Date();
      }
      this.setState({ urlParams: stateParams });
    } else {
      this.setState({ urlParams: { store: null, date: new Date() } });
    }
  };

  formatDate = (date) => {
    if (date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
    return "";
  };

  isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  };

  // FILTERS

  handleChangeStore = (store) => {
    let urlParams = this.state.urlParams;
    if (store.id !== parseInt(urlParams.store)) {
      urlParams.store = store.id;
    }
    this.setState({ store: store, urlParams: urlParams }, () => {
      this.getServiceTicket();
    });
  };

  onGetStores = (store) => {
    if (store && store.id) {
      let urlParams = this.state.urlParams;
      if (store.id !== parseInt(urlParams.store)) {
        urlParams.store = store.id;
      }
      this.setState({ store: store, urlParams: urlParams }, () => {
        this.getServiceTicket();
      });
    }
  };

  handleChangeDate = (event, date) => {
    let urlParams = this.state.urlParams;
    if (date.value !== urlParams.date) {
      urlParams.date = date.value;
    }
    this.setState({ date: date.value, urlParams: urlParams }, () => {
      this.getServiceTicket();
    });
  };

  // FILTERS END

  getServiceTicket = () => {
    if (this.state.store && this.state.store.id) {
      new Promise((resolve, reject) => {
        let path =
          "/service-ticket/" +
          this.state.store.id +
          "/" +
          this.formatDate(this.state.date);
        this.request.get(path, resolve, reject);
      })
        .then((response) => {
          let ticket = JSON.parse(response);
          if (
            ticket &&
            ticket.success === true &&
            ticket.data &&
            ticket.data.serviceTicket
          ) {
            let stateTicket = ticket.data;
            this.setState(
              { serviceTicket: stateTicket, loading: false },
              () => {
                window.history.replaceState(
                  {},
                  null,
                  "/management/service-ticket/" +
                    this.state.urlParams.store +
                    "/" +
                    this.formatDate(this.state.urlParams.date)
                );
                this.getAddressBookData();
                this.fillServiceTicketFields();
              }
            );
          } else {
            this.setState(
              {
                serviceTicket: {
                  serviceTicket: {
                    urgency: null,
                    description: "",
                    requestBy: "",
                  },
                  files: [],
                },
                loading: false,
              },
              () => {
                window.history.replaceState(
                  {},
                  null,
                  "/management/service-ticket/" +
                    this.state.urlParams.store +
                    "/" +
                    this.formatDate(this.state.urlParams.date)
                );
                this.getAddressBookData();
                this.fillServiceTicketFields();
              }
            );
          }
        })
        .catch((error) => {
          let defaultTicketObject = {
            serviceTicket: {
              urgency: null,
              description: "",
              requestBy: "",
            },
            files: [],
          };
          this.setState({ serviceTicket: defaultTicketObject, loading: false });
          if (error) {
            NotificationManager.error(error, "Error");
          } else {
            NotificationManager.error("Could not get Service Ticket", "Error");
          }
        });
    }
  };

  getRichTextValueParsed = (value) => {
    return value
      ? RichTextEditor.createValueFromString(value, "html")
      : RichTextEditor.createEmptyValue();
  };

  fillServiceTicketFields = () => {
    let serviceTicket = this.state.serviceTicket;
    let urgency = this.state.pickedUrgency;
    if (serviceTicket.serviceTicket.urgency) {
      urgency = this.state.urgencyOptions.find(
        (element) => element.value === serviceTicket.serviceTicket.urgency
      );
    }
    this.setState({
      nameValue: serviceTicket.serviceTicket.requestBy,
      descriptionValue: this.getRichTextValueParsed(
        serviceTicket.serviceTicket.description
      ),
      pickedUrgency: urgency,
      files: serviceTicket.files,
    });
  };

  handleChangeUrgency = (event, data) => {
    const foundUrgency = this.state.urgencyOptions.find(
      (element) => element.value === data.value
    );
    if (foundUrgency) {
      this.setState({ pickedUrgency: foundUrgency });
    }
  };

  handleDescriptionChange = (value) => {
    if (value) {
      this.setState({ descriptionValue: value });
    }
  };

  handleFileChanged = (event) => {
    event.preventDefault();
    let file = event.target.files[0];
    this.setState({ filename: event.target.files[0].name });
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.setState({
          file: reader.result,
        });
      };
    }
  };

  resetFile = () => {
    this.setState({ file: null, filename: null });
  };

  onNameChanged = (event) => {
    this.setState({ nameValue: event.target.value });
  };

  saveServiceTicket = () => {
    this.setState({ loading: true });
    new Promise((resolve, reject) => {
      let path =
        "/service-ticket/" +
        this.state.store.id +
        "/" +
        this.formatDate(this.state.date);
      let params = {
        urgency: this.state.pickedUrgency.value,
        description: this.state.descriptionValue.toString("html"),
        request_by: this.state.nameValue,
        address_book_id: this.state.pickedAddressBook
          ? this.state.pickedAddressBook.id
          : null,
      };
      if (this.state.filename && this.state.file) {
        params.file = this.state.file;
        params.filename = this.state.filename;
      }
      this.request.post(path, params, resolve, reject);
    })
      .then((response) => {
        NotificationManager.success(
          "Successfully saved new Service Ticket",
          "Success"
        );
        this.getServiceTicket();
        this.resetFile();
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error(
            "Could not create new Service Ticket",
            "Error"
          );
        }
      });
  };

  updateServiceTicket = (action) => {
    this.setState({ loading: true });
    new Promise((resolve, reject) => {
      let path = "/service-ticket/" + this.state.serviceTicket.serviceTicket.id;
      let params = {
        urgency: this.state.pickedUrgency.value,
        description: this.state.descriptionValue.toString("html"),
        request_by: this.state.nameValue,
        action: action,
        address_book_id: this.state.pickedAddressBook
          ? this.state.pickedAddressBook.id
          : null,
      };
      if (this.state.filename && this.state.file) {
        params.file = this.state.file;
        params.filename = this.state.filename;
      }
      this.request.put(path, params, resolve, reject);
    })
      .then((response) => {
        NotificationManager.success(
          action === "save"
            ? "Successfully updated Service Ticket"
            : "Successfully submited Service Ticket",
          "Success"
        );
        this.getServiceTicket();
        this.resetFile();
      })
      .catch((error) => {
        this.setState({ loading: false });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error(
            action === "save"
              ? "Could not update Service Ticket"
              : "Could not submit Service Ticket",
            "Error"
          );
        }
      });
  };

  handleRemoveImage = (index) => {
    let file = this.state.files[index];
    if (file && file.url) {
      new Promise((resolve, reject) => {
        let apiUrl = file.url;
        this.request.delete("", resolve, reject, apiUrl);
      })
        .then((response) => {
          response = JSON.parse(response);
          if (response && response.success === true) {
            this.getServiceTicket();
            NotificationManager.success(
              "Successfully removed media content",
              "Success"
            );
          }
        })
        .catch((error) => {
          if (error) {
            NotificationManager.error(error, "Error");
          } else {
            NotificationManager.error(
              "Could not remove media content",
              "Error"
            );
          }
        });
    } else {
      NotificationManager.error("Could not remove media content", "Error");
    }
  };

  addresBookPicked = (id, index) => {
    let addressBook = this.state.addressBook;
    let address = addressBook[index];
    let foundAddressIndex = address.resources.findIndex((address) => {
      return address.id === id;
    });

    if (foundAddressIndex !== -1) {
      this.setAllAddressBooksOnFalse();
      address.resources[foundAddressIndex].picked = true;
      this.setState({
        renderAddressBookPicked: true,
        pickedAddressBook: address.resources[foundAddressIndex],
      });
    }
  };

  setAllAddressBooksOnFalse = () => {
    let addressBook = this.state.addressBook;
    addressBook.forEach((address) => {
      address.resources.forEach((resource) => {
        resource.picked = false;
      });
    });
    this.setState({ addressBook: addressBook });
  };

  getAddressBookData = () => {
    new Promise((resolve, reject) => {
      let path = "/address-book/" + this.state.store.id + "/details";
      this.request.get(path, resolve, reject);
    })
      .then((response) => {
        let books = JSON.parse(response);
        if (books && books.success === true && books.data) {
          let stateAddressBook = books.data;
          this.setState({ addressBook: stateAddressBook }, () => {
            this.setAllAddressBooksOnFalse();
            this.setPickedAddressBook();
            this.setState({ renderAddressBook: true });
          });
        } else {
          this.setState({ addressBook: [] }, () => {
            this.setState({ renderAddressBook: true });
          });
        }
      })
      .catch((error) => {
        this.setState({ addressBook: [] }, () => {
          this.setState({ renderAddressBook: true });
        });
        if (error) {
          NotificationManager.error(error, "Error");
        } else {
          NotificationManager.error("Could not get Address Book data", "Error");
        }
      });
  };

  getAddressBookPickerHtml = (addressBook) => {
    return (
      <Grid
        columns={4}
        stackable
        className="service-ticket-address-book-picker-grid"
      >
        <Grid.Row>
          {addressBook.map((address, index) => {
            return (
              <Grid.Column
                className="service-ticket-address-book-picker-column"
                key={index}
              >
                <b>{address.category.name}</b>
                <br />
                {address.resources.map((resource, index2) => {
                  return (
                    <a
                      href={"#" + resource.name}
                      key={"link-" + index2}
                      style={
                        resource.picked
                          ? this.inlineAddressBookLinkColorStyle
                          : {}
                      }
                      className="service-ticket-address-book-resorce"
                      onClick={(e) => this.addresBookPicked(resource.id, index)}
                    >
                      {resource.name}
                    </a>
                  );
                })}
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>
    );
  };

  getAddressBookPickedHtml = () => {
    let addressBook = this.state.pickedAddressBook;
    return (
      <Grid className="service-ticket-address-book-picked-grid">
        <Grid.Row columns={1}>
          <Grid.Column>
            {addressBook.name ? <h3>{addressBook.name}</h3> : ""}
            {addressBook.street ? <span>{addressBook.street}</span> : ""}
            <br />
            {addressBook.city && addressBook.zip && addressBook.state ? (
              <span>
                {addressBook.city +
                  ", " +
                  addressBook.state +
                  ", " +
                  addressBook.zip}
              </span>
            ) : (
              ""
            )}
            <br />
            <h5>CONTACT NAME:</h5>
            {addressBook.contact_name ? (
              <span>{addressBook.contact_name}</span>
            ) : (
              ""
            )}
            <br />
            <b>TEL1: </b>
            {addressBook.phone || ""}
            <br />
            <b>TEL2: </b>
            {addressBook.phone2 || ""}
            <br />
            <b>FAX: </b>
            {addressBook.fax || ""}
            <br />
            <b>EMAIL: </b>
            {addressBook.email || ""}
            <br />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  getAddressBookHtml = () => {
    let addressBook = this.state.addressBook;
    return (
      <Grid columns={2} stackable>
        <Grid.Row>
          <Grid.Column width={11}>
            {this.getAddressBookPickerHtml(addressBook)}
          </Grid.Column>
          <Grid.Column
            width={5}
            className="service-ticket-address-book-picked-grid-column"
          >
            {this.state.renderAddressBookPicked
              ? this.getAddressBookPickedHtml()
              : ""}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  setPickedAddressBook = () => {
    let serviceTicket = this.state.serviceTicket.serviceTicket;
    let addressBookId = serviceTicket.addressBookId || null;
    let addressBook = this.state.addressBook;

    if (addressBookId) {
      addressBook.forEach((address) => {
        address.resources.forEach((resource) => {
          if (parseInt(resource.id) === parseInt(addressBookId)) {
            resource.picked = true;
            this.setState({
              renderAddressBookPicked: true,
              pickedAddressBook: resource,
            });
          }
        });
      });
    }
  };

  render() {
    return (
      <div>
        <Grid className="filters" columns={2} stackable>
          <Grid.Row>
            <Grid.Column width={8} className="col-left">
              <Title paramsPageTitle="Service Ticket" />
            </Grid.Column>
            <Grid.Column width={8} className="col-right">
              <CheckListFilters
                paramsStoreId={this.state.urlParams.store}
                date={this.state.urlParams.date}
                onGetStores={this.onGetStores}
                onChangeDate={this.handleChangeDate}
                onChangeStore={this.handleChangeStore}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {isPermitted(
          "service-providers-view",
          this.state.store ? this.state.store.id : null
        ) ? (
          <div className="service-ticket-content">
            <div className="service-ticket-address-book">
              {this.state.renderAddressBook ? this.getAddressBookHtml() : ""}
            </div>
            <div className="service-ticket-urgency-div">
              <b>Urgency: </b>
              <Select
                text={this.state.pickedUrgency.name}
                value={this.state.pickedUrgency.value}
                options={this.state.urgencyOptions}
                onChange={this.handleChangeUrgency}
              />
            </div>
            <div className="service-ticket-name-input-div">
              <div>
                <b>Request By (Name):</b>
              </div>
              <Input
                fluid
                value={this.state.nameValue}
                onChange={this.onNameChanged}
              />
            </div>
            <div className="service-ticket-text-area-div">
              <div>
                <b>Description:</b>
              </div>
              <RichTextEditor
                editorClassName="textEditor"
                value={this.state.descriptionValue}
                onChange={this.handleDescriptionChange}
                toolbarConfig={this.toolbarConfig}
              />
            </div>
            <div className="service-ticket-file-input">
              <div>
                <input
                  ref={this.fileInput}
                  type="file"
                  accept=".jpeg, .jpg, .png, .svg, .mp4, .mov, .gif"
                  onChange={this.handleFileChanged}
                />
              </div>
              <div className="fileInputTypes">
                (jpg, jpeg, png, gif, svg, mp4, mov)
              </div>
            </div>
            <br />
            <div className="service-ticket-media">
              {this.state.files &&
              Array.isArray(this.state.files) &&
              this.state.files.length > 0
                ? this.state.files.map((image, index) => {
                    return (
                      <div key={index}>
                        {image.is_video ? (
                          <div className="service-ticket-videos-media-div">
                            <video className="service-ticket-videos" controls>
                              <source src={image.url} type="video/mp4" />
                            </video>
                            <div className="service-ticket-remove-image-link">
                              <a
                                href={"#delete"}
                                onClick={() => this.handleRemoveImage(index)}
                              >
                                Delete
                              </a>
                            </div>
                          </div>
                        ) : (
                          <div className="service-ticket-images-media-div">
                            <ModalImage
                              className="service-ticket-images"
                              small={image.url}
                              large={image.url}
                              alt=""
                            />
                            <div className="service-ticket-remove-image-link">
                              <a
                                href={"#delete"}
                                onClick={() => this.handleRemoveImage(index)}
                              >
                                Delete
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })
                : ""}
            </div>
            {this.state.loading ? (
              <div className="service-ticket-buttons">
                <Button loading color="orange">
                  Loading
                </Button>
              </div>
            ) : (
              <div className="service-ticket-buttons">
                <Button
                  content="Save"
                  color="orange"
                  onClick={() => {
                    this.state.serviceTicket.serviceTicket.id
                      ? this.updateServiceTicket("save")
                      : this.saveServiceTicket();
                  }}
                />
                <Button
                  content="Submit"
                  color="orange"
                  onClick={() => this.updateServiceTicket("send")}
                />
              </div>
            )}
            <NotificationContainer />
          </div>
        ) : (
          <Forbidden target="store" />
        )}
      </div>
    );
  }
}

export default ServiceTicket;
